<template>
<div>
      <span class="yesno-label">{{label}}</span>
        <div class="toggle-radio" >
          
          <input type="radio" name="rdo" :id="'yes_' + id">
          <input type="radio" name="rdo" :id="'no_' + id">
          <div :style="getSwitchStyle()" class="switch icon-yes-checked" v-bind:class="{ 'icon-no-checked' : value == 'no'  }">
          <label @click="setValue('yes')" class="yes" :style="{ color: value == 'yes' ? 'white' : 'rgba(0,0,0,0.2)',   }">Yes</label>
          <label @click="setValue('no')" class="no" :style="{ color: value == 'no' ? 'white' : 'rgba(0,0,0,0.2)'  }">No</label>
          <span></span>
          </div>
        </div>
</div>
</template>

<script>
export default {
  name: 'control-yes-no',
  data() {
    return {
      value: null
    }
  },
  props: {
    label: String,
    id: String
  },
  methods:  {
    setValue(val) {
      this.value = val;
      this.$emit('changed', val);
    },
    getSwitchStyle() {
      if (this.value == 'yes') {
        return { 'background-color': '#00bc9c' }
      }
      if (this.value == 'no') {
        return { 'background-color': '#eb4f37' }
      }
      
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.yesno-label {
  font-size: 0.8em;
}

.toggle-radio {
  position: relative;
  display: block;
  width: 100%;
  font-family: sans-serif;
  font-weight: 800;
  margin-top: 40px;
}

.switch {
    position: relative;
    top: 50%;
    left: 50%;
    width: 150px;
    height: 50px;
    text-align: center;
    margin: -30px 0 0 -75px;
    background:lightgray;
    transition: all 0.2s ease;
    border-radius: 25px;
  }


.icon-yes-checked span {
    position: absolute;
    width: 20px;
    height: 4px;
    top: 50%;
    left: 50%;
    margin: -2px 0px 0px -4px;
    background: #fff;
    display: block;
    transform: rotate(-45deg); 
    transition: all 0.2s ease;

}

.icon-yes-checked span:after {
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    height: 12px;
    margin-top: -8px;
    background: #fff;
    transition: all 0.2s ease;
  
}

.icon-no-checked span {
    background: #fff;
    margin-left: -8px;
  }
  .icon-no-checked span:after {
    background: #fff;
    height: 20px;
    margin-top: -8px;
    margin-left: 8px;
  }

  /* .switch span {
    position: absolute;
    width: 20px;
    height: 4px;
    top: 50%;
    left: 50%;
    margin: -2px 0px 0px -4px;
    background: #fff;
    display: block;
    transform: rotate(-45deg); 
    transition: all 0.2s ease;
  }
  .switch span:after {
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    height: 12px;
    margin-top: -8px;
    background: #fff;
    transition: all 0.2s ease;
  } */
  input[type=radio] {
    display: none;
  }
  .switch label {
    cursor: pointer;
    color: rgba(0,0,0,0.2);
    width: 60px;
    line-height: 50px;
    transition: all 0.2s ease;
  }
  .yes {
    position: absolute;
    left: 0px;
    height: 20px;
  }
  .no {
    position: absolute;
    right: 0px;
  }

  

  /* #no:checked ~ .switch {
    background: #eb4f37;
  }
  #no:checked ~ .switch span {
    background: #fff;
    margin-left: -8px;
  }
  #no:checked ~ .switch span:after {
    background: #fff;
    height: 20px;
    margin-top: -8px;
    margin-left: 8px;
  }
  #yes:checked ~ .switch label[for=yes] {
    color: #fff;
  }
  #yes:checked ~ .switch {
    background-color: #00bc9c;
  }
  #no:checked ~ .switch label[for=no] {
    color: #fff;
  } */

</style>
