import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueRouter from "vue-router";
import { GlobalMethods, GlobalStore } from "./global";

import Login from "./views/Login.vue";
import FYI from "./views/FYI.vue";
import BestSellers from "./views/BestSellers.vue";
import BestStores from "./views/BestStores.vue";
import Memos from "./views/Memos.vue";
import ToDo from "./views/ToDo.vue";
import Settings from "./views/Settings.vue";
import UserProfile from "./views/UserProfile.vue";
import RegionalManager from "./views/RegionalManager.vue";
import ActionRequestDashboard from "./views/ActionRequestDashboard.vue";

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
import {
  faCog,
  faUser,
  faThumbsUp as faThumbsUpSolid,
  faLink,
  faTags
} from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";

import axios from "axios";
import VueAxios from "vue-axios";

import { library } from "@fortawesome/fontawesome-svg-core";
import Toasted from "vue-toasted";
import VueSession from "vue-session";
import Vue2TouchEvents from "vue2-touch-events";

import ImageUploader from "vue-image-upload-resize";

import ForgotPassword from "./views/password/forgot_password.vue";
import ForgotPasswordConfirmation from "./views/password/forgot_password_confirmation.vue";
import ForgotPasswordNoSend from "./views/password/forgot_password_no_send.vue";
import ResetPassword from "./views/password/reset_password.vue";

//import NotFound from "./views/NotFound.vue";

import VueCryptojs from "vue-cryptojs";
import VueAnalytics from "vue-analytics";

import { set } from "vue-analytics";

Vue.use(VueCryptojs);

Vue.use(ImageUploader);

require("@/assets/css/vendor.min.css");
require("@/assets/css/prism.min.css");
require("@/assets/css/theme.min.css");
require("@/assets/css/style.css");

import VSwipe from "vswipe";
Vue.use(VSwipe);

library.add(faCog, faUser, faThumbsUp, faThumbsUpSolid, faLink, faTags);

Vue.use(require("vue-moment"));

//import './custom.scss'
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(VueSession);
Vue.use(Vue2TouchEvents);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
Vue.use(Toasted);

Vue.config.productionTip = false;

// setInterval(() => {
//   let timeUntilExpiry = GlobalStore.expires_at - GlobalMethods.getEpochSeconds();
//   console.log("Time until expire:" + timeUntilExpiry);
// }, 1000);

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", component: FYI },
    { path: "/login", component: Login },
    { path: "/login/", component: Login },
    {
      path: "/FYI",
      component: FYI,
      meta: { title: "FYI - For Your Influencers" },
    },
    { path: "/bestSellers", component: BestSellers },
    { path: "/BestStores", component: BestStores },
    { path: "/memos", component: Memos },
    { path: "/toDo", component: ToDo },
    { path: "/settings", component: Settings },
    { path: "/userProfile", component: UserProfile },
    { path: "/regionalManager", component: RegionalManager },
    {
      path: "/actionRequestDashboard/:guid",
      component: ActionRequestDashboard,
    },

    { path: "/forgot_password", component: ForgotPassword },
    {
      path: "/forgot_password_confirmation",
      component: ForgotPasswordConfirmation,
    },
    { path: "/forgot_password_no_send", component: ForgotPasswordNoSend },
    { path: "/reset_password", component: ResetPassword },
    { path: "//reset_password", component: ResetPassword },

    {
      path: "*",
      //name: 'Not Found',
//      redirect: "/" + GlobalStore.custObj.modules[0].app_url, *** Does not appear to be loaded yet, but doesn't seem to matter
      redirect: "/fyi",
    },
  ],
});

Vue.use(VueAnalytics, {
  id: "UA-180170850-1",
  router,
  autoTracking: {
    pageviewTemplate(route) {
      if (GlobalStore.custObj) {
        set("dimension2", GlobalStore.custObj.name);
      }
      if (GlobalStore.userMeObj) {
        set("dimension1", GlobalStore.userMeObj.login);
        set("userId", GlobalStore.userMeObj.login);
        if (
          GlobalStore.userMeObj.stores &&
          GlobalStore.userMeObj.stores.length > 0
        ) {
          set("dimension3", GlobalStore.userMeObj.stores[0]);
        }
        console.log("setting1");
      }
      console.log("setting2");

      return {
        page: route.path,
        title: document.title,
        location: window.location.href,
      };
    },
  },
});

router.beforeEach(async (to, from, next) => {
  if (
    !(await GlobalMethods.checkIfAuthenticated()) &&
    to.path.toLowerCase() != "/login" &&
    to.path.toLowerCase() != "/login/" &&
    to.path.toLowerCase() != "/forgot_password" &&
    to.path.toLowerCase() != "/forgot_password/" &&
    to.path.toLowerCase() != "/forgot_password_confirmation" &&
    to.path.toLowerCase() != "/forgot_password_confirmation/" &&
    to.path.toLowerCase() != "/forgot_password_no_send" &&
    to.path.toLowerCase() != "/forgot_password_no_send/" &&
    to.path.toLowerCase() != "/reset_password" &&
    to.path.toLowerCase() != "/reset_password/" &&
    to.path.toLowerCase() != "//reset_password" &&
    to.path.toLowerCase() != "//reset_password/"
  )
    next("/login");
  else next();
});

Vue.directive("visible", function(el, binding) {
  el.style.visibility = binding.value ? "visible" : "hidden";
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
