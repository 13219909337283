<template>
  <div
    v-bind:style="
      currentURL == '/login' ? { maxWidth: '600px', margin: 'auto' } : null
    "
  >
    <header class="box-shadow-sm mb-2">
      <!-- Topbar-->
      <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page.-->

      <div class="navbar-sticky bg-light">
        <div id="appNavbar" class="navbar navbar-expand-xl navbar-light">
          <div class="container">
            <a
              class="navbar-brand d-none d-sm-block mr-3 flex-shrink-0"
              href="/login"
              style="min-width: 7rem"
              ><img
                class="logo-image-desktop"
                :src="headerLogo"
                alt="everyCX" /></a
            ><a
              class="navbar-brand d-sm-none mr-2"
              href="/login"
              style="min-width: 4.625rem"
              ><img class="logo-image-mobile" :src="headerLogo" alt="everyCX"
            /></a>
            <!-- <div class="input-group-overlay d-none d-lg-flex mx-4" v-if="checkAuthenticated()">
              <input class="form-control appended-form-control" type="text" placeholder="Search for products">
              <div class="input-group-append-overlay"><span class="input-group-text"><i class="czi-search"></i></span></div>
            </div> -->
            <div class="navbar-toolbar d-flex flex-shrink-0 align-items-center">
              <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"><span class="navbar-toggler-icon"></span></button> -->

              <!-- <a class="navbar-tool navbar-stuck-toggler" href="#"><span class="navbar-tool-tooltip">Expand menu</span><div class="navbar-tool-icon-box"><i class="navbar-tool-icon czi-menu"></i></div></a> -->
              <!-- <div class="navbar-tool-icon-box"><i class="navbar-tool-icon czi-menu"></i></div></a><a class="navbar-tool d-none d-lg-flex" href="account-wishlist.html"><span class="navbar-tool-tooltip">Wishlist</span>
                <div class="navbar-tool-icon-box"><i class="navbar-tool-icon czi-heart"></i></div></a><a class="navbar-tool ml-1 ml-lg-0 mr-n1 mr-lg-2" > -->
              <div
                v-if="checkAuthenticated()"
                class="navbar-tool-icon-box"
                @click="toggleUserMenu()"
              >
                <div
                  class="avatar"
                  style="display: inline-flex"
                  :title="getUsername()"
                >
                  <div class="avatar__letters">
                    {{ getInitials() }}
                  </div>
                </div>
                <img
                  class="profile-menu-logo"
                  src="./assets/exclamation-mark-white-cutout-small.png"
                />
              </div>
              <!-- <div class="navbar-tool-text ml-n3"><small>Hello, Sign in</small>My Account</div></a> -->
              <div class="user-profile" v-if="userMenuVisible">
                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item pb-1" href="#"
                      ><i class="navbar-tool-icon czi-user"></i
                      ><span>{{ userObj.login }}</span></a
                    >
                  </li>
                  <!-- <li><a class="dropdown-item pb-1" href="#"><i class="navbar-tool-icon czi-settings"></i><span>Settings</span></a></li> -->
                  <hr />
                  <li>
                    <a class="dropdown-item pb-1" href="#"
                      ><span>v {{ versionNumber }}</span></a
                    >
                  </li>
                  <hr />
                  <li>
                    <a class="dropdown-item pb-1" @click="logOut()"
                      ><i class="navbar-tool-icon czi-close"></i
                      ><span>Logout</span></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          class="navbar navbar-expand-xl navbar-light navbar-stuck-menu mt-n2 pt-0 pb-2"
        >
          <div class="container">
            <div class="collapse navbar-collapse" id="navbarCollapse">
              <!-- Search-->
              <!-- <div class="input-group-overlay d-lg-none my-3">
                <div class="input-group-prepend-overlay"><span class="input-group-text"><i class="czi-search"></i></span></div>
                <input v-if="checkAuthenticated()" class="form-control prepended-form-control" type="text" placeholder="Search for products">
              </div> -->

              <!-- Primary menu-->
              <div
                v-if="
                  checkAuthenticated() && typeof custObj.config !== 'undefined'
                "
              >
                <div class="navbar-nav">
                  <div
                    v-for="menuItem in getModules.config.modules"
                    :key="menuItem.title"
                    class="nav-item dropdown"
                  >
                    <router-link
                      class="nav-link dropdown-toggle"
                      :to="'/' + menuItem.app_url"
                      >{{ menuItem.title }}</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div id="app">
      <div id="content">
        <router-view></router-view>
      </div>
      <mobile-footer />
    </div>
  </div>
</template>

<script>
import mobileFooter from './components/mobileFooter.vue';
import { GlobalStore, GlobalMethods } from './global';
export default {
  name: 'app',
  data: function () {
    return {
      localStore: GlobalStore.isAuthenticated,
      userMenuVisible: false,
      versionNumber: GlobalStore.version,
      currentURL: '',
    };
  },
  computed: {
    headerLogo: function () {
      if (GlobalStore.isAuthenticated == false) {
        return GlobalStore.imageEndpoint + '/todaycx/logo.png';
      } else {
        if (typeof GlobalStore.custObj.config == 'undefined') {
          return GlobalStore.imageEndpoint + '/todaycx/logo.png';
        } else {
          if (typeof GlobalStore.custObj.images.logo_web_app != 'undefined') {
            console.log(GlobalStore.custObj.images.logo_web_app);
            return (
              GlobalStore.imageEndpoint +
              GlobalStore.custObj.images.logo_web_app
            );
          } else {
            return (
              GlobalStore.imageEndpoint +
              '/' +
              GlobalStore.custGuid +
              '/logo.png'
            );
          }
        }
      }
    },
    custObj: function () {
      console.log('GlobalStore.custObj', GlobalStore.custObj);
      return GlobalStore.custObj;
    },
    userObj: function () {
      console.log(GlobalStore.userObject);
      return GlobalStore.userObject;
    },
    userMeObj: function () {
      console.log(GlobalStore.userMeObj);
      return GlobalStore.userMeObj;
    },
    getModules() {
      return GlobalMethods.getParsedCustObj();
    },
  },
  methods: {
    getUsername: function () {
      console.log(this.userMeObj);
      if (this.userMeObj.given_name) {
        return (
          this.userMeObj.given_name +
          ' ' +
          this.userMeObj.last_name +
          ' (' +
          this.userMeObj.login +
          ')'
        );
      }
    },
    getInitials: function () {
      if (this.userMeObj.given_name) {
        return (
          this.userMeObj.given_name.toString().substr(0, 1) +
          this.userMeObj.last_name.toString().substr(0, 1)
        );
      }
    },
    getExpiry: function () {
      var secondsBeforeExpiry =
        GlobalStore.expires_at - GlobalMethods.getEpochSeconds();
      return secondsBeforeExpiry;
    },
    checkAuthenticated: function () {
      //alert(this.appName);
      return GlobalStore.isAuthenticated;
    },
    toggleUserMenu: function () {
      this.userMenuVisible = !this.userMenuVisible;
    },
    logOut: function () {
      this.userMenuVisible = false;
      GlobalMethods.clearSession();
      GlobalStore.isAuthenticated = false;
      this.$router.push('/login');
    },
  },
  //Left this here in case we need to debug expiry sny further.
  // mounted() {
  // setInterval(() => {
  //   console.log(this.getExpiry());
  // }, 1000);
  // },
  watch: {
    $route(to) {
      this.currentURL = to.path;
      document.title = to.meta.title
        ? GlobalStore.appTitle + ' | ' + to.meta.title
        : GlobalStore.appTitle;
    },
  },
  components: {
    mobileFooter,
  },
};
</script>

<style>
#app,
header {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
  margin: auto;
  padding-top: 0px;
  background-color: white;
}

#nav {
  background-color: lightgray;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  display: none;
}

header {
  width: 100%;
}

@media screen and (max-width: 760px) {
  header {
    position: fixed;
    z-index: 2000;
    width: 100%;
  }

  #content {
    padding-top: 70px;
  }
}

#nav .col-2 {
  font-size: 9pt;
  text-align: center;
}

#nav .row {
  margin-right: 10px;
  margin-left: 0px;
}

.navbar-nav {
  display: visible;
  flex-wrap: wrap;
}

#logo-bar {
  padding: 10px;
  margin-bottom: 10px;
}

#logo-bar font-awesome-icon {
  font-size: 30pt;
}

.logo-image-mobile {
  max-width: 142px;
  max-height: 50px;
}

.logo-image-desktop {
  max-width: 142px;
  max-height: 60px;
}

.navbar-collapse {
  justify-content: center;
}

.user-profile .dropdown-menu {
  /* width:20%; */
  float: right;
  right: 0px;
  left: unset;
  display: block;
  padding: 0;
}

.user-profile {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 30px !important;
}

.user-profile .dropdown-menu span {
  padding-left: 20px;
}

.user-profile .dropdown-menu .navbar-tool-icon {
  font-size: 10pt;
  line-height: 1.8rem;
}

p {
  margin-bottom: 0.5rem !important;
}

.profile-menu-logo {
  width: 35px;
  cursor: pointer;
}

.media-body {
  margin-left: 15px !important;
}

.avatar {
  /* Center the content */
  display: inline-block;
  vertical-align: middle;

  /* Used to position the content */
  position: relative;

  /* Colors */
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;

  /* Rounded border */
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin-right: 13px;
}

.avatar__letters {
  /* Center the content */
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
}

.navbar-tool-icon-box {
  width: 6rem !important;
}

@media screen and (max-width: 1279.98px) {
  .navbar-light.navbar-stuck-menu {
    display: none;
  }

  body {
    padding-bottom: 3.5rem !important;
  }
}

@media screen and (min-width: 760px) {
  .swipe-wrap img {
    max-width: 300px !important;
  }
}
@media screen and (max-width: 280px) {
  #appNavbar {
    display: contents;
  }
}
</style>
