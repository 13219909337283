<template>
  <div v-if="showModal" class="modal fade show" id="signin-modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            {{modalTitle}}
            <button @click="closeModal()" class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body tab-content py-4">
            <slot></slot>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'modal-popup',
  data() {
    return {
      appName: '',
      showModal: false,
      modalTitle: String
    }
  },
  props: {
    show: Boolean,
    
  },
  methods:  {
    openModal: function(title) {
      this.modalTitle = title;
      this.showModal = true;
    },
    closeModal: function() {
      this.showModal = false;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#signin-modal {
  display: block;
}
</style>
