<template>
	<!-- Toolbar for handheld devices-->
	<div
		class="cz-handheld-toolbar"
		v-if="checkAuthenticated()"
		v-bind:style="{ overflow: 'scroll' }"
	>
		<div
			class="d-table table-fixed"
			v-if="typeof custObj.config !== 'undefined'"
			v-bind:style="{
				width:
					this.windowWidth < custObj.config.modules.length * tabButtonWidth
						? custObj.config.modules.length * tabButtonWidth + 'px'
						: '100%',
				paddingRight: '10px'
			}"
		>
			<router-link
				v-for="menuItem in custObj.config.modules"
				:key="menuItem.title"
				class="d-table-cell cz-handheld-toolbar-item"
				:to="'/' + menuItem.app_url"
				v-bind:style="{ width: tabButtonWidth }"
				><span class="cz-handheld-toolbar-icon"
					><i
						:class="[
							currentTab == menuItem.app_url ? 'active' : '',
							menuItem.icon
						]"
					></i></span
				><span
					class="cz-handheld-toolbar-label"
					v-bind:class="{ active: currentTab == menuItem.app_url }"
					>{{ menuItem.title }}</span
				></router-link
			>
		</div>
	</div>
</template>

<script>
import { GlobalMethods, GlobalStore } from '../global';
export default {
	name: 'mobile-footer',
	data() {
		return {
			currentTab: '',
			windowWidth: window.innerWidth,
			tabButtonWidth: 50
		};
	},
	props: {
		rating: Object
	},
	watch: {
		$route(to) {
			this.currentTab = to.path.replace('/', '');
			if (to.fullPath.charAt(0) == '/') {
				this.currentTab = to.fullPath.substr(1).replace('%40', '@');
			} else {
				this.currentTab = to.fullPath.replace('%40', '@');
			}
			console.log('currentTab=', this.currentTab);
			console.log('to.fullPath', to.fullPath);
			console.log('htmlDecode currentTab', this.htmlDecode(this.currentTab));
		}
	},
	computed: {
		custObj() {
			return GlobalMethods.getParsedCustObj();
		}
	},
	mounted() {
		this.windowWidth = window.innerWidth;
		console.log(this.windowWidth);
		window.addEventListener('resize', () => {
			this.windowWidth = window.innerWidth;
		});
	},
	methods: {
		showMenuItem() {
			return false;
		},
		handleClick: function () {
			alert(this.appName);
		},
		checkAuthenticated: function () {
			//alert(this.appName);
			return GlobalStore.isAuthenticated;
		},
		htmlDecode: function (value) {
			var txt = document.createElement('textarea');
			txt.innerHTML = value;
			return txt.value;
		}
		// getMenuClass: function(menuItem) {
		//   //let icon = menuItem.icon.toString();
		//   const icon = "czi-star-filled";
		//   console.log(icon);
		//   return [
		//     {
		//       active: this.currentTab == menuItem.title,
		//     },
		//     { icon: true },
		//   ];
		// },
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.active {
	color: #fb7a47;
}

/* Bootstrap override */
@media (max-width: 1279.98px) {
	body {
		padding-bottom: 3.5rem;
	}
	.cz-handheld-toolbar {
		display: block;
	}
	.cz-handheld-toolbar + .btn-scroll-top {
		bottom: 4.5rem;
	}
}
</style>
