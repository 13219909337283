<template>
  <div>
    <div v-if="isLoading()">
      <page-loader></page-loader>
    </div>

    <div v-if="isSubmitting()">
      <uploader-progress></uploader-progress>
    </div>

    <div>
      <div style="display: inline-block; float: left">
        <a class="page-link top-back-button" @click="idx > 0 && prevItem()"
          ><i class="czi-arrow-left mr-2"></i>Prev Item</a
        >
      </div>
      <div style="display: inline-block">
        <a class="page-link top-back-button" @click="back()"
          ><i class="czi-arrow-up mr-2"></i>Back</a
        >
      </div>
      <div style="display: inline-block; float: right">
        <a
          class="page-link top-back-button"
          @click="idx < contentList.length - 1 && nextItem()"
          ><i class="czi-arrow-right mr-2"></i>Next Item</a
        >
      </div>
    </div>

    <!-- <a class="page-link top-back-button" @click="back()"
      ><i class="czi-arrow-left mr-2"></i>Back</a -->
    <!-- > -->
    <!-- <swipe :options="swipeOptions" ref="mySwipe">
      <swipe-item :key="index" v-for="(item, index) in contentList"> -->
    <div class="content-block" v-if="currentItem.title">
      <!-- <nav class="d-flex justify-content-between pt-2 content-top" aria-label="Page navigation">
          <ul class="pagination">
            <li class="page-item"><a class="page-link" @click="back()"><i class="czi-arrow-left mr-2"></i>Back</a></li>
          </ul>
      </nav> -->

      <h2 class="product-title font-size-lg">{{ currentItem.title }}</h2>

      <!-- <div class="product-title-header">
            <div class="product-title-header-1">
              <ul class="pagination back-button">
                <li class="page-item">
                  <a class="page-link" @click="back()"
                    ><i class="czi-arrow-left mr-2"></i>Back</a
                  >
                </li>
              </ul>
            </div>
            <div class="product-title-header-2">
              <h2 class="product-title font-size-lg">{{ item.title }}</h2>
            </div>
          </div> -->

      <!-- <img  class="content-image" v-bind:src="`${imageEndpoint}/${getCustGuid()}/${item.images.hero_image}`" alt="Product"> -->
      <div style="position: relative">
        <button
          v-if="currentItem.images.additional_images.length > 0"
          id="slider-left-button"
          class="trans-button"
          @click="goPrev(idx.toString())"
          type="button"
        >
          <i class="czi-arrow-left"></i>
        </button>
        <button
          v-if="currentItem.images.additional_images.length > 0"
          id="slider-right-button"
          class="trans-button"
          @click="goNext(idx.toString())"
          type="button"
        >
          <i class="czi-arrow-right"></i>
        </button>
        <swipe
          :options="imageSwipeOptions"
          :ref="'imageSwipe_' + idx.toString()"
        >
          <swipe-item>
            <div class="tcx-container mx-auto">
              <tcx-image
                className="content-image"
                :imageSrc="currentItem.images.hero_image_url"
                :aspectBlur="true"
                v-if="showImage"
              />
            </div>
          </swipe-item>
          <swipe-item
            :key="ai_index"
            v-for="(imageItem, ai_index) in currentItem.images
              .additional_images"
          >
            <div class="tcx-container mx-auto">
              <tcx-image
                className="content-image border-rounded"
                :imageSrc="imageItem"
                :aspectBlur="true"
              />
            </div>
          </swipe-item>
        </swipe>
      </div>

      <div class="blurb-bar">
        <div
          class="text-center blurb-bar__col1"
          style="padding: 5px 0px 0px 0px"
        >
          <a
            class="product-meta d-block font-size-xs pb-1 strap-line customer-color-primary"
            href="#"
            >{{ currentItem.summary }}</a
          >
        </div>
        <div
          class="text-right blurb-bar__col2"
          v-if="currentItem.hyperlink && currentItem.hyperlink.url != ''"
          style="padding: 10px 30px 0px 0px"
        >
          <a :href="currentItem.hyperlink.url" target="_blank"
            ><font-awesome-icon :icon="['fas', 'link']" size="2x"
          /></a>
        </div>
      </div>
      <div
        class="context-text"
        v-html="currentItem ? currentItem.body_html : ''"
      ></div>

      <form class="action-request-form">
        <div
          :key="ri_index"
          v-for="(request_item, ri_index) in currentItem.request_items"
          class="request-item-list"
        >
          <div
            class="checkbox-item"
            v-if="request_item.request_type == 'checkbox'"
          >
            <div class="checkbox-item-row">
              <div class="checkbox-input-wrapper">
                <label>
                  <input
                    :id="
                      currentItem.action_request_guid + '_' + request_item.id
                    "
                    @change="(evt) => (request_item.value = evt.target.checked)"
                    type="checkbox"
                    class="option-input checkbox"
                  />
                </label>
              </div>
              <div class="checkbox-text-wrapper">
                {{
                  request_item.mandatory == true
                    ? request_item.request_text + "*"
                    : request_item.request_text
                }}
              </div>
            </div>

            <hr />
          </div>
          <!-- <div class="form-check" >
          <input :id="item.action_request_guid + '_' + request_item.id" type="checkbox" class="form-check-input">
          <label class="form-check-label" for="exampleCheck1">{{request_item.request_text}}</label>
        </div> -->

          <div class="yesno-item" v-if="request_item.request_type == 'yesno'">
            <div v-if="request_item.mandatory == true">
              <control-yes-no
                @changed="(val) => (request_item.value = val)"
                :label="request_item.request_text + '*'"
                :id="currentItem.action_request_guid + '_' + request_item.id"
              >
              </control-yes-no>
            </div>
            <div v-else>
              <control-yes-no
                @changed="(val) => (request_item.value = val)"
                :label="request_item.request_text"
                :id="currentItem.action_request_guid + '_' + request_item.id"
              >
              </control-yes-no>
            </div>
            <hr />
          </div>

          <div
            class="yesno-item"
            v-if="request_item.request_type == 'imageupload'"
          >
            <div class="image-holder">
              <div
                v-for="(iItem, iindex) in imageUploadArray['image_' + ri_index]"
                :key="iindex"
              >
                <div>
                  <img
                    class="uploaded-image"
                    :src="getImageBlob(ri_index, iindex)"
                  />
                  <button
                    type="button"
                    @click="removeImage(ri_index, iindex)"
                    class="remove-button"
                  >
                    X
                  </button>
                </div>
              </div>
            </div>

            <!-- v-if="imageUploadArray.length > 0" :src="getImageBlob(ri_index)" -->

            <div class="image-uploader-label">
              {{
                request_item.mandatory == true
                  ? request_item.request_text + "*"
                  : request_item.request_text
              }}
            </div>
            <image-uploader
              :ref="
                'imageuploader_' +
                currentItem.action_request_guid +
                '_' +
                request_item.id
              "
              :id="
                'imageuploader_' +
                currentItem.action_request_guid +
                '_' +
                request_item.id
              "
              :preview="false"
              :className="['fileinput', { 'fileinput--loaded': hasImage }]"
              :capture="false"
              :debug="1"
              doNotResize="gif"
              accept="image/*"
              :quality="0.9"
              outputFormat="verbose"
              @input="setImage($event, ri_index)"
            >
              <label
                :for="
                  'imageuploader_' +
                  currentItem.action_request_guid +
                  '_' +
                  request_item.id
                "
                slot="upload-label"
              >
                <figure class="upload-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <path
                      class="path1"
                      d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                    ></path>
                  </svg>
                </figure>
                <span class="upload-caption">{{ "Click to upload" }}</span>
              </label>
            </image-uploader>
            <hr />
          </div>
        </div>
        <div class="due-by">Items marked with a * require a response.</div>
        <div class="due-by">
          Due by: {{ currentItem.due_by | moment("dddd, MMM Do YYYY") }}
        </div>

        <div
          class="store-selector"
          v-if="showStoreSelector && !allStoresSubmitted"
        >
          <p><b>Please Select which store you are responding for:</b></p>
          <select v-model="selectedStore">
            <option>-- Please Select --</option>
            <option v-for="option in storeList" v-bind:key="option">
              {{ option }}
            </option>
          </select>
        </div>
        <div v-if="allStoresSubmitted">
          <p><b>All stores have submitted a response.</b></p>
        </div>
        <button
          v-if="
            currentItem.mode == 'user' ||
            (currentItem.mode == 'store' && !allStoresSubmitted)
          "
          @click="submitActionRequestResponse(currentItem)"
          :disabled="submitting"
          type="button"
          class="btn btn-primary btn-shadow"
        >
          Submit Response
        </button>
      </form>
    </div>
    <!-- </swipe-item>
    </swipe> -->

    <!-- <nav
      class="d-flex justify-content-between content-top"
      aria-label="Page navigation"
    >
      <ul class="pagination back-button">
        <li class="page-item">
          <a class="page-link" @click="back()"
            ><i class="czi-arrow-left mr-2"></i>Back</a
          >
        </li>
      </ul>
    </nav> -->

    <div
      style="overflow: hidden; position: relative; width: 100%; display: none"
    >
      <nav
        class="d-flex justify-content-between pt-2 content-bottom"
        aria-label="Page navigation"
      >
        <ul class="pagination">
          <li v-visible="idx > 0" class="page-item">
            <a class="page-link" @click="prevItem()"
              ><i class="czi-arrow-left mr-2"></i>Prev</a
            >
          </li>
        </ul>
        <ul class="pagination">
          <li class="page-item d-sm-none">
            <span class="page-link page-link-static"
              >{{ idx + 1 }} / {{ contentList.length }}</span
            >
          </li>
          <!-- <li class="page-item active d-none d-sm-block" aria-current="page"><span class="page-link">1<span class="sr-only">(current)</span></span></li>
        <li class="page-item d-none d-sm-block"><a class="page-link" href="#">2</a></li>
        <li class="page-item d-none d-sm-block"><a class="page-link" href="#">3</a></li>
        <li class="page-item d-none d-sm-block"><a class="page-link" href="#">4</a></li>
        <li class="page-item d-none d-sm-block"><a class="page-link" href="#">5</a></li> -->
        </ul>
        <ul class="pagination">
          <li v-visible="idx < contentList.length - 1" class="page-item">
            <a class="page-link" @click="nextItem()" aria-label="Next"
              >Next<i class="czi-arrow-right ml-2"></i
            ></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { GlobalStore, GlobalMethods } from "../global";
import axios from "axios";
import pageLoader from "../components/pageLoader";
import uploaderProgress from "../components/uploaderProgress";
import controlYesNo from "../components/form/yesNo";
import tcxImage from "../components/tcxImage";
import { set } from "vue-analytics";

export default {
  name: "view-action-request",
  data() {
    return {
      // Flag for when current item is submitting.
      submitting: false,
      showStoreSelector: false,
      allStoresSubmitted: false,
      selectedStore: "-- Please Select --",
      imageEndpoint: GlobalStore.imageEndpoint,
      idx: this.itemIdx,
      currentItem: Object,
      start_x: 0,
      contentImgStyleObject: Object,
      showImage: true,
      swipeOptions: {
        startSlide: this.itemIdx,
        callback: this.swipe,
      },
      imageSwipeOptions: {
        stopPropagation: true,
        continuous: false,
        callback: this.imageswipe,
      },
      itemLiked: Boolean,
      hasImage: false,
      image: null,
      imageUploadArray: [],
    };
  },
  props: {
    itemIdx: Number,
    contentList: Array,
  },
  methods: {
    removeImage: function (ri_index, iindex) {
      this.imageUploadArray["image_" + ri_index].splice(iindex, 1);

      this.$forceUpdate();
    },
    goPrev: function (item_idx) {
      console.log(this.$refs["imageSwipe_" + item_idx]);
      this.$refs["imageSwipe_" + item_idx][0].prev();
    },
    goNext: function (item_idx) {
      this.$refs["imageSwipe_" + item_idx][0].next();
    },
    imageswipe: function (evt) {
      console.log(evt);
      //this.idx = evt + 1;
    },
    getImageBlob: function (idx, idx2) {
      console.log("get blob" + idx + ":" + idx2);

      var imageTag = "image_" + idx;
      if (this.imageUploadArray[imageTag][idx2]) {
        return this.imageUploadArray[imageTag][idx2].image_blob;
      } else {
        return null;
      }
      //
      // if (obj.image_upload.length > 0) {
      //   return 'data:' + obj.image_upload[0].content_type + ';base64,' + obj.image_upload[0].base64;
      // } else {
      //   return null;
      // }
    },

    setImage: function (output, ritem_idx) {
      //console.log(output);
      this.hasImage = true;
      //this.image = output;
      //console.log('Info', output.info);
      //console.log('Exif', output.exif);

      //console.log(output.dataUrl);

      var imageBlob = output.dataUrl.replace(
        "data:" + output.info.type + ";base64,",
        ""
      );

      //item.value = imageBlob;
      //var _item =
      //console.log();

      var imageObj = {
        alt_text: "",
        description: output.info.name,
        content_type: output.info.type,
        base64: imageBlob,
      };

      console.log(this.idx);

      console.log(imageObj);

      console.log(ritem_idx);
      //console.log(this.contentList[this.idx]);

      //this.contentList[this.idx].request_items[ritem_idx].value = imageObj.description;

      //this.contentList[this.idx].request_items[ritem_idx].image_upload = [];

      this.contentList[this.idx].request_items[ritem_idx].image_upload.push(
        imageObj
      );

      //this.contentList[this.idx].request_items[ritem_idx].image_blob = 'data:' + output.info.type + ';base64,' + imageBlob;

      var tagName = "image_" + ritem_idx;

      if (this.imageUploadArray.includes(tagName)) {
        //var idx = this.imageUploadArray.indexOf(tagName);
        // this.imageUploadArray.splice(idx,1);
        //his.imageUploadArray.push(tagName);
        this.imageUploadArray[tagName].push({
          image_blob: "data:" + output.info.type + ";base64," + imageBlob,
        });
      } else {
        this.imageUploadArray.push(tagName);

        this.imageUploadArray[tagName] = [];

        this.imageUploadArray[tagName].push({
          image_blob: "data:" + output.info.type + ";base64," + imageBlob,
        });
      }

      console.log(this.imageUploadArray);
      this.$forceUpdate();
      //console.log(this.contentList[this.idx].request_items);
    },
    htmlDecode(input) {
      var e = document.createElement("div");
      e.innerHTML = input;
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    },
    isItemLiked: function () {
      return this.itemLiked;
    },
    likeItem: function () {
      //this.contentList[itemIndex].liked = !this.contentList[itemIndex].liked;
      //console.log(this.contentList[itemIndex].liked);
      this.itemLiked = !this.itemLiked;

      return this.itemLiked;
    },
    swipe: function (evt) {
      this.idx = evt;
      this.itemLiked = false;
      this.getContentItem();
    },
    isLoading: function () {
      return this.loading;
    },
    isSubmitting: function () {
      return this.submitting;
    },
    getExpireDate: function (itm) {
      return itm.display.expires;
    },
    submitActionRequestResponse: function (item) {
      console.log(item);
      //console.log(GlobalStore.userObject);

      if (
        this.contentList[this.idx].mode == "store" &&
        GlobalStore.userMeObj.stores.length > 0 &&
        this.selectedStore == "-- Please Select --"
      ) {
        //this.showStoreSelector = true;
        GlobalStore.toastObj = this.$toasted.show(
          "Please Select a store before proceeding.",
          {
            theme: "outline",
            position: "top-center",
            duration: 5000,
            type: "error",
            fitToScreen: true,
            fullWidth: false,
          }
        );
      } else {
        var responseBody = {
          action_request_guid: item.action_request_guid,
          store: "",
          action_request_title: item.title,
          user_name: GlobalStore.userObject.given_name,
          response: [],
        };
        if (this.selectedStore != "-- Please Select --") {
          responseBody.store = this.selectedStore;
        }
        //console.log(responseBody);

        let doNotSubmit = false;

        item.request_items.forEach((itm) => {
          //let doNotSubmit = false;
          if (itm.request_type == "checkbox") {
            if (itm.mandatory && itm.value != true) {
              doNotSubmit = true;
              GlobalStore.toastObj = this.$toasted.show(
                "Please complete '" + itm.request_text + "' before submitting.",
                {
                  theme: "outline",
                  position: "top-center",
                  duration: 3000,
                  type: "error",
                  fitToScreen: true,
                  fullWidth: false,
                }
              );
            }
            responseBody.response.push({
              id: itm.id,
              request_type: itm.request_type,
              value: itm.value == true ? "checked" : "",
              image_upload: [],
            });
          }

          if (itm.request_type == "yesno") {
            if (itm.mandatory && itm.value != "no" && itm.value != "yes") {
              doNotSubmit = true;
              GlobalStore.toastObj = this.$toasted.show(
                "Please complete '" + itm.request_text + "' before submitting.",
                {
                  theme: "outline",
                  position: "top-center",
                  duration: 3000,
                  type: "error",
                  fitToScreen: true,
                  fullWidth: false,
                }
              );
            }
            responseBody.response.push({
              id: itm.id,
              request_type: itm.request_type,
              value: itm.value,
              image_upload: [],
            });
          }

          console.log(itm);

          if (itm.request_type == "imageupload") {
            if (itm.mandatory && itm.image_upload.length == 0) {
              doNotSubmit = true;
              GlobalStore.toastObj = this.$toasted.show(
                "Please complete '" + itm.request_text + "' before submitting.",
                {
                  theme: "outline",
                  position: "top-center",
                  duration: 3000,
                  type: "error",
                  fitToScreen: true,
                  fullWidth: false,
                }
              );
            }

            itm.image_upload.item_type = "action_response";

            responseBody.response.push({
              id: itm.id,
              request_type: itm.request_type,
              value: "",
              image_upload: itm.image_upload,
            });
          }
        });

        if (!doNotSubmit) {
          console.log(responseBody.response);
        }

        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + GlobalStore.access_token,
        };

        console.log(responseBody);

        if (!doNotSubmit) {
          this.submitting = true;
          axios
            .post(
              GlobalStore.APIEndpoint +
                "/action_response/" +
                item.action_request_guid,
              responseBody,
              {
                headers: headers,
              }
            )
            .then((resp) => {
              console.log(resp);
              //alert('Thank you, your response has been submitted.')
              GlobalStore.toastObj = this.$toasted.show(
                "Thank you, your response has been submitted.",
                {
                  theme: "outline",
                  position: "top-center",
                  duration: 10000,
                  type: "success",
                  fitToScreen: true,
                  fullWidth: false,
                }
              );

              this.$emit("itemCompleted");

              this.submitting = false;

              //this.$emit("back");
            })
            .catch((err) => {
              console.log(err.response);
              if (err.response.status == 422) {
                //alert('Error:' + err.response.data.message);
                if (err.response.data.message == "Response already exist") {
                  GlobalStore.toastObj = this.$toasted.show(
                    "Oops. It looks like you've already responded to this item.",
                    {
                      theme: "outline",
                      position: "bottom-center",
                      duration: 5000,
                      type: "error",
                      fitToScreen: true,
                      fullWidth: false,
                    }
                  );
                }

                this.submitting = false;

                //this.$emit('back');
              } else {
                //if (err.response.data.message == "Response already exist") {
                GlobalStore.toastObj = this.$toasted.show(
                  err.response.statusText,
                  {
                    theme: "outline",
                    position: "bottom-center",
                    duration: 5000,
                    type: "error",
                    fitToScreen: true,
                    fullWidth: false,
                  }
                );

                this.submitting = false;
                //}
              }
            });
        }
      }
    },
    getContentItem: function () {
      let guid = this.contentList[this.idx].action_request_guid;

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      //this.loading = true;
      this.showImage = false;

      axios
        .get(GlobalStore.APIEndpoint + "/action_request/" + guid, {
          headers: headers,
        })
        .then((resp) => {
          this.currentItem = this.contentList[this.idx];
          console.log(this.currentItem);
          this.showImage = true;

          if (GlobalStore.custObj) {
            set("dimension2", GlobalStore.custObj.name);
          }
          if (GlobalStore.userMeObj) {
            set("dimension1", GlobalStore.userMeObj.login);
            set("userId", GlobalStore.userMeObj.login);
            if (
              GlobalStore.userMeObj.stores &&
              GlobalStore.userMeObj.stores.length > 0
            ) {
              set("dimension3", GlobalStore.userMeObj.stores[0]);
            }
          }

          this.$ga.page({
            page:
              "/toDo/" +
              GlobalMethods.replaceAll(resp.data.title, " ", "-").toLowerCase(),
            title: resp.data.title,
            location: window.location.href,
          });
        })
        .catch((err) => {
          GlobalMethods.handleAPIerror(err);
        });
    },
    back: function () {
      this.$emit("back");
    },
    getCustGuid: function () {
      return GlobalStore.custGuid;
    },
    // swipeHandler(direction) {
    //   if (direction == "right" && this.idx > 0) {
    //     this.prevItem();
    //   } else if (
    //     direction == "left" &&
    //     this.idx < this.contentList.length - 1
    //   ) {
    //     this.nextItem();
    //   } else {
    //     this.contentImgStyleObject = { left: "0px", position: "relative" };
    //   }
    // },
    startHandler(evt) {
      if (evt.touches.length > 0) {
        console.log(evt);
        let touch = evt.touches[0];
        this.start_x = touch.clientX;
      }
    },
    movingHandler(evt) {
      if (evt.touches.length > 0) {
        let touch = evt.touches[0];

        let x_diff = touch.clientX - this.start_x;

        console.log(x_diff);
        if (x_diff < 100) {
          this.contentImgStyleObject = {
            left: parseInt(x_diff) + "px",
            position: "relative",
          };
        }

        //margin: '0px 0px 0px ' + parseInt(x_diff*2) + 'px'

        //this.start_x = touch.clientX;
      }

      //console.log(evt);

      // if (direction == "right" && this.idx > 0) {
      //   this.prevItem();
      // }
      // if (direction == "left" && this.idx < this.contentList.length-1) {
      //   this.nextItem();
      // }
    },
    nextItem() {
      this.idx++;
      this.getContentItem();
      window.scrollTo(0, 0);
    },
    prevItem() {
      this.idx--;
      this.getContentItem();
      window.scrollTo(0, 0);
    },
  },
  computed: {
    storeList() {
      if (GlobalStore.userMeObj.stores.length != 1) {
        // Remove stores that have responded from the list
        // console.log('this', this.contentList[this.itemIdx]);
        var respondedStores = this.contentList[this.idx].responses;
        // console.log("respondedStores", respondedStores);
        // Remove responded stores from the store list
        return GlobalStore.userMeObj.stores.filter(
          (el) => !respondedStores.includes(el)
        );
      } else {
        return GlobalStore.userMeObj.stores;
      }
    },
  },
  components: {
    pageLoader,
    controlYesNo,
    tcxImage,
    uploaderProgress,
  },
  async mounted() {
    //this.idx=0;
    this.getContentItem();
    GlobalMethods.getCustomerColors();

    // console.log('mounted', this.storeList);

    if (this.contentList[this.idx].mode == "store") {
      if (this.storeList.length > 0) {
        this.showStoreSelector = true;
        if (this.storeList.length == 1) {
          this.selectedStore = this.storeList[0];
        }
      } else {
        // Store list is empty - but could be because all stores have submitted. If so hide the Submit button.
        if (GlobalStore.userMeObj.stores.length > 0) {
          this.allStoresSubmitted = true;
        }
      }
    }

    //this.contentImgStyleObject = { left: '0px', position: 'fixed' };

    //console.log(this.$refs.mySwipe);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.remove-button {
  position: fixed;
  border-radius: 43px;
  background: rgba(255, 255, 255, 0.8);
  font-weight: bold;
  margin-right: 20px;
  left: calc(50% + 102px);
  margin-top: 10px;
  padding: 7px;
  width: 40px;
}
@media (max-width: 767px) {
  .remove-button {
    left: calc(50% + 65px);
    margin-top: 8px;
    padding: 2px;
    width: 30px;
  }
}

.page-link {
  border-style: solid;
  border-width: 2px;
  border-color: gray;
  height: 33px;
  right: 0px;
  font-size: 10pt;
  font-weight: bold;
  border-radius: 10px;
}

.image-holder {
  margin: 10px 0px 10px 0px;
  width: 100%;
  text-align: center;
}

.action-request-form {
  margin-bottom: 100px;
}

.content-top {
  position: fixed;
  top: 16px;
  z-index: 3000 !important;
  margin: auto;
  width: 100%;
}

@media (max-width: 767px) and (min-width: 585px) {
  .content-top {
    width: 530px !important;
    margin: auto;
  }
}

@media (min-width: 767px) {
  .content-top {
    width: 750px !important;
  }
  .top-back-button {
    max-width: 300px !important;
  }
}

.tcx-container {
  height: 500px;
  width: 500px;
}

/* Decreasing container size to fit mobile rendering */
@media only screen and (max-width: 500px) {
  .tcx-container {
    margin-top: auto;
    margin-bottom: auto;
    height: 345px;
    width: 345px;
  }
}

.content-bottom {
  position: fixed;
  bottom: 73px;
  width: 90%;
  background-color: white;
}

.content-block {
  margin-top: 0px 10px 0px 10px;
}

.due-by {
  font-size: 0.7rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.context-text {
  margin-top: 10px;
  font-size: 9pt;
  text-align: justify;
  margin: 10px;
}

.content-image {
  max-width: 400px;
  width: 95%;
}

h2 {
  font-size: 17pt;
  font-weight: bold;
  margin-top: 10px;
}

.strap-line {
  margin-top: 8px;
  font-size: 10pt !important;
  color: #42b983;
  font-weight: bold;
}

hr {
  margin: 20px 0px 10px 0px;
  /* display: none; */
  width: 100%;
}

.content-footer-container {
  margin: 0px 0px 30px 0px;
  font-size: 0.85em;
  color: #fe696a;
}

.col1 font-awesome-icon {
  font-size: 20pt;
}

.col1 span {
  vertical-align: top;
  margin-left: 5px;
  line-height: 2;
}

.col3 span {
  vertical-align: top;
  margin-left: 5px;
  line-height: 2;
}

.czi-thumb-up {
  fill: red !important;
}

.fa-2x {
  font-size: 1.5em !important;
}

.form-content {
  margin: 20px 0px 40px 0px;
}

/* Yes no button */

.yesno-item {
  margin-bottom: 40px;
}

.yesno-item
  label:not(.form-check-label):not(.custom-control-label):not(.custom-file-label):not(.custom-option-label) {
  color: rgba(0, 0, 0, 0.2);
}

/* Checkbox */

@keyframes click-wave {
  0% {
    height: 35px;
    width: 35px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}

.checkbox-item {
  margin-bottom: 20px;
  width: 80%;
}

.checkbox-item-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.checkbox-input-wrapper {
  width: 50px;
  display: block;
}

.checkbox-text-wrapper {
  flex-basis: 87%;
  font-size: 13px;
  font-weight: bold;
  vertical-align: middle;
  word-wrap: break-word;
  max-width: 80%;
  margin-top: 23px;
}

.checkbox-item .option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 37px;
  width: 37px;
  transition: all 0.15s ease-out 0s;
  background: lightgray;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: darkgrey;
}
.checkbox-item .option-input:hover {
  background: lightgray;
}
.checkbox-item .option-input:checked {
  background: #42b983;
}
.checkbox-item .option-input:checked::before {
  height: 35px;
  width: 35px;
  position: absolute;
  content: "✔";
  display: inline-block;
  font-size: 26.66667px;
  text-align: center;
  line-height: 35px;
}
.checkbox-item .option-input:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #42b983;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}

/* File Uploader */

.upload-caption {
  color: black;
}

.image-uploader-label {
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 10px;
}

.upload-icon {
  margin-top: 20px;
}

.request-item-list {
  text-align: left;
  width: 100%;
  margin: auto;
}

.uploaded-image {
  max-width: 200px;
  max-height: 300px;
  margin-bottom: 10px;
}

.blurb-bar {
  display: flex;
}

.blurb-bar__col1 {
  flex-basis: 100%;
}

.blurb-bar__col2 {
  flex-basis: 20%;
  margin-left: 20px;
  padding: 0px;
  padding: 10px 15px 0px 0px !important;
}

@media only screen and (max-width: 420px) {
  hr {
    width: 85vw;
  }
}

.product-title-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.product-title-header-1 {
}

.product-title-header-2 {
  width: 100%;
}

.back-button {
  min-width: 100px;
}

.top-back-button {
  max-width: 380px;
  margin: auto;
  margin-bottom: 10px;
}

.store-selector {
  margin-bottom: 10px;
}

.store-selector p {
  font-size: 12px;
}

.store-selector select {
  border-radius: 7px;
  font-size: 12px;
  padding: 4px;
}

.trans-button {
  background-color: rgba(255, 255, 255, 0.6);
  border-style: none;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.trans-button i {
  transform: scale(1.5);
  margin: 5px 0px 0px 0px;
}

#slider-left-button {
  position: absolute;
  left: 10px;
  top: calc(50% - 40px);
  z-index: 5000;
}

#slider-right-button {
  position: absolute;
  right: 10px;
  top: calc(50% - 40px);
  z-index: 5000;
}

/* .back-button {
  position: absolute;
  right: 80px;
}

@media (max-width: 767px) and (min-width: 585px) {
  .back-button {
  position: absolute;
  right: 40px;
}
} */
</style>
<style>
.fileinput {
  display: none !important;
}
</style>
