import Vue from "vue";
import axios from "axios";
import { GlobalStore } from "./global";

export const AdminAPI = new Vue({
  methods: {
    async getActionRequestData(data_request, limit = 100, pipeline = []) {
      var requestBody = {};
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + GlobalStore.access_token
      };

      const params = new URLSearchParams({
        "limit": limit
      })

      if (pipeline) {
        requestBody = {
            pipelines : pipeline
        }
      }

      try{
        return axios.post(`${GlobalStore.APIEndpoint}/todaycx-admin/dashboard/${data_request}${params.toString()}`,
            requestBody, { headers: headers }
            );
        }catch{
            return []
        }
    },

    async getActionRequestItem(action_request_guid) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      return await axios.get(
        GlobalStore.APIEndpoint +
          "/todaycx-admin/action_request/" +
          action_request_guid,
        {
          headers: headers,
        }
      );
    },

    // async getDashboardData(data_request) {
    //   const headers = {
    //     "Content-Type": "application/x-www-form-urlencoded",
    //     Authorization: "Bearer " + GlobalStore.access_token,
    //   };
    //   var url = GlobalStore.APIEndpoint + "/todaycx-admin/dashboard/" + data_request + "/";
    //   var requestBody = {};
    //   return await axios.post(url, requestBody, { headers: headers }        
    //   );
    // },
    getDashboardData: async function(data_request, limit = 100, pipeline = []) {
       
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + GlobalStore.access_token
      };

      var requestBody = {};
      if (pipeline) {
        requestBody = {
            pipelines : pipeline
        };
      }
      var query_string = "";

      if (limit) {
        query_string = "?limit=" + limit
      }
      try{
        return axios.post(GlobalStore.APIEndpoint + '/todaycx-admin/dashboard/' + data_request + query_string,
            requestBody,
            {
            headers: headers
            });
        }catch{
            return []
        }
    },

    async getActionRequestList(pageNo, archived, query, item_type, status) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      if (query != "") {
        query = (query ? query : "") + "*";

        let optional_filter = "";

        if (item_type != "All") {
          optional_filter = "&item_type=" + item_type;
        }

        return axios.get(
          GlobalStore.APIEndpoint +
            `/todaycx-admin/search/action_request?per_page=${
              GlobalStore.defaultPageSize
            }&page=${pageNo}&is_archived=${
              archived == true ? "true" : "false"
            }&query=${query}&publish_status=${status.toLowerCase()}${optional_filter}`,
          {
            headers: headers,
          }
        );
      } else {
        var status_filter = "";
        var archived_filter = "";
        var query_filter = "";

        if (status == "Published") {
          status_filter = "&publish_status=published";
        }

        if (status == "Draft") {
          status_filter = "&publish_status=draft";
        }

        archived_filter = "&is_archived=" + (archived ? "true" : "false");

        if (query != "") {
          query_filter = "&query=" + query;
        }

        if (item_type != "All") {
          query_filter = "&item_type=" + item_type;
        }

        // return axios.get(
        //   GlobalStore.APIEndpoint +
        //     "/todaycx-admin/top_story?per_page=" +
        //     GlobalStore.defaultPageSize +
        //     "&page=" +
        //     pageNo +
        //     status_filter +
        //     archived_filter +
        //     query_filter,
        //   {
        //     headers: headers,
        //   }
        // );

        return axios.get(
          GlobalStore.APIEndpoint +
            "/todaycx-admin/action_request?per_page=" +
            GlobalStore.defaultPageSize +
            "&page=" +
            pageNo +
            status_filter +
            archived_filter +
            query_filter,
          {
            headers: headers,
          }
        );
      }
    },

    formatToLocalTime: function(ts) {
      if (ts) {
        var itemDate = new Date(ts);
        var offset = 0;
        //   console.log(ts.indexOf('+'));
        if (ts.indexOf("+") == -1) {
          // not an ISO string - assume a UTC time
          offset = new Date().getTimezoneOffset();
        }
        var shiftedDate = new Date(itemDate.getTime() - offset * 60000);
        //   console.log(ts + ': ' + itemDate + '; offset=' + offset + '; shifted=' + shiftedDate);
        return shiftedDate.toLocaleString("en-AU");
      }
      return "Unknown";
    },
    
    fuzzyTime(datestamp) {
      // Make a fuzzy time
      var datestamp_ = Date.parse(datestamp);
      var current_time = new Date();
      // var tzOffset = current_time.getTimezoneOffset()
      // var adjusted_time = current_time.getTime() + (tzOffset * 60 * 1000)
      // console.log(datestamp_ + ": " + adjusted_time + ": " + current_time.getTime())
      var delta = Math.round((current_time - datestamp_) / 1000);

      var minute = 60,
        hour = minute * 60,
        day = hour * 24,
        week = day * 7;

      var fuzzy;
      if (delta < 0) {
        delta = -1 * delta;
        if (delta < 30) {
          fuzzy = "In a few seconds";
        } else if (delta < minute) {
          fuzzy = "In " + delta + " seconds.";
        } else if (delta < 2 * minute) {
          fuzzy = "In a minute";
        } else if (delta < hour) {
          fuzzy = "In " + Math.floor(delta / minute) + " minutes";
        } else if (Math.floor(delta / hour) == 1) {
          fuzzy = "In an hour";
        } else if (delta < day) {
          fuzzy = "In " + Math.floor(delta / hour) + " hours.";
        } else if (delta < day * 2) {
          fuzzy = "Tomorrow";
        } else if (delta < 7 * day) {
          fuzzy = "In " + Math.floor(delta / day) + " days";
        } else if (delta < week * 2) {
          fuzzy = "Next week";
        } else {
          fuzzy = "In " + Math.floor(delta / week) + " weeks";
        }
      } else {
        if (delta < 30) {
          fuzzy = "Just then";
        } else if (delta < minute) {
          fuzzy = delta + " seconds ago.";
        } else if (delta < 2 * minute) {
          fuzzy = "A minute ago";
        } else if (delta < hour) {
          fuzzy = Math.floor(delta / minute) + " minutes ago";
        } else if (Math.floor(delta / hour) == 1) {
          fuzzy = "1 hour ago";
        } else if (delta < day) {
          fuzzy = Math.floor(delta / hour) + " hours ago";
        } else if (delta < day * 2) {
          fuzzy = "Yesterday";
        } else if (delta < 7 * day) {
          fuzzy = Math.floor(delta / day) + " days ago";
        } else if (delta < week * 2) {
          fuzzy = "Last week";
        } else {
          fuzzy = Math.floor(delta / week) + " weeks ago";
        }
      }
      return fuzzy;
    },
  }
})
