<template>
  <div>
    <div v-if="isLoading()">
      <page-loader></page-loader>
    </div>
    <div>
      <div style="display: inline-block; float: left">
        <a class="page-link top-back-button" @click="idx > 0 && prevItem()"
          ><i class="czi-arrow-left mr-2"></i>Prev Item</a
        >
      </div>
      <div style="display: inline-block">
        <a class="page-link top-back-button" @click="back()"
          ><i class="czi-arrow-up mr-2"></i>Back</a
        >
      </div>
      <div style="display: inline-block; float: right">
        <a
          class="page-link top-back-button"
          @click="idx < contentList.length - 1 && nextItem()"
          ><i class="czi-arrow-right mr-2"></i>Next Item</a
        >
      </div>
    </div>

    <div class="content-block" v-if="currentItem.title">
      <h2 class="product-title font-size-lg">{{ currentItem.title }}</h2>

      <div
        v-if="
          currentItem.images.additional_images_url == null ||
          (currentItem.images.additional_images_url.length == 0 && showImage)
        "
        class="tcx-container mx-auto"
      >
        <tcx-image
          className="content-image border-rounded"
          :imageSrc="currentItem.images.hero_image_url"
          :aspectBlur="true"
        />
      </div>

      <div
        v-if="currentItem.images.additional_images_url.length > 0"
        style="position: relative"
      >
        <span class="image-count"
          >Image {{ currentImageIndex }} of
          {{ currentItem.images.additional_images.length + 1 }}</span
        >
        <div style="position: relative">
          <button
            id="slider-left-button"
            class="trans-button"
            @click="goPrev()"
            type="button"
          >
            <i class="czi-arrow-left"></i>
          </button>
          <button
            id="slider-right-button"
            class="trans-button"
            @click="goNext()"
            type="button"
          >
            <i class="czi-arrow-right"></i>
          </button>
          <swipe :options="imageSwipeOptions" :ref="'imageSwiper'">
            <swipe-item>
              <div class="tcx-container mx-auto">
                <tcx-image
                  className="content-image border-rounded"
                  :imageSrc="currentItem.images.hero_image_url"
                  :aspectBlur="true"
                  v-if="showImage"
                />
              </div>
            </swipe-item>
            <swipe-item
              :key="ai_index"
              v-for="(imageItem, ai_index) in currentItem.images
                .additional_images_url"
            >
              <div class="tcx-container mx-auto">
                <tcx-image
                  className="content-image border-rounded"
                  :imageSrc="imageItem"
                  :aspectBlur="true"
                />
              </div>
            </swipe-item>
          </swipe>
        </div>
      </div>

      <a
        class="product-meta d-block font-size-xs pb-1 strap-line customer-color-primary"
        href="#"
        >{{ currentItem.summary }}</a
      >
      <div class="context-text" v-html="currentItem.body_html"></div>
      <hr />
      <div class="row content-footer-container">
        <div
          class="col text-left col1 customer-color-primary"
          @click="handleLike(currentItem)"
        >
          <font-awesome-icon
            v-if="!isItemLiked()"
            :icon="['far', 'thumbs-up']"
            size="2x"
          />
          <font-awesome-icon
            v-if="isItemLiked()"
            :icon="['fas', 'thumbs-up']"
            size="2x"
          />
          <span>Like</span>
        </div>
        <div class="col text-center col2">
          <a
            v-if="currentItem.hyperlink && currentItem.hyperlink.url != ''"
            :href="currentItem.hyperlink.url"
            target="_blank"
            ><font-awesome-icon :icon="['fas', 'link']" size="2x"
          /></a>
        </div>
        <div class="col text-right col4 customer-color-primary">
          <span>{{ currentItem.category }}</span>
        </div>
      </div>
      <div class="row no-gutters">
        <div
          class="float-start tag-list"
          v-for="tag in currentItem.tags"
          v-bind:key="tag"
        >
          <span class="badge badge-pill badge-secondary">{{ tag }}</span>
        </div>
      </div>
    </div>

    <div
      style="overflow: hidden; position: relative; width: 100%; display: none"
    >
      <nav
        class="d-flex justify-content-between pt-2 content-bottom"
        aria-label="Page navigation"
      >
        <ul class="pagination">
          <li v-visible="idx > 0" class="page-item">
            <a class="page-link" @click="prevItem()"
              ><i class="czi-arrow-left mr-2"></i>Prev</a
            >
          </li>
        </ul>
        <ul class="pagination">
          <li class="page-item d-sm-none">
            <span class="page-link page-link-static"
              >{{ idx + 1 }} / {{ contentList.length }}</span
            >
          </li>
        </ul>
        <ul class="pagination">
          <li v-visible="idx < contentList.length - 1" class="page-item">
            <a class="page-link" @click="nextItem()" aria-label="Next"
              >Next<i class="czi-arrow-right ml-2"></i
            ></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { GlobalStore, GlobalMethods } from "../global";
import axios from "axios";
import pageLoader from "../components/pageLoader";
import tcxImage from "../components/tcxImage";
import { set } from "vue-analytics";

export default {
  name: "view-content",
  data() {
    return {
      showImage: true,
      imageEndpoint: GlobalStore.imageEndpoint,
      offsetIdx: -1,
      idx: this.itemIdx,
      currentItem: Object,
      start_x: 0,
      contentImgStyleObject: Object,
      swipeOptions: {
        startSlide: this.itemIdx,
        callback: this.swipe,
      },
      imageSwipeOptions: {
        stopPropagation: true,
        continuous: false,
        callback: this.imageswipe,
      },
      currentImageIndex: 1,
      itemLiked: Boolean,
      loading: false,
    };
  },
  props: {
    itemIdx: Number,
    contentList: Array,
  },
  methods: {
    getIdx: function () {
      //return this.idx;

      console.log(this.$refs["mySwipe"].getPos());
      return this.$refs["mySwipe"].getPos();
    },
    goPrev: function () {
      this.$refs["imageSwiper"].prev();
    },
    goNext: function () {
      this.$refs["imageSwiper"].next();
    },
    isItemLiked: function () {
      return this.itemLiked;
    },
    htmlDecode(input) {
      var e = document.createElement("div");
      e.innerHTML = input;
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    },
    swipe: function (evt) {
      this.idx = evt;
      this.itemLiked = false;
      this.getContentItem();
    },
    imageswipe: function (evt) {
      this.currentImageIndex = evt + 1;
    },
    isLoading: function () {
      return this.loading;
    },
    getContentItem: function () {
      //console.log(this.contentList);

      this.contentList.forEach((element) => {
        if (element.images.additional_images.length == 0) {
          this.offsetIdx++;
          console.log("OffsetIdx",this.offsetIdx);
        }
      });

      this.currentImageIndex = 1;
      if (this.$refs["imageSwiper"]) {
        this.$refs["imageSwiper"].slide(0, 1);
      }

      //this.offsetIdx--;

      let guid = this.contentList[this.idx].content_guid;

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;
      this.showImage = false;

      axios
        .get(GlobalStore.APIEndpoint + "/content?content_guid=" + guid, {
          headers: headers,
        })
        .then((resp) => {
          // Setting like status
          this.itemLiked = resp.data.data[0].rating.liked_by_me;

          this.currentItem = this.contentList[this.idx];

          this.currentItem.body_html = resp.data.data[0].body_html;

          this.loading = false;
          this.showImage = true;

          if (GlobalStore.custObj) {
            set("dimension2", GlobalStore.custObj.name);
          }
          if (GlobalStore.userMeObj) {
            set("dimension1", GlobalStore.userMeObj.login);
            set("userId", GlobalStore.userMeObj.login);
            if (
              GlobalStore.userMeObj.stores &&
              GlobalStore.userMeObj.stores.length > 0
            ) {
              set("dimension3", GlobalStore.userMeObj.stores[0]);
            }
          }

          this.$ga.page({
            page:
              "/" +
              resp.data.data[0].content_item_type +
              "/" +
              GlobalMethods.replaceAll(
                resp.data.data[0].title,
                " ",
                "-"
              ).toLowerCase(),
            title: resp.data.data[0].title,
            location: window.location.href,
          });

          // this.loading = false;
          // this.contentImgStyleObject = { left: '0px', position: 'relative' };
          //this.itemList = resp.data.data;
        })
        .catch((err) => {
          GlobalMethods.handleAPIerror(err);
        });
    },

    /**
     * Handle events when user likes a content item.
     * @param item Object containing the guid of the item to be liked.
     */
    handleLike(item) {
      console.log("like click", item)
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      axios
        .put(
          `${GlobalStore.APIEndpoint}/content/${item.content_guid}/like`,
          {},
          { headers: headers }
        )
        .then(() => {
          this.itemLiked = !this.itemLiked;
        })
        .catch(() => {
          GlobalStore.toastObj = this.$toasted.show(
            "Sorry, you have already liked this item!",
            {
              theme: "outline",
              position: "top-center",
              duration: 3000,
              type: "error",
              fitToScreen: true,
              fullWidth: false,
            }
          );
        });
    },

    back: function () {
      this.$emit("back");
    },
    getCustGuid: function () {
      return GlobalStore.custGuid;
    },
    swipeHandler(direction) {
      if (direction == "right" && this.idx > 0) {
        this.prevItem();
      } else if (
        direction == "left" &&
        this.idx < this.contentList.length - 1
      ) {
        this.nextItem();
      } else {
        this.contentImgStyleObject = { left: "0px", position: "relative" };
      }
    },
    startHandler(evt) {
      if (evt.touches.length > 0) {
        let touch = evt.touches[0];
        this.start_x = touch.clientX;
      }
    },
    nextItem() {
      this.idx++;
      this.getContentItem();
      window.scrollTo(0, 0);
    },
    prevItem() {
      this.idx--;
      this.getContentItem();
      window.scrollTo(0, 0);
    },
  },
  components: {
    pageLoader,
    tcxImage,
  },
  async mounted() {
    this.getContentItem();
    GlobalMethods.getCustomerColors();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page-link {
  border-style: solid;
  border-width: 2px;
  border-color: gray;
  height: 33px;
  right: 0px;
  font-size: 10pt;
  font-weight: bold;
  border-radius: 10px;
}
.trans-button {
  background-color: rgba(255, 255, 255, 0.6);
  border-style: none;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.trans-button i {
  transform: scale(1.5);
  margin: 5px 0px 0px 0px;
}
.content-top {
  position: fixed;
  top: 16px;
  z-index: 3000 !important;
  margin: auto;
  width: 100%;
}

@media (max-width: 767px) and (min-width: 585px) {
  .content-top {
    width: 530px !important;
    margin: auto;
  }
}

@media (min-width: 767px) {
  .content-top {
    width: 750px !important;
  }
  .top-back-button {
    max-width: 300px !important;
  }
}

.tcx-container {
  height: 500px;
  width: 500px;
}

/* Decreasing container size to fit mobile rendering */
@media only screen and (max-width: 500px) {
  .tcx-container {
    margin-top: auto;
    margin-bottom: auto;
    height: 345px;
    width: 345px;
  }
}

.content-bottom {
  position: fixed;
  bottom: 73px;
  width: 90%;
  background-color: white;
}

.content-block {
  margin-top: 0px 10px 0px 10px;
}

.context-text {
  margin-top: 10px;
  font-size: 9pt;
  text-align: justify;
  margin: 10px;
}

.content-image {
  max-width: 400px;
  width: 95%;
}

.content-image img {
  max-width: 300px !important;
}

h2 {
  font-size: 17pt;
  font-weight: bold;
  margin-top: 10px;
}

.strap-line {
  margin-top: 8px;
  font-size: 10pt !important;
  color: #42b983;
  font-weight: bold;
}

hr {
  margin: 20px 0px 10px 0px;
}

.content-footer-container {
  font-size: 0.85em;
  color: #fe696a;
}

.col1 font-awesome-icon {
  font-size: 20pt;
}

.col1 span {
  vertical-align: top;
  margin-left: 5px;
  line-height: 2;
}

.col3 span {
  vertical-align: top;
  margin-left: 5px;
  line-height: 2;
}

.czi-thumb-up {
  fill: red !important;
}

.fa-2x {
  font-size: 1.5em !important;
}

.border-rounded {
  border-radius: 5px !important;
}

.image-count {
  font-size: 0.7em;
  font-weight: bold;
}

#slider-left-button {
  position: absolute;
  left: 10px;
  top: calc(50% - 40px);
  z-index: 5000;
}

#slider-right-button {
  position: absolute;
  right: 10px;
  top: calc(50% - 40px);
  z-index: 5000;
}

.product-title-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.product-title {
  margin-bottom: 10px !important;
}

.product-title-header-1 {
}

.product-title-header-2 {
  width: 100%;
}

.back-button {
  min-width: 100px;
}

.top-back-button {
  max-width: 120px;
  margin: auto;
}

/* .back-button {
  position: absolute;
  right: 80px;
} */

/* @media (max-width: 767px) and (min-width: 585px) {
  .back-button {
    position: absolute;
    right: 40px;
  }
} */
</style>
