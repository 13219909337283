
import Vue from 'vue'
import axios from "axios";
import { GlobalStore } from '../global'

  export const viewMethods = new Vue({
    methods: {

    getViewData: async function(data_request, per_page = 100, page = 1, pipeline = []) {
       
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + GlobalStore.access_token
      };

      var requestBody = {};
      if (pipeline) {
          if (typeof(pipeline) == Object){
              pipeline = [pipeline]
          }
        requestBody = {
            pipelines : pipeline
        };
      }

      const params = new URLSearchParams({
        ...(per_page && {"per_page": per_page}),
        ...(page && {"page": page})
      })

      try{
          // console.log('posting to:', GlobalStore.APIEndpoint + '/view/' + data_request + query_string);
          // console.log('requestBody:', requestBody);
        return await axios.post(`${GlobalStore.APIEndpoint}/view/${data_request}?${params.toString()}`,
            requestBody,
            {
            headers: headers
            });
        }catch{
            return []
        }
    }
  }
})