<template>
	<div class="tcx-container">
		<div class="main-container">
			<div v-if="!imageLoaded" class="skeleton-load" />

			<!-- No background blur -->
			<img
				v-if="!this.aspectBlur"
				:src="this.imagePath + this.imageObj.url"
				:alt="this.imageObj.alt_text"
				:title="this.imageObj.description"
				:class="className"
				@load="onImageLoad"
			/>

			<!-- With background blur -->
			<div v-if="this.aspectBlur" class="image-container">
				<div class="blur-image-container">
					<div class="blur-background">
						<img
							:src="this.imagePath + this.imageObj.url"
							:alt="this.imageObj.alt_text"
							:title="this.imageObj.description"
						/>
					</div>
					<img
						class="main-image"
						:src="this.imagePath + this.imageObj.url"
						:alt="this.imageObj.alt_text"
						:title="this.imageObj.description"
						@load="onImageLoad"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { GlobalStore } from '../global';
import axios from 'axios';

export default {
	name: 'tcx-image',
	data() {
		return {
			imagePath: '',
			custGuid: GlobalStore.custGuid,
			imageObj: { url: '', description: '', alt_text: '' },
			imageLoaded: false
		};
	},
	props: {
		imageSrc: String,
		className: String,
		aspectBlur: {
			default: false,
			type: Boolean
		}
	},
	methods: {
		renderImage() {
			if (this.imageSrc && this.imageSrc.includes('.')) {
				this.imagePath = GlobalStore.imageEndpoint; // + '/' + this.custGuid + '/';
				this.imageObj.url = this.imageSrc.replace(this.imagePath, ""); // be forgiving for relative or absolute url
				this.imageObj.description = 'static';
				// console.log('Using provided URL:', this.imageObj.url);
				// console.log("Provided image object: ", this.imageObj)
				// console.log("Provided image path: ", this.imagePath)
				// console.log("Provided Full image url:", this.imagePath + this.imageObj.url);
			} else {
				// console.log('Retrieving image:', this.imageSrc);
				//I used XMLHttpRequest as I could not get Axios to return a synchronous response correctly.
				// var request = new XMLHttpRequest();

				// request.open(
				//   "GET",
				//   GlobalStore.APIEndpoint + "/image/" + this.imageSrc,
				//   true  //makes the request synchronous
				// );
				// request.setRequestHeader(
				//   "Content-Type",
				//   "application/x-www-form-urlencoded"
				// );
				// request.setRequestHeader(
				//   "Authorization",
				//   "Bearer " + GlobalStore.access_token
				// );

				// request.send(null);

				// this.imagePath = GlobalStore.imageEndpoint;
				// this.imageObj = JSON.parse(request.responseText);
				const headers = {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: 'Bearer ' + GlobalStore.access_token
				};
				if (!this.imageSrc) {
					// console.log("MISSING IMAGESRC:" + this.imageSrc);
					this.imagePath = GlobalStore.imageEndpoint;
					this.imageObj = GlobalStore.placeholderImage;
				} else {
					axios
						.get(GlobalStore.APIEndpoint + '/image/' + this.imageSrc, {
							headers: headers
						})
						.then((resp) => {
							if (resp.status == 200) {
								this.imagePath = GlobalStore.imageEndpoint;
								this.imageObj = resp.data;
							} else {
								// Some problem retriving the image
								console.log(resp);
								this.imagePath = GlobalStore.imageEndpoint;
								this.imageObj = GlobalStore.placeholderImage;
							}
							// console.log("Retrieved image object: ", this.imageObj)
							// console.log("Retrieved image path: ", this.imagePath)
							// console.log("Retrieved Full image url:", this.imagePath + this.imageObj.url);
						});
				}
			}
			return { className: this.className };
		},

		/**
		 * Setter for 'this.imageLoaded'.
		 */
		onImageLoad() {
			this.imageLoaded = true;
		}
	},
	components: {},
	async mounted() {
		this.renderImage();
	}
};
</script>

<style scoped>
* {
	--border: 3px;
}

.tcx-container {
	height: 100%;
	width: 100%;
}

.main-container {
	position: relative;
	height: 100%;
	width: 100%;
}

.image-container {
	overflow: hidden;
	aspect-ratio: 1;
}

.image-container img {
	min-width: 100%;
	max-height: 100%;
}

.blur-image-container {
	height: 100%;
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
}

.blur-background {
	border-radius: var(--border);
	display: flex;
	position: absolute;
	overflow: hidden;
	height: 100%;
	width: 100%;
}
.blur-background img {
	filter: blur(15px);
	position: absolute;
	min-width: 100%;
	min-height: 100%;
}

.main-image {
	height: 100%;
	width: 100%;
	object-fit: contain;
	overflow: hidden;
	z-index: 1;
	filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.35));
}

/* ************* */
/* SKELETON LOAD */
/* ************* */
.skeleton-load {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgb(236, 236, 236);
	border-radius: var(--border);
	z-index: 2;
	overflow: hidden;
}

.skeleton-load::before {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.4) 50%,
		rgba(255, 255, 255, 0) 100%
	);
	animation: shimmer 1s infinite;
}

@keyframes shimmer {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}
</style>
