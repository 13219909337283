import Vue from "vue";
import axios from "axios";
import { GlobalMethods, GlobalStore } from "../global";


export const lazyLoad = new Vue({
  data: {
    /** Default number of items to fetch per scroll event. */
    DEFAULT_ITEM_COUNT: 5
  },

  methods: {
    /**
     * Gets specified page of data.
     * @param {String} url API url including origin.
     * @param {URLSearchParams} searchParams Optional search parameters.
     * @param {Number} page Integer representing the page of data to request.
     * @param {Number} per_page Optional specification for number of items returned.
     */
    async getNextItems(path, searchParams, page, per_page=this.DEFAULT_ITEM_COUNT) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      const params = new URLSearchParams({
        // Spreading any additional request parameters.
        ...Object.fromEntries(searchParams),
        "per_page": per_page,
        "page": page
      })
      
      try {
        return await axios.get(`${path}?${params.toString()}`, {
          headers: headers,
        })
      } catch (error) {
        GlobalMethods.handleAPIerror(error);
        return undefined;
      }
    },
  }
})