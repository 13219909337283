var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('page-loader'):_vm._e(),_c('modal-popup',{ref:"modal"},[_c('star-rating',{attrs:{"title":_vm.selectedStoryTitle,"mode":"input"},on:{"submitRating":function($event){return _vm.submitRating($event)}}})],1),_c('div',{staticClass:"container pb-5 mb-2 mb-md-4 top-stories"},[_c('div',{staticClass:"row pt-3 mx-n2"},[_c('multiselect',{staticClass:"filter pb-3",attrs:{"tag-placeholder":"Add this as new tag","placeholder":"Select a tag","label":"name","track-by":"code","options":_vm.tagList,"multiple":true},on:{"select":_vm.tagSelected,"remove":_vm.tagSelected},model:{value:(_vm.selectedFilterItems),callback:function ($$v) {_vm.selectedFilterItems=$$v},expression:"selectedFilterItems"}}),(!_vm.loading)?_vm._l((_vm.filteredItemList),function(item,index){return _c('div',{key:index,staticClass:"col-md-4 col-sm-6 px-2 mb-4"},[_c('div',{staticClass:"card product-card",staticStyle:{"height":"100%"}},[(item.products.length > 0)?_c('div',{staticClass:"card-header",domProps:{"innerHTML":_vm._s(
                _vm.getProductHeading(item.title, item.products[0].product_guid)
              )}}):_vm._e(),(item.products.length == 0)?_c('div',{staticClass:"card-header"},[_c('h6',{staticClass:"product-heading"},[_vm._v(_vm._s(item.title))])]):_vm._e(),_c('div',{staticStyle:{"position":"relative"}},[(
                  item.images.additional_images.length +
                    (item.images.hero_image ? 1 : 0) >
                  1
                )?_c('button',{staticClass:"trans-button",attrs:{"id":"slider-left-button","type":"button"},on:{"click":function($event){_vm.goPrev(index.toString())}}},[_c('i',{staticClass:"czi-arrow-left"})]):_vm._e(),(
                  item.images.additional_images.length +
                    (item.images.hero_image ? 1 : 0) >
                  1
                )?_c('button',{staticClass:"trans-button",attrs:{"id":"slider-right-button","type":"button"},on:{"click":function($event){_vm.goNext(index.toString())}}},[_c('i',{staticClass:"czi-arrow-right"})]):_vm._e(),(_vm.showImages)?_c('swipe',{ref:'imageSwipe_' + index.toString(),refInFor:true,attrs:{"options":_vm.imageSwipeOptions}},[_c('swipe-item',[(_vm.showImages)?_c('tcx-image',{attrs:{"className":"img-fluid","imageSrc":item.images.hero_image_url}}):_vm._e()],1),_vm._l((item.images
                    .additional_images_url),function(imageitem,ai_index){return _c('swipe-item',{key:ai_index},[(_vm.showImages)?_c('tcx-image',{attrs:{"className":"content-image border-rounded","imageSrc":imageitem}}):_vm._e()],1)})],2):_vm._e()],1),_c('div',{staticClass:"card-body py-2"},[_c('a',{staticClass:"product-meta d-block font-size-xs pb-1 strap-line customer-color-primary",attrs:{"href":"#"}},[_vm._v(_vm._s(item.strapline))])]),(item.products.length == 1)?_c('div',{staticClass:"py-2"},_vm._l((item.products),function(product,pindex){return _c('div',{key:pindex,staticClass:"font-size-sm features-italics",domProps:{"innerHTML":_vm._s(_vm.getProductLine(product.product_guid))}})}),0):_vm._e(),(item.products.length > 1)?_c('div',{staticClass:"py-2"},_vm._l((item.products),function(product,pindex){return _c('div',{key:pindex,staticClass:"font-size-sm features-italics"},[_c('div',{staticClass:"product-expander",on:{"click":function($event){return _vm.expandProduct(product)}}},[_vm._v(" "+_vm._s(_vm.getProductName(product.product_guid))+" "),_c('div',{staticClass:"product-expander__expand-button",domProps:{"innerHTML":_vm._s(
                      product.expanded == true
                        ? "<i class='czi-add rotated'></i>"
                        : "<i class='czi-add'></i>"
                    )}})]),(product.expanded && product.expanded == true)?_c('div',{staticClass:"product-expander__body",domProps:{"innerHTML":_vm._s(_vm.getProductLine(product.product_guid))}}):_vm._e()])}),0):_vm._e(),_c('div',{staticClass:"card-body py-2"},[_c('a',{staticClass:"product-meta d-block font-size-xs pb-1 addon-prompt customer-color-primary",attrs:{"href":"#"}},[_vm._v(_vm._s(item.addon_prompt))])]),_c('div',{staticClass:"card-body py-2 star-box"},[_c('div',{staticClass:"my-class d-flex justify-content-between",on:{"click":function($event){return _vm.ratingClick(item.top_story_guid)}}},[_c('star-rating',{attrs:{"mode":"readonly","rating":item.rating}})],1)]),_c('div',{staticClass:"p-1 card-footer row no-gutters"},[_c('div',{staticClass:"col-12",on:{"click":function($event){return _vm.viewContent(index)}}},_vm._l((item.tags),function(tag){return _c('div',{key:tag,staticClass:"float-start tag-list"},[_c('span',{staticClass:"badge badge-pill badge-secondary"},[_vm._v(_vm._s(tag))])])}),0)])])])}):_vm._e()],2),(_vm.loadingScrollItems)?_c('div',{staticClass:"pt-3"},[_c('spinner')],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }