<template>
  <div>
    <page-loader v-if="loading" />
    <modal-popup ref="modal">
      <star-rating
        :title="selectedStoryTitle"
        mode="input"
        @submitRating="submitRating($event)"
      ></star-rating>
    </modal-popup>

    <div class="container pb-5 mb-2 mb-md-4 top-stories">
      <!-- Products grid-->
      <div class="row pt-3 mx-n2">
        <!-- Product-->

        <multiselect
          v-model="selectedFilterItems"
          tag-placeholder="Add this as new tag"
          placeholder="Select a tag"
          label="name"
          track-by="code"
          :options="tagList"
          :multiple="true"
          class="filter pb-3"
          @select="tagSelected"
          @remove="tagSelected"
        ></multiselect>
        <template v-if="!loading">
          <div
            class="col-md-4 col-sm-6 px-2 mb-4"
            v-for="(item, index) in filteredItemList"
            :key="index"
          >
            <!-- class="product-heading" -->
            <div style="height: 100%" class="card product-card">
              <div
                v-if="item.products.length > 0"
                class="card-header"
                v-html="
                  getProductHeading(item.title, item.products[0].product_guid)
                "
              ></div>
              <div v-if="item.products.length == 0" class="card-header">
                <h6 class="product-heading">{{ item.title }}</h6>
              </div>
              <!-- <img v-bind:src="`${imageEndpoint}/${getCustGuid()}/${item.images.thumbnail_image}`"  alt="Product"> -->
              <div style="position: relative">
                <button
                  v-if="
                    item.images.additional_images.length +
                      (item.images.hero_image ? 1 : 0) >
                    1
                  "
                  id="slider-left-button"
                  class="trans-button"
                  @click="goPrev(index.toString())"
                  type="button"
                >
                  <i class="czi-arrow-left"></i>
                </button>
                <button
                  v-if="
                    item.images.additional_images.length +
                      (item.images.hero_image ? 1 : 0) >
                    1
                  "
                  id="slider-right-button"
                  class="trans-button"
                  @click="goNext(index.toString())"
                  type="button"
                >
                  <i class="czi-arrow-right"></i>
                </button>
                <swipe
                  :options="imageSwipeOptions"
                  :ref="'imageSwipe_' + index.toString()"
                  v-if="showImages"
                >
                  <swipe-item>
                    <tcx-image
                      className="img-fluid"
                      :imageSrc="item.images.hero_image_url"
                      v-if="showImages"
                    />
                  </swipe-item>

                  <swipe-item
                    :key="ai_index"
                    v-for="(imageitem, ai_index) in item.images
                      .additional_images_url"
                  >
                    <tcx-image
                      className="content-image border-rounded"
                      :imageSrc="imageitem"
                      v-if="showImages"
                    />
                  </swipe-item>
                </swipe>
              </div>

              <div class="card-body py-2">
                <a
                  class="product-meta d-block font-size-xs pb-1 strap-line customer-color-primary"
                  href="#"
                  >{{ item.strapline }}</a
                >
              </div>
              <div class="py-2" v-if="item.products.length == 1">
                <div
                  v-for="(product, pindex) in item.products"
                  :key="pindex"
                  class="font-size-sm features-italics"
                  v-html="getProductLine(product.product_guid)"
                ></div>
              </div>
              <div class="py-2" v-if="item.products.length > 1">
                <div
                  v-for="(product, pindex) in item.products"
                  :key="pindex"
                  class="font-size-sm features-italics"
                >
                  <div @click="expandProduct(product)" class="product-expander">
                    {{ getProductName(product.product_guid) }}
                    <div
                      class="product-expander__expand-button"
                      v-html="
                        product.expanded == true
                          ? `<i class='czi-add rotated'></i>`
                          : `<i class='czi-add'></i>`
                      "
                    ></div>
                  </div>
                  <div
                    v-if="product.expanded && product.expanded == true"
                    class="product-expander__body"
                    v-html="getProductLine(product.product_guid)"
                  ></div>
                </div>
              </div>

              <div class="card-body py-2">
                <a
                  class="product-meta d-block font-size-xs pb-1 addon-prompt customer-color-primary"
                  href="#"
                  >{{ item.addon_prompt }}</a
                >
              </div>

              <div class="card-body py-2 star-box">
                <div
                  class="my-class d-flex justify-content-between"
                  @click="ratingClick(item.top_story_guid)"
                >
                  <star-rating mode="readonly" :rating="item.rating" />
                  <!--<button class="btn-wishlist btn-sm float-right" type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist"><i class="czi-heart"></i></button>!-->
                </div>
              </div>

              <div class="p-1 card-footer row no-gutters">
                <!-- Tags -->
                <div @click="viewContent(index)" class="col-12">
                  <div
                    class="float-start tag-list"
                    v-for="tag in item.tags"
                    :key="tag"
                  >
                    <span class="badge badge-pill badge-secondary">{{
                      tag
                    }}</span>
                  </div>
                </div>
              </div>

              <!-- <div class="card-footer">
                <div
                  class="float-start tag-list font-size-sm"
                  v-for="tag in item.tags"
                  v-bind:key="tag"
                >
                  <span class="badge-pill badge-secondary">{{ tag }}</span>
                </div>
              </div> -->
              <!-- <div class="card-body card-body-hidden">
              <div class="text-center pb-2">
                <div class="custom-control custom-option custom-control-inline mb-2">
                  <input class="custom-control-input" type="radio" name="size1" id="s-75">
                  <label class="custom-option-label" for="s-75">7.5</label>
                </div>
                <div class="custom-control custom-option custom-control-inline mb-2">
                  <input class="custom-control-input" type="radio" name="size1" id="s-80" checked>
                  <label class="custom-option-label" for="s-80">8</label>
                </div>
                <div class="custom-control custom-option custom-control-inline mb-2">
                  <input class="custom-control-input" type="radio" name="size1" id="s-85">
                  <label class="custom-option-label" for="s-85">8.5</label>
                </div>
                <div class="custom-control custom-option custom-control-inline mb-2">
                  <input class="custom-control-input" type="radio" name="size1" id="s-90">
                  <label class="custom-option-label" for="s-90">9</label>
                </div>
              </div>
              <button class="btn btn-primary btn-sm btn-block mb-2" type="button" data-toggle="toast" data-target="#cart-toast"><i class="czi-cart font-size-sm mr-1"></i>Add to Cart</button>
              <div class="text-center"><a class="nav-link-style font-size-ms" href="#quick-view" data-toggle="modal"><i class="czi-eye align-middle mr-1"></i>Quick view</a></div>
            </div> -->
            </div>
          </div>
        </template>
      </div>
      <div class="pt-3" v-if="loadingScrollItems">
        <spinner />
      </div>
      <!-- Pagination-->
      <!--
      <nav class="d-flex justify-content-between pt-2" aria-label="Page navigation">
        <ul class="pagination">
          <li class="page-item"><a class="page-link" href="#"><i class="czi-arrow-left mr-2"></i>Prev</a></li>
        </ul>
        <ul class="pagination">
          <li class="page-item d-sm-none"><span class="page-link page-link-static">1 / 5</span></li>
          <li class="page-item active d-none d-sm-block" aria-current="page"><span class="page-link">1<span class="sr-only">(current)</span></span></li>
          <li class="page-item d-none d-sm-block"><a class="page-link" href="#">2</a></li>
          <li class="page-item d-none d-sm-block"><a class="page-link" href="#">3</a></li>
          <li class="page-item d-none d-sm-block"><a class="page-link" href="#">4</a></li>
          <li class="page-item d-none d-sm-block"><a class="page-link" href="#">5</a></li>
        </ul>
        <ul class="pagination">
          <li class="page-item"><a class="page-link" href="#" aria-label="Next">Next<i class="czi-arrow-right ml-2"></i></a></li>
        </ul>
      </nav>
      -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Multiselect from "vue-multiselect";
import { GlobalMethods, GlobalStore } from "../global";
import { lazyLoad } from "../helpers/lazyLoad";
import { requests } from "../helpers/requests";
import StarRating from "../components/starRating.vue";
import modalPopup from "../components/modalPopup.vue";
import tcxImage from "../components/tcxImage";
import pageLoader from "../components/pageLoader";
import spinner from "../components/spinner";

// register globally
Vue.component("multiselect", Multiselect);

export default {
  name: "FYI",
  data() {
    return {
      selectedFilterItems: [],
      tagList: [],
      idx: 0,
      loading: true,
      imageSwipeOptions: {
        stopPropagation: true,
        continuous: false,
        callback: this.imageswipe,
      },
      itemList: [],
      mediaGroups: null,
      imageEndpoint: GlobalStore.imageEndpoint,
      media: null,
      products: null,
      modalVisible: false,
      selectedStoryGUID: null,
      selectedStoryTitle: "",
      newItemIcon: "czi-bell",
      itemsViewed: [],
      /** Used by lazyLoader for api calls. */
      nextPage: null,
      /** Flag used by bottom spinner. Will be set to true when scroll items are loading. */
      loadingScrollItems: false,
      showImages: true,
    };
  },
  components: {
    StarRating,
    modalPopup,
    tcxImage,
    Multiselect,
    pageLoader,
    spinner,
  },
  props: {
    msg: String,
  },
  computed: {
    filteredItemList() {
      return requests.filteredItemListHelper(this.itemList);
    },
  },
  methods: {
    /**
     * Get List of Product Ids to send to Product API call
     * @param {Array} data Array of items to be mutated.
     */
    getProductGuids(data) {
      let productGuids = "";
      data.forEach((i) => {
        var expand = true;
        i.products.forEach((p) => {
          // console.log("product_guid", p.product_guid);
          productGuids += p.product_guid + ",";
          p.expanded = expand;
          expand = false;
        });
      });
      // console.log('getProductGuids', productGuids);
      if (productGuids) {
        GlobalMethods.getProductData(productGuids);
      }
    },

    /**
     * Getting initial items. Populates this.itemList.
     */
    getItems() {
      // Resetting 'nextPage'
      this.nextPage = null;
      this.showImages = false;

      requests
        .getInitialItems(
          `${GlobalStore.APIEndpoint}/top_story`,
          [],
          this.selectedFilterItems
        )
        .then((res) => {
          console.log(res.data.data);
          this.itemList = res.data.data;
          this.getProductGuids(this.itemList);
          this.nextPage =
            res.data.total_count > GlobalStore.requests.LAZY_LOAD_PER_PAGE
              ? res.data.next_page
              : null;

          this.showImages = true;
        })
        .catch((err) => {
          console.error(err);
          GlobalMethods.handleAPIerror(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    tagSelected() {
      setTimeout(() => {
        //console.log(this.selectedFilterItems);
        this.getItems();
      }, 200);
    },
    imageswipe(evt) {
      console.log(evt);
      //this.idx = evt + 1;
    },
    goPrev(item_idx) {
      // console.log('item index:',  item_idx);
      this.$refs["imageSwipe_" + item_idx][0].prev();
      this.submitView(item_idx);
    },
    goNext(item_idx) {
      // console.log('item index:',  item_idx);
      this.$refs["imageSwipe_" + item_idx][0].next();
      this.submitView(item_idx);
    },
    getCustGuid() {
      return GlobalStore.custGuid;
    },
    submitRating(rating) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      var urlencoded = new URLSearchParams();
      urlencoded.append("stars", rating);

      axios
        .put(
          GlobalStore.APIEndpoint +
            "/top_story/" +
            this.selectedStoryGUID +
            "/rating",
          {
            stars: rating,
          },
          {
            headers: headers,
          }
        )
        .then((resp) => {
          if (resp.status == 200) {
            var storyItem = this.itemList.find(
              (f) => f.top_story_guid == this.selectedStoryGUID
            );
            storyItem.rating.total = resp.data.rating.total;
            storyItem.rating.stars = resp.data.rating.stars;
            storyItem.rating.count = resp.data.rating.count;

            GlobalStore.toastObj = this.$toasted.show(
              "Your rating has been submitted.",
              {
                theme: "outline",
                position: "top-center",
                duration: 3000,
                type: "success",
                fitToScreen: true,
                fullWidth: false,
              }
            );
          }
          this.$refs.modal.closeModal();
        })
        .catch(() => {
          GlobalStore.toastObj = this.$toasted.show(
            "Sorry, you have already rated this item.",
            {
              theme: "outline",
              position: "top-center",
              duration: 3000,
              type: "error",
              fitToScreen: true,
              fullWidth: false,
            }
          );
          this.$refs.modal.closeModal();
        });
    },
    ratingClick(guid) {
      this.selectedStoryGUID = guid;
      var storyItem = this.itemList.find((f) => f.top_story_guid == guid);
      this.$refs.modal.openModal("Please rate this item:");
      this.selectedStoryTitle = storyItem.title;
    },
    submitView(item_idx) {
      if (this.itemsViewed.includes(item_idx)) {
        // console.log('view already registered ', this.filteredItemList[item_idx]);
      } else {
        // console.log('registering view ', this.filteredItemList[item_idx]);
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + GlobalStore.access_token,
        };

        axios
          .put(
            `${GlobalStore.APIEndpoint}/top_story/${this.filteredItemList[item_idx].top_story_guid}/view`,
            {},
            { headers: headers }
          )
          .then(() => {
            if (this.itemsViewed.indexOf(item_idx) === -1)
              this.itemsViewed.push(item_idx); // cache processed views to minimise traffic
          })
          .catch(() => {
            GlobalStore.toastObj = this.$toasted.show(
              "Sorry, you have already liked this item!",
              {
                theme: "outline",
                position: "top-center",
                duration: 3000,
                type: "error",
                fitToScreen: true,
                fullWidth: false,
              }
            );
          });
      }
    },
    handleClick: function () {
      alert(this.appName);
    },
    getProductHeading(title, product_guid) {
      var ret_string = "";
      if (product_guid) {
        let product = GlobalStore.coreData.products.find(
          (p) => p.product_guid == product_guid
        );

        if (product) {
          var price =
            product.price && !isNaN(product.price)
              ? ", $" + product.price.toFixed(2)
              : "";
          ret_string = `<b>${title}</b>${price}`;
        }
      }
      return ret_string;
    },
    expandProduct(product) {
      product.expanded = !product.expanded;
      this.$forceUpdate();
    },
    getProductName(product_guid) {
      if (GlobalStore.coreData.products && product_guid) {
        let product = GlobalStore.coreData.products.find(
          (p) => p.product_guid == product_guid
        );
        if (product) {
          return product.name; //+ (product.price && product.price != '' ? " - $" + product.price : '');
        }
        return "";
      }
      return "";
    },
    getProductLine(product_guid) {
      var ret_string = "";

      if (GlobalStore.coreData.products && product_guid) {
        let product = GlobalStore.coreData.products.find(
          (p) => p.product_guid == product_guid
        );
        if (product) {
          //ret_string += `<div class="features-italics">`;
          if (product.brand) {
            ret_string += "<p><b>" + product.brand + "</b></p>";
          }

          if (product.features) {
            product.features.forEach((f) => {
              ret_string += f + "<br>";
            });
          }

          //ret_string += `</div>`;
        }

        return ret_string;
      }

      return "";
    },
    getMediaGroup(media_group_guid) {
      return GlobalStore.coreData.mediaGroups.find(
        (f) => f.media_group_guid == media_group_guid
      );
    },
    getImage(guid, type) {
      let ret_image = "";
      if (GlobalStore.coreData.mediaGroups) {
        const mediaGroup = this.getMediaGroup(guid);

        if (mediaGroup != null) {
          const featureImageGuid = mediaGroup[type + "_guid"];
          if (featureImageGuid && GlobalStore.coreData.media) {
            ret_image = GlobalStore.coreData.media.find(
              (m) => m.media_guid == featureImageGuid
            ).data;
          }
        }
      }
      return ret_image;
    },

    /**
     * Implements the onscroll event listener to call lazyLoad data. Must be
     * called in the execution file rather than helper file to use
     * eventListener and variables.
     */
    initializeLazyLoad() {
      // console.log('initializeLazyLoad');
      window.onscroll = () => {
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset >= document.body.offsetHeight;
        if (bottomOfWindow && this.nextPage && !this.loadingScrollItems) {
          this.loadingScrollItems = true;
          const url = `${GlobalStore.APIEndpoint}/top_story`;
          const params = new URLSearchParams({
            // Add additional request parameters here.
            ...(this.selectedFilterItems && {
              tags: encodeURIComponent(
                requests.getFilterString(this.selectedFilterItems)
              ),
            }),
          });

          this.showImages = false;

          // Getting data
          lazyLoad
            .getNextItems(
              url,
              params,
              this.nextPage,
              GlobalStore.requests.LAZY_LOAD_PER_PAGE
            )
            .then((res) => {
              this.itemList.push(...res.data.data);
              this.getProductGuids(this.itemList);
              this.nextPage = res.data.next_page;
              this.showImages = true;
            })
            .finally(() => {
              this.loadingScrollItems = false;
            });
        }
      };
    },
  },

  mounted() {
    GlobalMethods.getCustomerColors();
    GlobalStore.custObj.config.tags.top_story.sort().forEach((tag) => {
      this.tagList.push({ name: tag, code: tag });
    });
    this.newItemIcon = GlobalMethods.getNewItemIcon();

    this.initializeLazyLoad();
  },

  beforeMount() {
    // Getting initial items.
    this.getItems();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.trans-button {
  background-color: rgba(255, 255, 255, 0.6);
  border-style: none;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.trans-button i {
  transform: scale(1.5);
  margin: 5px 0px 0px 0px;
}

.strap-line {
  margin-top: 8px;
  font-size: 11pt !important;
  font-weight: bold;
}

.addon-prompt {
  font-size: 9pt !important;
  font-weight: bold;
}

.top-stories .py-2 {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.top-stories .mb-4 {
  margin-bottom: 0.8rem !important;
}

.product-heading {
  margin-bottom: 8px;
  font-weight: bold;
}

.star-box {
  display: contents;
}

.features-italics {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 8pt !important;
  font-style: italic;
}

img {
  width: 98%;
  margin: auto;
}

#slider-left-button {
  position: absolute;
  left: 10px;
  top: calc(50% - 40px);
  z-index: 1;
}

#slider-right-button {
  position: absolute;
  right: 10px;
  top: calc(50% - 40px);
  z-index: 1;
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.085);
}

.multiselect {
  margin: 0px 8px;
}
/* tag display classes */
.card-header {
  min-height: 75px;
}

.tag-list {
  display: inline-block !important;
}
.badge-pill {
  margin: 0px;
}
.product-card {
  padding-bottom: 0px;
}
.product-card .badge {
  position: unset;
}
.card-footer {
  text-align: left;
  min-height: 33px;
  padding: 5px;
}
.new-item-icon {
  float: right;
  color: green;
}
</style>
