<template>
	<div class="container">
		<div v-if="isLoading">
			<page-loader></page-loader>
		</div>

		<!-- Desktop size render -->
		<div class="pt-3 row mx-n2 no-gutter elements-as-card">
			<div v-if="actionRequests.length == 0">
				There are no items to display.
			</div>
			<div
				class="col-md-4 col-xxl-4 mb-3 px-md-2 cursor-pointer"
				v-for="item in actionRequests"
				:key="item.action_request_guid"
				@click="viewActionRequest(item.action_request_guid)"
			>
				<div class="card cursor-pointer">
					<div class="card-img-top">
						<tcx-image :imageSrc="item.thumbnail_url" :aspectBlur="true" />
					</div>
					<div class="card-body p-2">


						<h6 class="mb-1 text-700 card-title">
							{{ item.title }}
						</h6>
						<p
							class="mb-0 text-500 card-text elip-wrap"
							:class="item.past_due ? 'text-goldenrod' : 'text-green'"
						>
							Due: {{ item.due_by_string }}
						</p>
						{{ item.progress.completed_names.length }}/{{
							item.progress.potential_names.length ? item.progress.potential_names.length : 0
						}} completed
						<div class="progress-container">
							<div class="progress w-100 rounded-soft" style="height: 5px;">
								<div
									class="progress-bar rounded-capsule"
									role="progressbar"
									:style="item.widthPercentComplete"
									aria-valuenow="33"
									aria-valuemin="0"
									aria-valuemax="100"
								></div>
							</div>
						</div>
						<div>
							<div
								v-if="item.is_expired == true"
								class="mt-1 mb-0 font-weight-semi-bold text-500 text-firebrick card-text"
							>
								Expired
							</div>
							<span
							v-if="item.mode"
							class="badge action_request_mode pull-right hidden-xs"
							:class="item.mode"
							>{{ item.mode }} to respond</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Phone size render -->
		<div class="elements-as-list" v-if="!isLoading">
			<div class="col my-4">
				<div v-if="actionRequests.length == 0">
					There are no items to display.
				</div>
				<div
					class="card no-gutters mb-2"
					v-for="item in actionRequests"
					:key="item.action_request_guid"
					@click="viewActionRequest(item.action_request_guid)"
				>
					<div class="card-body row">
						<div class="col-4 p-0 backdrop">
							<tcx-image :imageSrc="item.thumbnail_url" :aspectBlur="true" />
						</div>

						<div class="col-8 row no-gutters py-1">
							<div class="col-xxl-12 col-sm-6 my-auto text-left">
								<h6 class="mb-1 text-700 card-title">
									{{ item.title }}
								</h6>
								<!-- <p class="font-weight-semi-bold mb-0 text-500 card-text elip-wrap">{{item.summary}}</p> -->
								<p
									class="mb-0 text-500 card-text elip-wrap"
									:class="item.past_due ? 'text-goldenrod' : 'text-green'"
								>
									Due: {{ item.due_by_string }}
								</p>
								<p v-if="item.mode" class="mb-0 text-500 card-text elip-wrap capitalize" 
									:class="item.mode == 'user' ? 'text-darkslategray' : 'text-darkcyan'">
									
									{{ item.mode }} to respond
								</p>
							</div>

							<div class="pt-1 col-xxl-12 col-sm-6 my-auto card-text">
								{{ item.progress.completed_names.length }}/{{
									(item.progress.potential_names.length ? item.progress.potential_names.length : 0)
								}} completed
								<div class="progress-container">
									<div class="progress w-100 rounded-soft" style="height: 5px;">
										<div
											class="progress-bar rounded-capsule"
											role="progressbar"
											:style="item.widthPercentComplete"
											aria-valuenow="33"
											aria-valuemin="0"
											aria-valuemax="100"
										></div>
									</div>
								</div>
								<div>
									<div
										v-if="item.is_expired == true"
										class="mt-1 mb-0 font-weight-semi-bold text-500 text-firebrick card-text"
									>
										Expired
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pageLoader from '../components/pageLoader';
import tcxImage from '../components/tcxImage.vue';
import { GlobalStore } from '../global';
import { AdminAPI } from '../adminApi';

export default {
	name: 'RegionalManager',
	data() {
		return {
			isLoading: true,
			actionReqName: '',
			actionRequests: []
		};
	},
	computed: {},
	methods: {
		viewActionRequest(guid) {
			this.$router.push(`/actionRequestDashboard/${guid}`);
		}
	},
	components: {
		pageLoader,
		tcxImage
	},
	async mounted() {
		var moduleTodo = GlobalStore.custObj.config.modules.filter(module=>module.content_item_type == "Todo");
//		console.log("moduleTodo", moduleTodo);
		this.actionReqName = moduleTodo[0].title;
//		console.log("actionReqName - updated", this.actionReqName);

		// Getting data
		console.log('POSTing to get DashboardData');
		const pipeline =[
			{
				"$match": {
				"item_type": {
					"$nin": [
					"template",
					"_system"
					]
				},
				"display.publish_status" : "published",
				"$expr": {
					"$and": [
					{
						"$lte": [
						"$display.display_after",
						"$$NOW"
						]
					},
					{
						"$gte": [
						"$display.expires",
						"$$NOW"
						]
					}
					]
				},
				"is_archived": false
				}
			},
			{
				"$sort": {
				"due_by": -1
				}
			}
		];
		await AdminAPI.getDashboardData('audience_action_request', 100, pipeline)
			.then((responses) => {
				let nowTime = new Date().toISOString();
				console.log('DashboardData responses', responses);
				responses.data.filter(item => item.display.expires > nowTime).forEach(async (res) => {
					try {
						await AdminAPI.getActionRequestItem(res.action_request_guid).then(
							(arItem) => {
								res.thumbnail = arItem.data.images.thumbnail_image;
								res.thumbnail_url = arItem.data.images.thumbnail_image_url;
								res.due_by_string = AdminAPI.fuzzyTime(arItem.data.due_by);
								res.past_due = arItem.data.due_by < nowTime;
								res.is_expired = arItem.data.display.expires < nowTime;

								console.log('arItem', arItem);
							}
						);
						console.log("item", res);
						res.widthPercentComplete = 'width: ' + res.progress.completed_percent.toFixed(1) + '%';
						// console.log("item updated", res);
						this.actionRequests.push(res);
					} catch (err) {
						console.log(
							'Error getting action request:' + res.action_request_guid,
							err
						);
					}
				});
			})
			.catch((err) => {
				console.error('Failed to get dashboard data: ' + err);
			})
			.finally(() => {
				console.log('actionRequestItems', this.actionRequests);
				this.isLoading = false;
			});		
	}
};
</script>

<style scoped>
.card {
	cursor: pointer;
	overflow: hidden;
}

.card-body {
	padding: 0 0 0 0;
	min-height: 115px;
}

.col-5 {
	padding-top: 15px;
	padding-bottom: 15px;
}

.row {
	margin: 0;
}

.text-green {
	color: #42b983 !important;
}
.text-goldenrod {
	color: goldenrod !important;
}
.text-firebrick {
	color: firebrick !important;
}
.text-darkslategray {
	color: darkslategray !important;
}
.text-darkcyan {
	color: darkcyan !important;
}

.card-content {
	height: fit-content !important;
}

.badge.action_request_mode {
  background-color: gray;
  opacity: 0.9;
  color: #ffffff;
  padding: 2px 10px 2px 10px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}
.badge.action_request_mode.store {
  background-color: darkslategray;
}
.badge.action_request_mode.user {
  background-color: darkcyan;
}
p.capitalize {
  text-transform: capitalize;
}
.progress-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.progress-bar {
	background-color: #3fa535 !important;
}

.backdrop {
	background-color: whitesmoke;
}

img {
	border-top-left-radius: 0.4327rem;
	border-bottom-left-radius: 0.4327rem;
}

@media (max-width: 576px) {
	.card-text {
		width: 100%;
		font-size: 8pt;
	}

	.elip-wrap {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.card-title {
		font-size: 10pt;
	}
}

@media screen and (max-width: 480px) {
	.elements-as-card {
		display: none;
	}
}
@media screen and (min-width: 481px) {
	.elements-as-list {
		display: none;
	}
}
</style>
