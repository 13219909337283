<template>
  <div
    style="
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      position: fixed;
      margin-left: 0;
      left: 0px;
      top: 0px;
      z-index: 2000;
    "
  >
    <svg class="spinner" viewBox="0 0 50 50">
      <circle
        class="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="5"
      ></circle>
    </svg>
    <div class="spinner-label">Submitting response, please wait..</div>
  </div>
</template>

<script>
export default {
  name: "page-loader",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.spinner-label {
  top: 55%;
  background-color: white;
  margin: 0px 20px 0px 0px;
  border-radius: 20px;
  position: fixed;
  padding: 20px;
  left: calc(50% - 100px);
  width: 200px;
}
.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}
.spinner .path {
  stroke: rgb(63, 165, 53);
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
