import Vue from "vue";
import axios from "axios";

export const GlobalStore = new Vue({
  data: {
        version: "1.30.3",
    APIEndpoint: process.env.VUE_APP_APIENDPOINT,
    imageEndpoint: process.env.VUE_APP_IMAGEENDPOINT,
    isAuthenticated: false,
    userObject: null,
    custObj: "",
    userMeObj: {},
    custGuid: "20d61b61-da34-4599-8ccd-e053ec6fc6a5",
    passkey: "709e9ead-c13d-44d0-8203-c1dcf1c0bbac",
    refresh_token: "",
    expires_in: 0,
    expires_at: 0,
    expireTimer: null,
    access_token: "",
    toastObj: null,
    coreData: {
      media: [],
      products: [],
      mediaGroups: [],
    },
    appTitle: "EveryCX",
    placeholderImage: {
      alt_text: "Placeholder image",
      content_type: "image/png",
      created_by: "bc39bd4d-29ec-4d90-a3f6-65e7d82fc8fd",
      created_by_name: "System",
      customer_guid: "",
      date_created: "2021-07-26T21:56:54.459000+00:00",
      description: "Placeholder image",
      height: null,
      image_guid: null,
      is_archived: false,
      is_thumbnail: false,
      item_type: "_system",
      thumbnail_image_guid: null,
      thumbnail_url: "/everycx/placeholder-image.png",
      updated_by_name: "Unknown",
      updated_timestamp: "2021-07-23T10:54:19.127000+00:00",
      url: "/everycx/placeholder-image.png",
      width: null,
    },
    /** Config object for API requests */
    requests: {
      /** Number of items per request. Used for initial page load and lazyLoad requests */
      LAZY_LOAD_PER_PAGE: 60,
    },
  },
});

export const GlobalMethods = new Vue({
  methods: {
    getCoreData: async function () {
      const [_media, _mediaGroup] = await Promise.all([
        axios.get("/mockdata/media.json"),
        axios.get("/mockdata/media_groups.json"),
      ]);

      GlobalStore.coreData.media = _media.data;
      //GlobalStore.coreData.products = _product.data;
      GlobalStore.coreData.mediaGroups = _mediaGroup.data;
    },
    getProductData: async function (productGuids) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      //var urlencoded = new URLSearchParams();
      //urlencoded.append("product_guid", productGuids);
      axios
        .get(GlobalStore.APIEndpoint + "/product", {
          headers: headers,
          params: {
            product_guid: productGuids,
          },
        })
        .then((resp) => {
          GlobalStore.coreData.products = resp.data.data;
        });
    },

    /**
     * Handle events when user likes a content item. Requires 'item' to have 'content_guid' property.
     * @param {Object} item Object containing the guid of the item to be liked.
     */
    async likeItem(item) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      try {
        const res = await axios.put(
          `${GlobalStore.APIEndpoint}/content/${item.content_guid}/like`,
          {},
          { headers: headers }
        );
        return res;
      } catch (err) {
        this.displayErrorToast("Sorry, you have already liked this item!");
      }
    },

    /**
     * Displays error toast with a given message
     * @param {String} message message to be displayed
     */
    displayErrorToast(message) {
      GlobalStore.toastObj = this.$toasted.show(message, {
        theme: "outline",
        position: "top-center",
        duration: 3000,
        type: "error",
        fitToScreen: true,
        fullWidth: false,
      });
    },

    /**
     * Displays success toast with a given message.
     * @param {String} message message to be displayed
     */
    displaySuccessToast(message) {
      GlobalStore.toastObj = this.$toasted.show(message, {
        theme: "outline",
        position: "top-center",
        duration: 3000,
        type: "success",
        fitToScreen: true,
        fullWidth: false,
      });
    },

    /****************************************/
    /*      JWT Session Token Methods       */
    /****************************************/
    checkIfAuthenticated: async function () {
      let isAuth = await this.checkSessionTokens();

      return isAuth;
    },
    checkRefreshToken: async function () {
      var secondsBeforeExpiry = GlobalStore.expires_at - this.getEpochSeconds();

      if (secondsBeforeExpiry <= 0) {
        await this.getRefreshToken();
      }
    },
    updateSessionTokens: function () {
      this.$session.set("r", GlobalStore.refresh_token);
      this.$session.set("a", GlobalStore.access_token);
      this.$session.set("e", GlobalStore.expires_in);
      this.$session.set("e_a", GlobalStore.expires_at);
      this.$session.set("c", GlobalStore.custObj.name);
      if (GlobalStore.userObject) {
        this.$session.set("u", GlobalStore.userObject.login);

        this.$ga.set("userId", GlobalStore.userObject.login);
      }
    },
    checkSessionTokens: async function () {
      var secondsBeforeExpiry = GlobalStore.expires_at - this.getEpochSeconds();
      if (secondsBeforeExpiry <= 0) {
        await this.getRefreshToken();
      }
      if (
        this.$session.get("r") &&
        this.$session.get("a") &&
        this.$session.get("e")
      ) {
        GlobalStore.refresh_token = this.$session.get("r");
        GlobalStore.access_token = this.$session.get("a");
        GlobalStore.userObject = GlobalMethods.parseJwt(
          GlobalStore.access_token
        );
        GlobalStore.custGuid = GlobalStore.userObject.customer_guid;
        GlobalStore.expires_in = this.$session.get("e");
        GlobalStore.expires_at = this.$session.get("e_a");
        GlobalStore.isAuthenticated = true;

        const authheaders = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + GlobalStore.access_token,
        };
        try {
          var custResp = await axios.get(
            GlobalStore.APIEndpoint +
              "/customer/" +
              GlobalStore.userObject.customer_guid,
            {
              headers: authheaders,
            }
          );
        } catch (err) {
          //if problem validating session tokens, return false to this function, which will boot user to login page
          this.userMenuVisible = false;
          GlobalMethods.clearSession();
          GlobalStore.isAuthenticated = false;
          return false;
        }

        GlobalStore.custObj = custResp.data;

        const meHeaders = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + GlobalStore.access_token,
        };

        var meResp = await axios.get(GlobalStore.APIEndpoint + "/user/me", {
          headers: meHeaders,
        });
        GlobalStore.userMeObj = meResp.data;

        return true;
      }
      return false;
    },
    clearSession: function () {
      GlobalStore.isAuthenticated == false;
      localStorage.removeItem("cxa");
      this.$session.destroy();
    },
    getRefreshToken: async function () {
      //Don't try to get new refresh token if user is logged out and we don't have bearer token...
      if (this.$session.get("r")) {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + this.$session.get("r"),
          //new Buffer(this.username + ":" + this.password).toString("base64")
        };

        var urlencoded = new URLSearchParams();
        urlencoded.append("grant_type", "refresh_token");
        urlencoded.append("expires_in", "7200");

        try {
          var tokenResp = await axios.post(
            GlobalStore.APIEndpoint + "/authenticate",
            urlencoded,
            {
              headers: headers,
            }
          );
        } catch {
          this.userMenuVisible = false;
          GlobalMethods.clearSession();
          GlobalStore.isAuthenticated = false;
          document.location.href = "/login";
        }

        if (tokenResp.status === 200) {
          GlobalStore.isAuthenticated = true;
          GlobalStore.refresh_token = tokenResp.data.refresh_token;
          GlobalStore.access_token = tokenResp.data.access_token;
          GlobalStore.expires_in = tokenResp.data.expires_in;
          GlobalStore.expires_at =
            GlobalMethods.getEpochSeconds() +
            parseInt(tokenResp.data.expires_in);
        } else {
          //This will force back to login
          this.clearSession();
          this.userMenuVisible = false;
          GlobalStore.isAuthenticated = false;
        }

        GlobalMethods.updateSessionTokens();
      }

      //});
    },
    /****************************************/
    /*         Helpers and Utilites         */
    /****************************************/
    getEpochSeconds: function () {
      const now = new Date();
      const secondsSinceEpoch = Math.round(now.getTime() / 1000);
      return secondsSinceEpoch;
    },
    handleAPIerror: function (err) {
      if (err.response.status == 401) {
        //GlobalMethods.clearSession();
        //this.GlobalStore.isAuthenticated = false;
        //this.$router.push("login");
      } else {
        console.log(err);
      }
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },

    /**
     * @desc Gets css_overrides oject from GlobalStore and applies it to a new
     * stylesheet attached to head.
     */
    getCustomerColors() {
      var css = `${GlobalStore.custObj.config.branding.css_overrides}`,
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");
      head.appendChild(style);
      style.type = "text/css";
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    },
    getNewItemIcon: function () {
      try {
        return GlobalStore.custObj.config.branding.new_item_icon;
      } catch {
        return "czi-bell";
      }
    },

    /**
     * Finds the a page title based on the customer obj modules.
     * @param {String} endpoint the url endpoint of the page. e.g. 'fyi'
     * @returns {String} title of the page.
     */
    getModuleTitle(endpoint) {
      return GlobalStore.custObj.config.modules.find((obj) => {
        return obj.admin_url === endpoint;
      }).title;
    },

    /**
     * Removes 'modules' from 'custObj' that current user does not have access
     * to. Function moved from 'mobileFooter.vue.'
     * @returns 'custObj' copy with unauthorized modules removed.
     */
    getParsedCustObj() {
      var custObjCopy = JSON.parse(JSON.stringify(GlobalStore.custObj));

      if (custObjCopy.config) {
        var licencedModules = GlobalStore.custObj.licence.optional_modules;

        custObjCopy.config.modules = [];
        GlobalStore.custObj.config.modules.forEach((module) => {
          var moduleRoleOverlaps = false;

          GlobalStore.userObject.roles.forEach((userRole) => {
            module.roles.forEach((moduleRole) => {
              if (moduleRole == userRole) {
                moduleRoleOverlaps = true;
              }
            });
          });

          if (
            module.optional == false ||
            (module.optional == true &&
              licencedModules.includes(module.title) &&
              (module.roles.length == 0 || moduleRoleOverlaps))
          )
            custObjCopy.config.modules.push(module);
        });
      }else{
        console.log('globals.js:getParsedCustObj: No custObjCopy.config');
      }

      return custObjCopy;
    },
  },
});
