var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.checkAuthenticated())?_c('div',{staticClass:"cz-handheld-toolbar",style:({ overflow: 'scroll' })},[(typeof _vm.custObj.config !== 'undefined')?_c('div',{staticClass:"d-table table-fixed",style:({
			width:
				this.windowWidth < _vm.custObj.config.modules.length * _vm.tabButtonWidth
					? _vm.custObj.config.modules.length * _vm.tabButtonWidth + 'px'
					: '100%',
			paddingRight: '10px'
		})},_vm._l((_vm.custObj.config.modules),function(menuItem){return _c('router-link',{key:menuItem.title,staticClass:"d-table-cell cz-handheld-toolbar-item",style:({ width: _vm.tabButtonWidth }),attrs:{"to":'/' + menuItem.app_url}},[_c('span',{staticClass:"cz-handheld-toolbar-icon"},[_c('i',{class:[
						_vm.currentTab == menuItem.app_url ? 'active' : '',
						menuItem.icon
					]})]),_c('span',{staticClass:"cz-handheld-toolbar-label",class:{ active: _vm.currentTab == menuItem.app_url }},[_vm._v(_vm._s(menuItem.title))])])}),1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }