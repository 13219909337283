<template>
	<div v-if="tags.length" @click="showDropdown = !showDropdown">
		<div class="tag-icon customer-color-primary font-size-sm text-right">
			<font-awesome-icon :icon="['fas', 'tags']" size="1x" />
		</div>

		<div v-if="showDropdown" class="tag-content p-1">
			<div class="float-start tag-list" v-for="tag in tags" v-bind:key="tag">
				<span class="badge badge-pill badge-secondary">{{ tag }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'tag-icon',
	data() {
		return {
			showDropdown: false
		};
	},
	props: {
		/**
		 * Array of tag name strings
		 */
		tags: {
			type: Array,
			required: true
		}
	}
};
</script>

<style scoped>
.tag-icon {
	position: relative;
}

.tag-content {
	display: block;
	position: absolute;
	max-width: 160px;
	right: 10px;
	box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.2);
	border-radius: 0.3125rem;
	transition: 0.5s;
	background-color: white;
}

.font-size-sm {
	font-size: 8pt !important;
}
</style>
