<template>
  <div class="container product-list">
    <div v-if="loading">
      <page-loader></page-loader>
    </div>

    <div class="filter-row">
      <multiselect
        v-model="selectedFilterItems"
        tag-placeholder="Add this as new tag"
        placeholder="Select a tag"
        label="name"
        track-by="code"
        :options="tagList"
        :multiple="true"
        class="filter pb-3"
        @select="tagSelected"
        @remove="tagSelected"
      ></multiselect>

      <select
        v-model="selectedSortFilter"
        v-if="showSortFilter"
        class="sortbyFilter"
        @change="onSortByChange($event)"
      >
        <option value="-1">-- Sort by --</option>
        <option
          v-for="(option, index) in sortFilterList"
          v-bind:value="index"
          v-bind:key="option"
        >
          {{ option }}
        </option>
      </select>
    </div>

    <!-- Tablet and larger rendering -->
    <div class="row pt-3 mx-n2 no-gutter elements-as-card">
      <div
        class="col-md-4 col-xxl-4 mb-3 px-md-2"
        v-for="(item, index) in filteredItemList"
        :key="index"
      >
        <div class="card">
          <div class="card-img-top">
            <tcx-image
              className="mr-3 border-rounded"
              :imageSrc="item.images.hero_image_url"
              :aspectBlur="true"
              v-if="showImages"
            />
          </div>
          <div class="card-body">
            <h5 class="card-title">{{ item.title }}</h5>
            <p
              v-if="item.summary.length > 0"
              class="p-1 card-text summary customer-color-primary"
            >
              {{ item.summary }}
            </p>
            <hr class="separator-med" />
            <div v-if="item.item_type == '@headline_ranked'">
              <div
                v-for="(headline_ranked, idx) in item.headlines_ranked"
                v-bind:key="idx"
              >
                <p
                  class="card-text font-size-med"
                  v-html="headline_ranked.headline"
                ></p>
              </div>
            </div>
            <div v-else>
              <div v-for="(headline, idx) in item.headlines" v-bind:key="idx">
                <p class="card-text font-size-med" v-html="headline"></p>
              </div>
            </div>
          </div>

          <!-- Footer -->
          <div class="p-1 card-footer row no-gutters">
            <!-- Tags -->
            <div class="col-8">
              <div
                class="float-start tag-list"
                v-for="tag in item.tags"
                v-bind:key="tag"
              >
                <span class="badge badge-pill badge-secondary">{{ tag }}</span>
              </div>
            </div>

            <!-- Like button -->
            <div
              class="col-4 customer-color-primary font-size-sm like-icon nobr"
              @click="handleLike(item)"
            >
              <span class="pr-1">{{ item.rating.likes }}</span>
              <font-awesome-icon
                v-if="!item.rating.liked_by_me"
                :icon="['far', 'thumbs-up']"
                size="2x"
                class="pt-1"
              />
              <font-awesome-icon
                v-if="item.rating.liked_by_me"
                :icon="['fas', 'thumbs-up']"
                size="2x"
                class="pt-1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-3" v-if="loadingScrollItems">
      <spinner />
    </div>

    <!-- Mobile rendering -->
    <div class="elements-as-list mt-4 pb-5">
      <div v-if="filteredItemList.length == 0">
        There are no items to display.
      </div>
      <div
        :key="index"
        v-for="(item, index) in filteredItemList"
        class="mt-3 row no-gutters list-unstyled"
      >
        <div class="col-4">
          <tcx-image
            :imageSrc="item.images.thumbnail_image_url"
            :aspectBlur="true"
            v-if="showImages"
          />
        </div>

        <div class="col-7 content-container">
          <div class="card-text card-best-store-title">{{ item.title }}</div>
          <div
            v-if="item.item_type == '@headline_ranked'"
            class="card-best-store-headlines"
          >
            <div
              v-for="(headline_ranked, idx) in item.headlines_ranked"
              v-bind:key="idx"
            >
              <div
                class="card-text font-size-sm"
                v-html="headline_ranked.headline"
              ></div>
            </div>
          </div>
          <div
            v-if="item.item_type != '@headline_ranked'"
            class="card-best-store-headlines"
          >
            <div v-for="(headline, idx) in item.headlines" v-bind:key="idx">
              <div class="card-text font-size-sm" v-html="headline"></div>
            </div>
          </div>
          <div class="row no-gutters footer-container">
            <div
              v-if="item.summary.length > 0"
              class="col summary font-size-sm customer-color-primary text-left"
            >
              {{ item.summary }}
            </div>
          </div>
        </div>

        <!-- Side notification bar -->
        <div class="col pr-1 py-1 notifications align-items-end">
          <!-- New Item Icon. Uncomment if needed. -->
          <!-- <span
            v-if="item.display.viewed_by_me == false"
            :class="newItemIcon"
            class="new-item-icon mb-2"
          ></span> -->

          <!-- Tags Icon -->
          <tag-icon :tags="item.tags" />

          <!-- Like button -->
          <div
            class="mt-auto customer-color-primary like-icon text-right font-size-sm nobr"
            @click="handleLike(item)"
          >
            <span class="font-size-sm"
              >{{ item.rating.likes }}
              <font-awesome-icon
                v-if="!item.rating.liked_by_me"
                :icon="['far', 'thumbs-up']"
                size="1x" />
              <font-awesome-icon
                v-if="item.rating.liked_by_me"
                :icon="['fas', 'thumbs-up']"
                size="1x"
            /></span>
          </div>
        </div>
      </div>
      <div class="pt-3" v-if="loadingScrollItems">
        <spinner />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import { GlobalMethods, GlobalStore } from "../global";
import { viewMethods } from "../helpers/view";
import { requests } from "../helpers/requests";
import { lazyLoad } from "../helpers/lazyLoad";
import pageLoader from "../components/pageLoader";
import tcxImage from "../components/tcxImage";
import tagIcon from "../components/tagIcon.vue";
import spinner from "../components/spinner";

Vue.component("multiselect", Multiselect);

export default {
  name: "Second",
  data() {
    return {
      itemList: [],
      imageEndpoint: GlobalStore.imageEndpoint,
      loading: true,
      selectedFilterItems: [],
      tagList: [],
      newItemIcon: "czi-bell",
      selectedSortFilter: 0,
      sortFilterList: [],
      showSortFilter: false,
      /** Used by lazyLoader for api calls. */
      nextPage: null,
      /** Flag used by bottom spinner. Will be set to true when scroll items are loading. */
      loadingScrollItems: false,
      showImages: true,
    };
  },
  computed: {
    filteredItemList() {
      return requests.filteredItemListHelper(this.itemList);
    },
  },
  props: {
    msg: String,
  },
  methods: {
    onSortByChange() {
      console.log(this.selectedSortFilter);
      this.getItems();
    },

    /**
     * Helper method to get view data. Populates 'itemList' with returned
     * values.
     */
    async getRankedItems(per_page, page) {
      await GlobalMethods.checkRefreshToken();
      let filterString = requests.getFilterString(this.selectedFilterItems);

      var rankItem = "rank_value_" + this.selectedSortFilter;
      //  console.log('rankItem', rankItem);

      var pipeline = [{ $sort: { [rankItem]: -1 } }];
      if (filterString) {
        var tags = filterString.split(",");
        // console.log('tags:', tags);
        var tag_pipeline = [
          {
            $match: {
              $expr: {
                $gt: [{ $size: { $setIntersection: [tags, "$tags"] } }, 0],
              },
            },
          },
        ];
        // console.log('tag_pipeline', tag_pipeline)
        pipeline = tag_pipeline.concat(pipeline);
      }

      // console.log('pipeline', pipeline);
      var startTime = performance.now();
      await viewMethods
        .getViewData("headlines_ranked", per_page, page, pipeline)
        .then((resp) => {
          console.log("resp:", resp);
          this.itemList.push(...resp?.data.data);
          this.nextPage = resp?.data.next_page;
        })
        .finally(() => {
          this.loading = false;
          this.loadingScrollItems = false;
        });
      var endTime = performance.now();
      console.log(
        `Call to getViewData took ${endTime - startTime} milliseconds`
      );
    },

    /**
     * Retrieves initial items for main page data. Populates 'itemList'.
     */
    getItems() {
      // Resetting 'nextPage'
      this.nextPage = null;

      if (this.$route.query["item_types"] == "@headline_ranked") {
        this.itemList = [];
        this.getRankedItems(GlobalStore.requests.LAZY_LOAD_PER_PAGE, 1);
      } else {
        const params = new URLSearchParams({
          content_item_type: "best_store",
        });

        this.showImages = false;

        requests
          .getInitialItems(
            `${GlobalStore.APIEndpoint}/content`,
            params,
            this.selectedFilterItems
          )
          .then((res) => {
            this.itemList = res.data.data;
            this.nextPage =
              res.data.total_count > GlobalStore.requests.LAZY_LOAD_PER_PAGE
                ? res.data.next_page
                : null;

            this.showImages = true;
          })
          .catch((err) => {
            GlobalMethods.handleAPIerror(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    tagSelected: function () {
      setTimeout(() => {
        //console.log(this.selectedFilterItems);
        this.getItems();
      }, 200);
    },
    handleClick: function () {
      alert(this.appName);
    },
    getCustGuid: function () {
      return GlobalStore.custGuid;
    },
    stripHtml(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },

    /**
     * Handle events when user likes a content item.
     * @param item Object containing the guid of the item to be liked.
     */
    handleLike(item) {
      GlobalMethods.likeItem(item).then((result) => {
        if (result && result.status === 200) {
          // Finding element that was returned and udating it.
          var index = this.itemList.findIndex(
            (item) => item.content_guid == result.data.content_guid
          );
          // Triggering data rerender.
          Vue.set(this.itemList, index, result.data);
        }
      });
    },

    /**
     * Implements the onscroll event listener to call lazyLoad data. Must be
     * called in the execution file rather than helper file to use
     * eventListener and variables.
     */
    initializeLazyLoad() {
      window.onscroll = () => {
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset >= document.body.offsetHeight;
        if (bottomOfWindow && this.nextPage && !this.loadingScrollItems) {
          this.loadingScrollItems = true;
          if (this.$route.query["item_types"] == "@headline_ranked") {
            this.getRankedItems(
              GlobalStore.requests.LAZY_LOAD_PER_PAGE,
              this.nextPage
            );
          } else {
            const url = `${GlobalStore.APIEndpoint}/content`;
            const params = new URLSearchParams({
              content_item_type: "best_store",
              ...(this.selectedFilterItems && {
                tags: encodeURIComponent(
                  requests.getFilterString(this.selectedFilterItems)
                ),
              }),
            });

            this.showImages = false;

            // Getting data
            lazyLoad
              .getNextItems(
                url,
                params,
                this.nextPage,
                GlobalStore.requests.LAZY_LOAD_PER_PAGE
              )
              .then((res) => {
                this.itemList.push(...res.data.data);
                this.nextPage = res.data.next_page;
                this.showImages = true;
              })
              .finally(() => {
                this.loadingScrollItems = false;
              });
          }
        }
      };
    },
  },
  components: {
    pageLoader,
    tcxImage,
    Multiselect,
    tagIcon,
    spinner,
  },
  mounted() {
    GlobalMethods.getCustomerColors();
    this.newItemIcon = GlobalMethods.getNewItemIcon();

    if (this.$route.query.rank_sort) {
      this.showSortFilter = true;
    }

    if (GlobalStore.custObj.config.text_formats.best_store) {
      const kpi_ranks =
        GlobalStore.custObj.config.text_formats.best_store[0][
          "@headline_ranked"
        ].headlines;

      kpi_ranks.forEach((k) => {
        var rank = k.split(":");
        //console.log(this.stripHtml(rank[0]));
        this.sortFilterList.push(this.stripHtml(rank[0]));
      });
    }

    GlobalStore.custObj.config.tags.best_store.sort().forEach((tag) => {
      this.tagList.push({ name: tag, code: tag });
    });

    this.initializeLazyLoad();
  },

  beforeMount() {
    // Getting initial items.
    this.getItems();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  --border-radius: 0.4375rem;
}

.container {
  margin-bottom: 30px;
}

.filter-row {
  display: flex;
}
.sortbyFilter {
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  color: #adadad;
  font-size: 14px;
  border-color: #e8e8e8;
  background-color: white;
  height: 43px;
  margin-top: 15px;
  flex-basis: 200px;
  margin-left: 7px;
}

.bs-container {
  margin: 16px 15px 10px 15px;
}

.notifications {
  display: flex;
  flex-direction: column;
}

h6 {
  text-align: center;
}

.font-size-sm {
  font-size: 8pt !important;
  text-align: left;
  line-height: 1.3em;
  padding-bottom: 2px;
}

.font-size-med {
  font-size: 11pt !important;
  text-align: left;
  line-height: 1em;
}
.card {
  min-height: 255px;
  overflow: hidden;
}
.best-store-thumbnail {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 38%;
}
div.best-store-thumbnail {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 38%;
  background-color: whitesmoke;
  border-bottom-left-radius: var(--border-radius);
}
img {
  width: 100%;
  max-height: 220px;
  object-fit: scale-down;
}
.caption {
  display: block;
  font-size: 10pt !important;
  line-height: 1.2em;
}

.summary {
  font-style: italic;
  text-align: center;
}

.border-rounded {
  border-radius: 5px !important;
}

.mb-4 {
  margin-bottom: 0.75em !important;
}

.list-unstyled {
  border: 1px solid rgba(0, 0, 0, 0.085);
  border-radius: var(--border-radius);
}
.list-unstyled img {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

hr.separator-med {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
hr.separator-sm {
  padding-top: 0.5em;
  width: 90%;
  margin: 0 auto;
}

.multiselect {
  margin: 15px 0px 0px 0px;
  padding-bottom: 0px !important;
}
/*  Phone-size render classes */
.card-best-store-title {
  padding-top: 5px;
  font-size: 10pt !important;
  text-align: left;
  line-height: 0.9em;
  font-weight: bold;
}
.card-best-store-headlines {
  padding: 0.25rem 0.1rem 0.1rem;
}
.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.content-container > div {
  padding-left: 5px;
}

.footer-container {
  align-content: space-between;
}

.w-40 {
  width: 40%;
}
/* tag display classes */
.card-header {
  padding: 0rem 0rem;
}
.card-footer {
  text-align: left;
  min-height: 33px;
  margin: 0;
}
.tag-list {
  display: inline-block !important;
}

.elements-as-list {
  margin-top: 0px !important;
}

.elements-as-list .card-body {
  position: relative;
}

.card-body .like-icon {
  position: absolute;
  bottom: 6px;
  right: 6px;
}
.card-footer .like-icon {
  text-align: right;
}

.like-icon > * {
  cursor: pointer;
}
.nobr {
  white-space: nowrap;
}

.new-item-icon {
  float: right;
  color: green;
}
/*
Displaying items as a list for screens below 480px width and as cards for
screens above 480px
*/
@media screen and (max-width: 480px) {
  .elements-as-card {
    display: none;
  }

  .card {
    min-height: 200px !important;
  }

  .card-title {
    margin-top: 0px;
  }

  .card-body {
    padding: 0.25rem 0.25rem 0 0.25rem;
  }
}
@media screen and (min-width: 481px) {
  .elements-as-list {
    display: none;
  }

  .card-body {
    padding: 1.25rem;
  }

  .card img {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: whitesmoke;
  }
}
</style>
