<template>
  <div>
    <div class="container">
      <div v-if="loading">
        <page-loader></page-loader>
      </div>

      <!-- <button @click="checkRefreshToken()">Refresh Token</button> -->

      <multiselect
        v-model="selectedFilterItems"
        tag-placeholder="Add this as new tag"
        placeholder="Select a tag"
        label="name"
        track-by="code"
        :options="tagList"
        :multiple="true"
        class="filter pb-3"
        @select="tagSelected"
        @remove="tagSelected"
      ></multiselect>

      <!-- larger screen render -->
      <div
        v-if="viewMode == 'list'"
        class="row mx-n2 pt-3 no-gutters elements-as-card"
      >
        <div
          class="col-md-4 col-xxl-4 mb-3 px-md-2"
          v-for="(item, index) in filteredItemList"
          :key="index"
        >
          <div style="height: 100%" class="card">
            <div
              class="card-img-top cursor-pointer"
              @click="viewContent(index)"
            >
              <tcx-image
                className="mr-3 border-rounded"
                :imageSrc="item.images.hero_image_url"
                :aspectBlur="true"
                v-if="showImages"
              />
            </div>
            <div class="card-body cursor-pointer" @click="viewContent(index)">
              <span
                v-if="item.display.viewed_by_me == false"
                :class="newItemIcon"
                class="new-item-icon p-1"
              ></span>
              <h5 class="card-title">{{ item.title }}</h5>
              <p class="font-size-sm card-text">{{ item.summary }}</p>
              <p
                v-if="item.category.length > 0"
                class="card-text customer-color-primary"
              >
                {{ item.category }}
              </p>
            </div>

            <div class="p-1 card-footer row no-gutters">
              <!-- Tags -->
              <div @click="viewContent(index)" class="col-8">
                <div
                  class="float-start tag-list"
                  v-for="tag in item.tags"
                  v-bind:key="tag"
                >
                  <span class="badge badge-pill badge-secondary">{{
                    tag
                  }}</span>
                </div>
              </div>
              <!-- Like Button / Indicator -->
              <div
                class="col-4 text-right customer-color-primary like-icon nobr"
                @click="handleLike(item)"
              >
                <span class="font-size-sm"
                  >{{ item.rating.likes }}
                  <font-awesome-icon
                    v-if="!item.rating.liked_by_me"
                    :icon="['far', 'thumbs-up']"
                    size="2x"
                    class="pt-1" />
                  <font-awesome-icon
                    v-if="item.rating.liked_by_me"
                    :icon="['fas', 'thumbs-up']"
                    size="2x"
                    class="pt-1"
                /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-3" v-if="loadingScrollItems">
        <spinner />
      </div>

      <!-- Mobile rendering -->
      <div v-if="viewMode == 'list'" class="elements-as-list mt-4 pb-5">
        <div v-if="filteredItemList.length == 0">
          There are no items to display.
        </div>
        <div
          :key="index"
          v-for="(item, index) in filteredItemList"
          class="mt-3 row no-gutters list-unstyled"
        >
          <div class="col-4" @click="viewContent(index)">
            <tcx-image
              :imageSrc="item.images.thumbnail_image_url"
              :aspectBlur="true"
              v-if="showImages"
            />
          </div>

          <div class="col-7 content-container">
            <div @click="viewContent(index)">
              <h6 class="mb-1">{{ item.title }}</h6>
              <div class="card-text font-size-sm">
                {{ item.summary }}
              </div>
              <div class="card-text font-size-sm customer-color-primary">
                {{ item.category }}
              </div>
            </div>
          </div>

          <!-- Side notification bar -->
          <div class="col pr-1 py-1 notifications align-items-end">
            <!-- New Item Icon -->
            <span
              v-if="item.display.viewed_by_me == false"
              :class="newItemIcon"
              class="new-item-icon mb-2"
            ></span>
            <!-- Tags Icon -->
            <tag-icon :tags="item.tags" />
            <!-- Like button -->
            <div
              class="mt-auto customer-color-primary like-icon text-right font-size-sm nobr"
              @click="handleLike(item)"
            >
              <span class="font-size-sm"
                >{{ item.rating.likes }}
                <font-awesome-icon
                  v-if="!item.rating.liked_by_me"
                  :icon="['far', 'thumbs-up']"
                  size="1x" />
                <font-awesome-icon
                  v-if="item.rating.liked_by_me"
                  :icon="['fas', 'thumbs-up']"
                  size="1x"
              /></span>
            </div>
          </div>
        </div>

        <div class="pt-3" v-if="loadingScrollItems">
          <spinner />
        </div>
      </div>
      <div v-if="viewMode == 'content'" class="best-seller-item">
        <view-content
          @back="backToList()"
          :contentList="filteredItemList"
          :itemIdx="this.selectedIdx"
        ></view-content>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import { GlobalMethods, GlobalStore } from "../global";
import { requests } from "../helpers/requests";
import { lazyLoad } from "../helpers/lazyLoad";
import viewContent from "../components/viewContent";
import pageLoader from "../components/pageLoader";
import spinner from "../components/spinner";
import tcxImage from "../components/tcxImage";
import tagIcon from "../components/tagIcon";

Vue.component("multiselect", Multiselect);

export default {
  name: "BestSellers",
  data() {
    return {
      itemList: [],
      imageEndpoint: GlobalStore.imageEndpoint,
      viewMode: "list",
      selectedIdx: "",
      loading: true,
      selectedFilterItems: [],
      tagList: [],
      newItemIcon: "czi-bell",
      /** Used by lazyLoader for api calls. */
      nextPage: null,
      /** Flag used by bottom spinner. Will be set to true when scroll items are loading. */
      loadingScrollItems: false,
      showImages: true,
    };
  },
  props: {
    msg: String,
  },
  computed: {
    filteredItemList() {
      return requests.filteredItemListHelper(this.itemList);
    },
  },
  methods: {
    tagSelected() {
      setTimeout(() => {
        //console.log(this.selectedFilterItems);
        this.getItems();
      }, 200);
    },

    /**
     * Getting initial items. Populates 'this.itemList'.
     */
    getItems() {
      // Resetting 'nextPage'
      this.nextPage = null;

      const params = new URLSearchParams({
        content_item_type: "best_seller",
      });

      this.showImages = false;

      requests
        .getInitialItems(
          `${GlobalStore.APIEndpoint}/content`,
          params,
          this.selectedFilterItems
        )
        .then((res) => {
          this.itemList = res.data.data;
          this.nextPage =
            res.data.total_count > GlobalStore.requests.LAZY_LOAD_PER_PAGE
              ? res.data.next_page
              : null;
          this.showImages = true;
        })
        .catch((err) => {
          GlobalMethods.handleAPIerror(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkRefreshToken: function () {
      GlobalMethods.checkRefreshToken();
    },
    handleClick: function () {
      alert(this.appName);
    },
    getCustGuid: function () {
      return GlobalStore.custGuid;
    },
    viewContent: async function (idx) {
      await GlobalMethods.checkRefreshToken();
      this.viewMode = "content";
      this.selectedIdx = idx;
      window.scrollTo(0, 0);
      //console.log(idx);
    },
    async backToList() {
      await GlobalMethods.checkRefreshToken();
      this.viewMode = "list";
      this.selectedItem = null;
    },

    /**
     * Handle events when user likes a content item.
     * @param item Object containing the guid of the item to be liked.
     */
    handleLike(item) {
      GlobalMethods.likeItem(item).then((result) => {
        if (result && result.status === 200) {
          // Finding element that was returned and udating it.
          var index = this.itemList.findIndex(
            (item) => item.content_guid == result.data.content_guid
          );
          // Triggering data rerender.
          Vue.set(this.itemList, index, result.data);
        }
      });
    },

    /**
     * Implements the onscroll event listener to call lazyLoad data. Must be
     * called in the execution file rather than helper file to use
     * eventListener and variables.
     */
    initializeLazyLoad() {
      window.onscroll = () => {
        // Gets set to true if user has scrolled to bottom of window
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset >= document.body.offsetHeight;
        if (bottomOfWindow && this.nextPage && !this.loadingScrollItems) {
          this.loadingScrollItems = true;
          const url = `${GlobalStore.APIEndpoint}/content`;
          const params = new URLSearchParams({
            // Add additional request parameters here.
            content_item_type: "best_seller",
            ...(this.selectedFilterItems && {
              tags: encodeURIComponent(
                requests.getFilterString(this.selectedFilterItems)
              ),
            }),
          });

          this.showImages = false;
          // Getting data
          lazyLoad
            .getNextItems(
              url,
              params,
              this.nextPage,
              GlobalStore.requests.LAZY_LOAD_PER_PAGE
            )
            .then((res) => {
              this.itemList.push(...res.data.data);
              this.nextPage = res.data.next_page;
              this.showImages = true;
            })
            .finally(() => {
              this.loadingScrollItems = false;
            });
        }
      };
    },
  },
  components: {
    viewContent,
    pageLoader,
    tcxImage,
    Multiselect,
    tagIcon,
    spinner,
  },

  mounted() {
    GlobalStore.custObj.config.tags.best_seller.sort().forEach((tag) => {
      this.tagList.push({ name: tag, code: tag });
    });

    this.newItemIcon = GlobalMethods.getNewItemIcon();

    this.initializeLazyLoad();
  },

  beforeMount() {
    // Getting initial items.
    this.getItems();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.best-seller-item {
  margin-top: 10px;
}

* {
  --abs-pos: 6px;
}

h6 {
  text-align: left;
}

.container {
  margin-bottom: 30px;
}

.notifications {
  display: flex;
  flex-direction: column;
}

.font-size-sm {
  font-size: 8pt !important;
  text-align: left;
}

.product-list li {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.border-rounded {
  border-radius: 5px !important;
}

.mb-4 {
  margin-bottom: 0.75em !important;
}

.list-unstyled {
  border: 1px solid rgba(0, 0, 0, 0.085);
  border-radius: 0.4375rem;
}

.multiselect {
  margin: 15px 0px 0px 0px;
  padding-bottom: 0px !important;
}
/* card display classes  */
.card-body {
  padding: 0.4rem;
}
.card {
  height: 100%;
}
.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}
.w-40 {
  width: 40%;
}
.card-footer {
  text-align: left;
  min-height: 33px;
}

/* tag display classes */
.tag-list {
  display: inline-block !important;
}

.elements-as-list {
  margin-top: 0px !important;
}

.elements-as-list img {
  border-top-left-radius: 0.4375rem;
  border-bottom-left-radius: 0.4375rem;
}

.elements-as-list .card-horizontal {
  position: relative;
}

.like-icon {
  position: absolute;
  bottom: 3px;
  right: 3px;
}

.content-container > div {
  padding-left: 5px;
}

.nobr {
  white-space: nowrap;
}

.like-icon > * {
  cursor: pointer;
}

.card-footer .like-icon {
  position: absolute;
  bottom: var(--abs-pos);
  right: var(--abs-pos);
}

.tags-icon {
  position: absolute;
  top: var(--abs-pos);
  right: var(--abs-pos);
}

.content-container > div {
  padding-left: 5px;
}

.nobr {
  white-space: nowrap;
}

.new-item-icon {
  float: right;
  color: green;
}

/*
Displaying items as a list for screens below 480px width and as cards for
screens above 480px
*/
@media screen and (max-width: 480px) {
  .elements-as-card {
    display: none;
  }
}
@media screen and (min-width: 481px) {
  .elements-as-list {
    display: none;
  }
}
</style>
