<template>
  <div class="login-container">
    <form
      class="needs-validation tab-pane fade show active"
      autocomplete="off"
      novalidate
      id="signin-tab"
    >
      <div class="form-group">
        <label class="input-label" for="si-email">Email address</label>
        <input
          class="form-control"
          type="email"
          id="si-email"
          placeholder="johndoe@example.com"
          required
          v-model="username"
        />
        <div class="invalid-feedback">
          Please provide a valid email address.
        </div>
      </div>
      <div class="form-group">
        <label class="input-label" for="si-password">Password</label>
        <div class="password-toggle">
          <input
            class="form-control"
            type="password"
            ref="siPassword"
            required
            v-model="password"
          />
          <label class="password-toggle-btn">
            <input
              @click="togglePassword()"
              class="custom-control-input"
              type="checkbox"
            /><i class="czi-eye password-toggle-indicator"></i
            ><span class="sr-only">Show password</span>
          </label>
        </div>
      </div>
      <div class="form-group d-flex flex-wrap justify-content-between">
        <div class="custom-control custom-checkbox mb-2">
          <input
            class="custom-control-input"
            type="checkbox"
            id="si-remember"
            v-model="rememberMe"
          />
          <label class="custom-control-label" for="si-remember"
            >Keep me logged in</label
          >
        </div>
        <a class="forgot-password font-size-sm" href="/forgot_password/"
          >Forgot password?</a
        >
      </div>
      <div v-if="loggingIn == true">
        <page-loader></page-loader>
      </div>
      <button
        v-if="!loggingIn == true"
        @click="loginClick()"
        class="btn btn-primary btn-block btn-shadow btn-todaycx"
        type="button"
      >
        Sign in
      </button>
    </form>
  </div>
</template>

<script>
import { GlobalStore, GlobalMethods } from "../global";
import pageLoader from "../components/pageLoader";
import axios from "axios";

export default {
  name: "Second",
  data() {
    return {
      appName: "Briefe",
      username: "",
      password: "",
      loggingIn: false,
      rememberMe: true,
    };
  },
  props: {
    msg: String,
  },
  computed: {
    getModules() {
      return GlobalMethods.getParsedCustObj();
    },
  },
  async mounted() {
    var query = this.$route.query;
    if (query != {} && query.login != null) {
      this.username = query.login;
      GlobalMethods.clearSession();
      GlobalStore.isAuthenticated = false;
    }

    if (localStorage.getItem("cxa") && query.login == null) {
      //console.log(sessionStorage.getItem('cxa'));
      var decryptedCreds = this.CryptoJS.AES.decrypt(
        localStorage.getItem("cxa"),
        GlobalStore.passkey
      )
        .toString(this.CryptoJS.enc.Utf8)
        .split("|");
      this.username = decryptedCreds[0];
      this.password = decryptedCreds[1];
      this.loginClick();
    }
    //GlobalMethods.clearSession();
    //GlobalStore.isAuthenticated = false;
  },
  methods: {
    togglePassword() {
      console.log("clicked");
      if (this.$refs["siPassword"].type == "password") {
        this.$refs["siPassword"].type = "text";
      } else {
        this.$refs["siPassword"].type = "password";
      }
    },
    loginClick: function() {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic " +
          new Buffer(
            this.username.trim().toLowerCase() + ":" + this.password.trim()
          ).toString("base64"),
      };

      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "credentials");
      urlencoded.append("expires_in", "7200");

      this.loggingIn = true;

      axios
        .post(GlobalStore.APIEndpoint + "/authenticate", urlencoded, {
          headers: headers,
        })
        .then((resp) => {
          this.loggingIn = false;
          console.log("auth resp", resp);
          if (resp.status === 200) {
            if (this.rememberMe == true) {
              localStorage.setItem(
                "cxa",
                this.CryptoJS.AES.encrypt(
                  this.username + "|" + this.password,
                  GlobalStore.passkey
                ).toString()
              );
            }

            GlobalStore.isAuthenticated = true;
            GlobalStore.refresh_token = resp.data.refresh_token;
            GlobalStore.access_token = resp.data.access_token;
            GlobalStore.userObject = GlobalMethods.parseJwt(
              resp.data.access_token
            );
            GlobalStore.custGuid = GlobalStore.userObject.customer_guid;
            GlobalStore.custObj = GlobalMethods.getParsedCustObj();
            
            GlobalStore.expires_in = resp.data.expires_in;
            GlobalStore.expires_at =
              GlobalMethods.getEpochSeconds() + parseInt(resp.data.expires_in);

            if (GlobalStore.toastObj) {
              GlobalStore.toastObj.goAway(0);
            }

            GlobalMethods.updateSessionTokens();

            const meHeaders = {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + GlobalStore.access_token,
            };

            axios
              .get(GlobalStore.APIEndpoint + "/user/me", {
                headers: meHeaders,
              })
              .then((meResp) => {
                GlobalStore.userMeObj = meResp.data;
                axios
                  .get(GlobalStore.APIEndpoint + "/customer/" + GlobalStore.userObject.customer_guid, {
                    headers: meHeaders,
                  })
                  .then((resp) => {
                    GlobalStore.custObj = resp.data
                    console.log('GlobalStore.custObj: ', GlobalStore.custObj);
                    if(GlobalStore.custObj != ""){
                      console.log('Pushing to: ',"/" + GlobalStore.custObj.config.modules[0].app_url);
                      this.$router.push("/" + GlobalStore.custObj.config.modules[0].app_url);
                    }else{
                      console.log('Pushing to: ',"/fyi");
                      this.$router.push("/fyi");
                    }
                  })
                  .catch(err => console.log("ERROR", err));})
              .catch (err => {console.
                  log("ERROR", err)
                  //if problem validating session tokens, return false to this function, which will boot user to login page
                  this.userMenuVisible = false;
                  GlobalMethods.clearSession();
                  GlobalStore.isAuthenticated = false;
                  return false;
                });
          }
        })
        .catch(() => {
          this.loggingIn = false;
          localStorage.removeItem("cxa");
          GlobalStore.toastObj = this.$toasted.show(
            "Invalid Login, Please try again",
            {
              theme: "outline",
              position: "bottom-center",
              duration: 5000,
              type: "error",
              fitToScreen: true,
              fullWidth: false,
            }
          );
        });
    },
  },

  components: {
    pageLoader,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.forgot-password {
  text-align: right;
  float: right !important;
}
.login-container {
  margin: 30px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.btn-todaycx {
  /* background-color: rgb(63, 165, 53) !Important;
  border-color: rgb(63, 165, 53) !Important; */
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(63, 165, 53) !important;

  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: white !important;
  border-color: rgb(63, 165, 53);
  border-width: 12px;
  color: rgb(63, 165, 53) !important;
  margin: auto;
  padding: 0;
  font-weight: bold;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):focus,
.btn-primary:not(:disabled):not(.disabled):hover {
  border-color: rgb(63, 165, 53) !important;
}
.form-control {
  min-height: 40px;
}
.form-control:focus {
  border-color: rgba(63, 165, 53, 0.3);
}
.input-label {
  float: left;
}

.password-toggle-btn {
  top: calc(50% + 14px);
}
.form-group {
  text-align: left;
}
</style>
