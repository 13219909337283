<template>
  <div class="parent-container">
    <div v-if="mode ==='readonly'" class="star-rating float-right" :title="rating.total">
      <span><small v-if="rating.rated_by_me">You've rated this item.</small></span>
      <div>
        <div class="parent-rating my-1">
          <div class="rating-item" v-for="index1 in Math.floor(rating.stars)" :key="index1">
            <i v-if="Math.floor(rating.stars) != index1" class="sr-star czi-star-filled active"></i>

            <i v-if="Math.floor(rating.stars) == index1 && (rating.stars - Math.floor(rating.stars)) == 0" class="sr-star czi-star-filled active"></i>

            <i v-if="Math.floor(rating.stars) == index1 && (rating.stars - Math.floor(rating.stars)) > 0" class="sr-star czi-star-filled active"></i>
            <i v-if="Math.floor(rating.stars) == index1 && (rating.stars - Math.floor(rating.stars)) > 0" class="sr-star czi-star-filled active half-rating"></i>
          </div>
          <div class="rating-item" v-for="index2 in (5-Math.floor(rating.stars))" :key="index2+5">
            <i class="sr-star czi-star active"></i>
          </div>
        </div>
        <div class="rating-count">{{rating.stars.toFixed(1)}}<div class="rating-spacer">&nbsp;&nbsp;</div></div>
      </div>
    </div>
    <div v-if="mode ==='input'">
      <div class="rating-title">{{title}}</div>
      <div class="star-input-rating-container">
        <i @click="selectStar(index2)" v-for="index2 in 5" :key="index2" class="sr-star active star-input" v-bind:class="{'czi-star-filled': index2 <= selectedRating, 'czi-star': index2 > selectedRating }" ></i>
      </div>
      <button :disabled="selectedRating == 0" @click="submitRating()" type="button" class="btn btn-primary btn-shadow">Submit Rating</button>
    </div>       
  </div>
</template>

<script>
export default {
  name: 'star-rating',
  data() {
    return {
      appName: '',
      selectedRating: 0
    }
  },
  props: {
    rating: Object,
    mode: String,
    title: String
  },
  methods:  {
    selectStar: function(idx) {
      this.selectedRating = idx;
    },
    submitRating: function() {
      this.$emit('submitRating', this.selectedRating);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.parent-container {
  margin: auto;
}

.star-rating {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 13px 0px 7px 0px;
}

.sr-star {
  font-size: 16pt !important;
}

.parent-rating {
  position: relative;
  display: inline-block;
}

.rating-count {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 0px 0px 10px;
}

 .half-rating {
   position: static;
   margin-left:-2px;
   width: 10px;
   overflow-x: hidden;
   z-index: 2000;
 }

 .rating-item {
   display: inline-block;
   width: 22px;
 }

 .rating-spacer {
   width:7px;
   display: inline-block;
 }

 .star-input-rating-container {
   margin-bottom: 25px;
 }

 .star-input {
   font-size: 24pt !Important;
   padding: 0px 10px 0px 10px;
   color: #fea569;
 }

 .rating-title {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 14pt;

}

</style>
