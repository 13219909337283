<template>
  <div class="container">
    <div v-if="loading">
      <page-loader></page-loader>
    </div>

    <multiselect
      v-model="selectedFilterItems"
      tag-placeholder="Add this as new tag"
      placeholder="Select a tag"
      label="name"
      track-by="code"
      :options="tagList"
      :multiple="true"
      class="filter pb-3"
      @select="tagSelected"
      @remove="tagSelected"
    ></multiselect>

    <div
      v-if="viewMode == 'list'"
      class="pt-3 mx-n2 row no-gutter elements-as-card"
    >
      <div
        class="col-md-4 col-xxl-4 mb-3 px-md-2"
        @click="viewContent(index)"
        v-for="(item, index) in filteredItemList"
        :key="index"
      >
        <div v-if="filteredItemList.length == 0">
          There are no items to display.
        </div>
        <div class="card cursor-pointer">
          <div class="card-img-top">
            <tcx-image
              className="mr-3 border-rounded"
              :imageSrc="item.images.hero_image_url"
              :aspectBlur="true"
              v-if="showImages"
            />
          </div>
          <div class="card-body">
            <span
              v-if="item.display.viewed_by_me == false"
              :class="newItemIcon"
              class="new-item-icon"
            ></span>
            <h5 class="card-title">{{ item.title }}</h5>
            <p class="card-text font-size-med">
              {{ item.summary }}
            </p>
            <p
              v-if="item.category.length > 0"
              class="card-text customer-color-primary"
            >
              {{ item.category }}
            </p>
          </div>
          <div class="p-1 card-footer">
            <div class="tag-list" v-for="tag in item.tags" v-bind:key="tag">
              <span class="badge badge-pill badge-secondary">{{ tag }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-3" v-if="loadingScrollItems">
      <spinner />
    </div>
    <!-- Mobile rendering -->
    <div v-if="viewMode == 'list'" class="col elements-as-list pb-5">
      <div v-if="filteredItemList.length == 0">
        There are no items to display.
      </div>
      <div
        :key="index"
        v-for="(item, index) in filteredItemList"
        class="mt-3 row list-unstyled"
      >
        <div class="col-4 p-0" @click="viewContent(index)">
          <tcx-image
            :imageSrc="item.images.thumbnail_image_url"
            :aspectBlur="true"
            v-if="showImages"
          />
        </div>
        <div class="col-7 p-0 pt-1" @click="viewContent(index)">
          <div class="ml-3">
            <h6 class="mb-1">{{ item.title }}</h6>
            <div class="card-text font-size-sm">
              {{ item.summary }}
            </div>
            <div class="card-text font-size-sm customer-color-primary">
              {{ item.category }}
            </div>
          </div>
        </div>
        <!-- Side notification bar -->
        <div class="col-1 p-1 notifications">
          <!-- New Item Icon -->
          <span
            v-if="item.display.viewed_by_me == false"
            :class="newItemIcon"
            class="new-item-icon p-1"
          ></span>
          <!-- Tags Icon -->
          <tag-icon :tags="item.tags" />
        </div>
      </div>
      <div class="pt-3" v-if="loadingScrollItems">
        <spinner />
      </div>
    </div>

    <div v-if="viewMode == 'content'" class="memo-item">
      <view-content
        @back="backToList()"
        :contentList="filteredItemList"
        :itemIdx="this.selectedIdx"
      ></view-content>
    </div>
  </div>
</template>

<script>
import { GlobalMethods, GlobalStore } from "../global";
import { requests } from "../helpers/requests";
import { lazyLoad } from "../helpers/lazyLoad";
import viewContent from "../components/viewContent";
import pageLoader from "../components/pageLoader";
import tcxImage from "../components/tcxImage";
import spinner from "../components/spinner";
import tagIcon from "../components/tagIcon.vue";
import Multiselect from "vue-multiselect";
import Vue from "vue";

Vue.component("multiselect", Multiselect);

export default {
  name: "Memos",
  data() {
    return {
      appName: "",
      itemList: [],
      imageEndpoint: GlobalStore.imageEndpoint,
      viewMode: "list",
      selectedIdx: "",
      loading: true,
      selectedFilterItems: [],
      tagList: [],
      newItemIcon: "czi-bell",
      routeQueryItems: [],
      /** Used by lazyLoader for api calls. */
      nextPage: null,
      /** Flag used by bottom spinner. Will be set to true when scroll items are loading. */
      loadingScrollItems: false,
      showImages: true,
    };
  },
  props: {
    msg: String,
  },
  computed: {
    filteredItemList() {
      return requests.filteredItemListHelper(this.itemList);
    },
  },
  watch: {
    $route() {
      this.routeQueryItems = this.$route.query;

      // console.log('this.routeQueryItems', this.routeQueryItems);
      // console.log(this.viewMode);

      this.selectedItem = null;

      this.viewMode = "list";

      this.getItems();
    },
  },
  methods: {
    tagSelected() {
      setTimeout(() => {
        this.getItems();
      }, 200);
    },

    getItems() {
      // Resetting 'nextPage'
      this.nextPage = null;
      this.showImages = false;

      console.log("types", this.routeQueryItems);

      const params = new URLSearchParams({
        content_item_type: "memo",
        ...(this.routeQueryItems.item_types && {
          item_type: this.routeQueryItems.item_types,
        }),
        ...(this.routeQueryItems.exclude_item_types && {
          exclude_item_types: this.routeQueryItems.exclude_item_types,
        }),
      });

      requests
        .getInitialItems(
          `${GlobalStore.APIEndpoint}/content`,
          params,
          this.selectedFilterItems
        )
        .then((res) => {
          this.itemList = res.data.data;
          this.nextPage =
            res.data.total_count > GlobalStore.requests.LAZY_LOAD_PER_PAGE
              ? res.data.next_page
              : null;

          this.showImages = true;
        })
        .catch((err) => {
          GlobalMethods.handleAPIerror(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClick: function () {
      alert(this.appName);
    },
    getCustGuid: function () {
      return GlobalStore.custGuid;
    },
    viewContent: async function (idx) {
      await GlobalMethods.checkRefreshToken();
      this.viewMode = "content";
      this.selectedIdx = idx;
      window.scrollTo(0, 0);
      //console.log(selectedIdx);
    },
    backToList: function () {
      this.viewMode = "list";
      this.selectedItem = null;
    },

    /**
     * Implements the onscroll event listener to call lazyLoad data. Must be
     * called in the execution file rather than helper file to use
     * eventListener and variables.
     */
    initializeLazyLoad() {
      window.onscroll = () => {
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset >= document.body.offsetHeight;
        if (bottomOfWindow && this.nextPage && !this.loadingScrollItems) {
          this.loadingScrollItems = true;
          const url = `${GlobalStore.APIEndpoint}/content`;
          const params = new URLSearchParams({
            content_item_type: "memo",
            ...(this.selectedFilterItems && {
              tags: encodeURIComponent(
                requests.getFilterString(this.selectedFilterItems)
              ),
            }),
            ...(this.routeQueryItems.item_types && {
              item_type: this.routeQueryItems.item_types,
            }),
            ...(this.routeQueryItems.exclude_item_types && {
              exclude_item_types: this.routeQueryItems.exclude_item_types,
            }),
          });

          this.showImages = false;
          // Getting data
          lazyLoad
            .getNextItems(
              url,
              params,
              this.nextPage,
              GlobalStore.requests.LAZY_LOAD_PER_PAGE
            )
            .then((res) => {
              this.itemList.push(...res.data.data);
              this.nextPage = res.data.next_page;

              this.showImages = true;
            })
            .finally(() => {
              this.loadingScrollItems = false;
            });
        }
      };
    },
  },

  components: {
    viewContent,
    pageLoader,
    tcxImage,
    tagIcon,
    spinner,
  },

  mounted() {
    GlobalMethods.getCustomerColors();

    GlobalStore.custObj.config.tags.memo.sort().forEach((tag) => {
      this.tagList.push({ name: tag, code: tag });
    });

    this.newItemIcon = GlobalMethods.getNewItemIcon();

    this.initializeLazyLoad();
  },

  beforeMount() {
    // Getting query items from rout
    this.routeQueryItems = this.$route.query;

    // Getting initial items.
    this.getItems();
  },
};
</script>

<style scoped>
* {
  --abs-pos: 6px;
  --border-radius: 0.4375rem;
}

.container {
  margin-bottom: 30px;
}

.bs-container {
  margin: 16px 15px 10px 15px;
}

h6 {
  text-align: left;
}
.font-size-sm {
  font-size: 8pt !important;
  text-align: left;
}
.font-size-med {
  font-size: 11pt !important;
}

.border-rounded {
  border-radius: 5px !important;
}

.mb-4 {
  margin-bottom: 0.75em !important;
}

.cursor-pointer {
  cursor: pointer;
}

.memo-item {
  margin-top: 10px;
}

.memo-list {
  margin-top: 10px;
}

.memo-thumbnail {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 38%;
}
div.memo-thumbnail {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 38%;
}
img {
  width: 100%;
}

.notifications {
  display: flex;
  flex-direction: column;
}

.list-unstyled {
  border: 1px solid rgba(0, 0, 0, 0.085);
  border-radius: var(--border-radius);
}

.elements-as-list img {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.elements-as-card img {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  /* border-bottom-left-radius: var(--border-radius); */
}

.multiselect {
  margin: 15px 0px 0px 0px;
  padding-bottom: 0px !important;
}
/* card display classes */
.card-body {
  padding: 0.4rem;
}
.card {
  height: 100%;
}
.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}
.w-40 {
  width: 40%;
}
.card-footer {
  text-align: left;
  min-height: 33px;
}

/* tag display classes */
.tag-list {
  display: inline-block !important;
}

.elements-as-list {
  margin-top: 15px !important;
}

.new-item-icon {
  float: right;
  color: green;
}
/*
Displaying items as a list for screens below 480px width and as cards for
screens above 480px
*/
@media screen and (max-width: 480px) {
  .elements-as-card {
    display: none;
  }
}
@media screen and (min-width: 481px) {
  .elements-as-list {
    display: none;
  }
}
</style>
