<template>
   <div>
    <div class="actionRequestDashboard card my-3" v-if='dataLoaded && selectedRequestItem'>

        <div class="row no-gutters ml-0">
          <h1 class="text-left col-10" style="text-transform: capitalize">{{selectedRequestItem.mode}} Responses</h1>
          <button class="back-button p-0 col-2 btn btn-primary float-right" type="button" @click="retreatPage()">
            Back
          </button>
        </div>

        <h3 class="text-left font-weight-bold">{{actionRequestTitle}}</h3>
        <!-- {{selectedRequestItem.action_request_guid}} -->
      <div class="mb-12 pl-md-0 pr-md-2" style="margin-bottom:20px;">
        <div class="card h-md-100">
          <tcx-image
            className="rounded border border-200 w-100"
            :imageSrc="selectedRequestItem.images.hero_image_url"
          />
          <div class="card-body p-1 pt-3">
            <div class="container mb-3 fs--1">
              <div class="row">
                <div class="col-3 col-2 row-title">
                  <p>Due:</p>
                </div>
                <div class="col-8 row-text">
                  <p>{{fuzzyTime(selectedRequestItem.due_by)}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-3 col-2 row-title">
                  <p>Expires:</p>
                </div>
                <div class="col-8 row-text">
                  <p>{{fuzzyTime(selectedRequestItem.display.expires)}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-3 row-title">
                  <p>Type:</p>
                </div>
                <div class="col-8 row-text">
                  <p style="text-transform: capitalize">{{actionRequestMode}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-3 col-2 row-title">
                  <p>Summary:</p>
                </div>
                <div class="col-8 row-text">
                  <p>{{selectedRequestItem.summary}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-3 col-2 row-title">
                  <p>Request:</p>
                </div>
                <div class="col-8 row-text">
                  <p><span v-html="selectedRequestItem.body_html"></span></p>
                </div>
              </div>
              <!-- Tags -->
              <div class="row" v-if="selectedRequestItem.tags.length">
                <div class="col-3 col-2 row-title">
                  <p>Tags:</p>
                </div>
                <div class="col-8 row-text tag-list">
                  <div
                    class="text-left"
                    v-for="tag in selectedRequestItem.tags"
                    :key="tag">
                    <span class="badge badge-pill badge-secondary row-text">{{ tag }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <!-- Top Summary Counts -->
        <div class="col-4 col-md-2 col-xxl-2 mb-3 pr-2">
          <div class="card h-md-100">
            <div class="audience-header card-header">
              <p>
                Audience
              </p>
            </div>
            <div class="card-body statistic-body">
              <div class="row">
                <div v-if="actionRequestMode=='user'" class="col">
                  <div class="statistic-number">{{this.dataAudience.audience ? this.dataAudience.audience.users.length : 0}}</div>
                  <div class="statistic-text">people</div>
                </div> 
                <div  v-if="actionRequestMode=='store'" class="col">
                  <div class="statistic-number">{{this.dataAudience.audience ? this.dataAudience.audience.stores.length : 0}}</div>
                  
                  <div class="statistic-text">stores</div>
                </div>
                <!-- <div class="col-auto pl-0">
                  <div ref="chart3" class="echart-bar-weekly-sales h-100"></div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 col-md-2 col-xxl-2 mb-3 pr-2">
          <div class="card h-md-100">
            <div class="audience-header card-header">
              <p>Responses</p>
            </div>
            <div class="card-body statistic-body">
              <div class="row">
                <div v-if="actionRequestMode=='user'" class="col">
                  <div class="statistic-number">{{dataAudience.progress ? dataAudience.progress.completed_names.length : 0}}</div>
                  <div class="statistic text">people</div>
                </div>
                <div v-if="actionRequestMode=='store' && unrespondedStoreView.length>0" class="col">
                  <div class="statistic-number">{{unrespondedStoreView[0].responded_stores ? unrespondedStoreView[0].responded_stores.filter(String).length : 0}}</div>
                  <div class="statistic-text">stores</div>
                </div>
                <!-- <div class="col-auto pl-0">
                  <div ref="chart4" class="echart-bar-weekly-sales h-100"></div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 col-md-2 col-xxl-2 mb-3 pr-md-2">
          <div class="card h-md-100">
            <div class="audience-header card-header">
              <p>Yet to respond</p>
            </div>
            <div class="card-body statistic-body">
              <div class="row">
                <div v-if="actionRequestMode=='user'" class="col">
                  <div class="statistic-number">{{dataAudience.progress ? dataAudience.progress.potential_names.length - dataAudience.progress.completed_names.length : 0}}
                  </div>
                  <div class="statistic-text">people
                  </div>
                </div>
                <div v-if="actionRequestMode=='store' && unrespondedStoreView.length>0" class="col">
                  <div class="statistic-number">{{this.remainingStores.filter(String).length}}</div>
                  <div class="statistic-text">stores</div>
                </div>
                <!-- <div class="col-auto pl-0">
                  <div ref="chart5" class="echart-bar-weekly-sales h-100"></div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xxl-6 mb-3 pl-md-0 pr-md-2" style="margin-bottom:20px;">
          <div class="card h-md-100">
            <div class="audience-header card-header">
              <p>Progress</p>
            </div>
            <div class="card-body progress-body">
              <div class="row h-100 progress-row pt-1">
                <div class="col my-auto pr-2">
                   <!-- <div ref="donut" class="echart-doughnut absolute-centered"></div> -->

                  <div class="progress-container">
                    <div class="progress w-100 rounded-soft bg-200" style="height: 15px;">
                      <div
                        class="progress-bar rounded-capsule"
                        role="progressbar"
                        :style="widthPercentComplete"
                        aria-valuenow=33
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>

                  <div class="progress-text">{{(progressPotential) ? progressPercent + "%" : "N/A"}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col pt-1">
                  <p class="progress-text">This is the percentage who have responded so far.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Content Counts -->
      </div>
      <!-- Start of Audience Detail section -->
      <h3 class="text-left">Audience Information</h3>

        <div class="card mb-3">
          <div class="card-header response-header">
            <p>Responded so far:</p>
          </div>
          <div class="card-body audience-body">
            <div class="container">
              <div class="row" v-if="actionRequestMode == 'user' && this.unrespondedUserView.length>0">
                <div class="col-sm">
                  <div v-for="(item) in arrayToColumns(this.respondedUsers, 1,3)" :key=item.id class="audience-text">
                    {{ displayName(item)}} - <b>{{item.active ? storeSummary(item) : " (inactive)"}}</b>
                  </div>
                </div>
                <div class="col-sm">
                  <div v-for="(item) in arrayToColumns(this.respondedUsers, 2, 3)" :key=item.id class="audience-text">
                    {{ displayName(item)}} - <b>{{item.active ? storeSummary(item) : " (inactive)"}}</b>
                  </div>
                </div>
                <div class="col-sm">
                  <div v-for="(item) in arrayToColumns(this.respondedUsers, 3,3)" :key=item.id class="audience-text">
                    {{ displayName(item)}} - <b>{{item.active ? storeSummary(item) : " (inactive)"}}</b>
                  </div>
                </div>
              </div>
              <div class="row" v-if="actionRequestMode == 'store' && this.unrespondedStoreView.length>0">
                <div class="col-sm" >
                  <div v-if="this.unrespondedStoreView[0].responded_stores.length==0" class="audience-text">No responses yet.</div>
                  <div v-for="(item) in arrayToColumns(this.unrespondedStoreView[0].responded_stores.filter(String).sort(), 1, 3)" :key=item.id class="audience-text">
                    {{item}}
                  </div>
                </div>
                <div class="col-sm" >
                  <div v-for="(item) in arrayToColumns(this.unrespondedStoreView[0].responded_stores.filter(String).sort(), 2, 3)" :key=item.id class="audience-text">
                    {{item}}
                  </div>
                </div>
                <div class="col-sm" >
                  <div v-for="(item) in arrayToColumns(this.unrespondedStoreView[0].responded_stores.filter(String).sort(), 3, 3)" :key=item.id class="audience-text">
                    {{item}}
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-3">
          <div class="card-header response-header">
            <p>Yet to respond:</p>
          </div>
          <div class="card-body audience-body">
            <div class="container">
              <div class="row" v-if="actionRequestMode == 'user' && unrespondedUserView.length>0 && this.remainingUsers">
                <div class="col-sm">
                  <div v-for="(item) in arrayToColumns(this.remainingUsers, 1,3)" :key=item.id class="audience-text">
                    {{ displayName(item)}} - <b>{{item.active ? storeSummary(item) : " (inactive)"}}</b>
                  </div>
                </div>
                <div class="col">
                  <div v-for="(item) in arrayToColumns(this.remainingUsers, 2, 3)" :key=item.id class="audience-text">
                    {{ displayName(item)}} - <b>{{item.active ? storeSummary(item) : " (inactive)"}}</b>
                  </div>
                </div>
                <div class="col">
                  <div v-for="(item) in arrayToColumns(this.remainingUsers, 3,3)" :key=item.id class="audience-text">
                    {{ displayName(item)}} - <b>{{item.active ? storeSummary(item) : " (inactive)"}}</b>
                  </div>
                </div>
              </div>
              <div class="row" v-if="actionRequestMode == 'store' && unrespondedStoreView.length>0">
                <div class="col" v-if="this.unrespondedStoreView">
                  <div v-if="this.remainingStores.length==0" class="audience-text">None.</div>
                  <div v-for="(item) in arrayToColumns(this.remainingStores.filter(String).sort(), 1, 3)" :key=item.id class="audience-text">
                    {{item}}
                  </div>
                </div>
                <div class="col" v-if="this.unrespondedStoreView">
                  <div v-for="(item) in arrayToColumns(this.remainingStores.filter(String).sort(), 2, 3)" :key=item.id class="audience-text">
                    {{item}}
                  </div>
                </div>
                <div class="col" v-if="this.unrespondedStoreView">
                  <div v-for="(item) in arrayToColumns(this.remainingStores.filter(String).sort(), 3, 3)" :key=item.id class="audience-text">
                    {{item}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- Start of Response summary section -->
        <h3 class="text-left" v-if="responseDetail.mode">Response Summary</h3>
        <div v-if="responseDetail.mode" class="row no-gutters">
          <div v-for="(response, index) in responseDetail.response_summary" :key=response.id class="col-12 px-0">
            <div class="card mb-2">
              <div class="card-header response-header">
                <p>Q{{index}}.&nbsp;{{response.text}}</p>
              </div>      
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col" v-if="response.hasOwnProperty('yes')">
                    <div class="statistic-number">{{response.yes}}</div>
                    <div class="statistic-text">Yes</div>
                  </div>
                  <div class="col" v-if="response.hasOwnProperty('no')">
                    <div class="statistic-number">{{response.no}}</div>
                    <div class="statistic-text">No</div>
                  </div>
                  <div class="col" v-if="response.hasOwnProperty('maybe')">
                    <div class="statistic-number">{{response.maybe}}</div>
                    <div class="statistic-text">Maybe</div>
                  </div>
                  <div class="col" v-if="response.hasOwnProperty('no_answer')">
                    <div class="statistic-number">{{response.no_answer}}</div>
                    <div class="statistic-text">Unanswered</div>
                  </div>
                  <div class="col" v-if="response.hasOwnProperty('checked')">
                    <div class="statistic-number">{{response.checked}}</div>
                    <div class="statistic-text">Checked</div>
                  </div>
                  <div class="col" v-if="response.hasOwnProperty('unchecked')">
                    <div class="statistic-number">{{response.unchecked}}</div>
                    <div class="statistic-text">Unchecked</div>
                  </div>
                  <div class="col" v-if="response.hasOwnProperty('imageupload')">
                    <div class="statistic-number">{{response.imageupload}}</div>
                    <div class="statistic-text">Images</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- </div> -->
      <!-- Start of ImageUpload responses -->
      <div>
        <h3 class="text-left">Uploaded Images</h3>
        <div class="mb-4">
          <label for="storeSelect" class="select-label">Display uploads from region:</label>
          <select ref="regionSelector" @input="populateUploads" class="form-control select-input" id="storeSelect">
            <option value="none">None</option>
            <option value="all">All</option>
            <option v-for="region in custObj.config.regions" :key=region.name :value="region.name">{{region.name}}</option>
          </select>
        </div>
        <h6 v-if="selectedRegion != 'none' && sortUploads.length == 0">Sorry... no uploads!</h6>
        <div v-for="store_group in sortUploads" :key=store_group.key>
          <h6 class="text-left mb-3">{{(store_group.key != '') ? store_group.key : "Unknown store"}}</h6>

          <div class="pl-3">
            <div v-if="responseDetail.mode == 'user'" class="row no-gutters">
              <div class="px-0 col-lg-auto col-sm-6" v-for="(response, index) in store_group.values" :key=index>
                <div v-if="response.image.length > 0" class="card mb-3 mr-2">
                  <div v-if="response.user_name" class="card-header response-header">
                    <p>Q{{response.response_id}}. {{requestItemText(response.response_id)}}</p>
                  </div>
                  <div class="card-body pt-1">
                    <div class="row">
                      <div class="mr-1 text-center img-tiles col" v-for="image in response.image" :key=image.id>
                          <div class="tcx-container mx-auto">
                            <tcx-image :imageSrc="image.url" :aspectBlur="true"/>  
                          </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col pl-3 pt-1">
                        <div class="upload-text-user">{{(response.user_name) ? response.user_name : "Unknown"}}</div>
                        <div class="upload-text-timestamp">{{formatToLocalTime(response.timestamp)}}</div>
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>

            <div v-if="responseDetail.mode == 'store'" class="row no-gutters">
              <div class="px-0 col-lg-auto col-sm-6" v-for="(response, index) in store_group.values" :key=index>
                <div v-if="response.image.length > 0" class="card mb-3 mr-2">
                  <div class="card-header upload-header">
                    <p class="mb-1">Q{{response.response_id}}. {{requestItemText(response.response_id)}}</p>
                  </div>     
                  <div class="card-body pt-1">
                    <div class="row">
                      <div class="mr-1 text-center img-tiles col" v-for="image in response.image" :key=image.id>
                          <div class="tcx-container mx-auto">
                            <tcx-image :imageSrc="image.url" :aspectBlur="true"/>
                          </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col pl-3 pt-1">
                        <div class="upload-text-user">{{(response.user_name) ? response.user_name : "Unknown"}}</div>
                        <div class="upload-text-timestamp">{{formatToLocalTime(response.timestamp)}}</div>
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>  
          </div>
        
        </div>      
      </div>
    </div>
    <div v-if="!dataLoaded">
      <page-loader></page-loader>
    </div>
    <!-- <image-modal :item="selectedItem"/> -->
   </div>
</template>

<script>
import { aggregateMethods } from "../helpers/aggregate";
import pageLoader from "../components/pageLoader";
import { actionMethods } from "../helpers/actions";
import { GlobalMethods, GlobalStore } from '../global';
import { AdminAPI } from '../adminApi';
import { dashboardMethods } from "../helpers/dashboard";
import tcxImage from "../components/tcxImage.vue";
// import { graphMethods } from "../helpers/graphs"
// import ImageModal from "../components/ImageModal"


export default {
  data() {
    return {
      actionRequestTitle: '',
      actionRequestGuid: '',
      actionRequestMode: 'Unknown',
      selectedRequestItem: null,
      // audienceUsers: [],
      dataAudience: [],
      respondedUsers: [],
      responsesStores: [],
      unrespondedUserView: [],
      additionalImages: [],
      unrespondedStoreView: { unrespondedStoreView : []},
      remainingStores: [],
      remainingUsers: [],
      progressPercent: 0.0,
      progressPotential: 0.0,
      progressCurrent: 0.0,
      widthPercentComplete: '',
      responseSummaries: [],
      responseImageUploads: [],
      responseDetail: [],
      /** The region selected in the dropdown */
      selectedRegion: 'none' ,
      /** List of responses from the region specified in 'selectedRegion' */
      selectedRegionUploads: [],
      selectedItem: '',
      dataLoaded: false,
    };
  },
   watch: {
    '$route.params.guid'(guid) {
        this.actionRequestGuid = guid;
      // this.actionRequestGuid = "2455a3d0-86e0-11ea-b0b1-09bf639f3d15";
    }
    // '$route.params.id'(id) {
    //     //this.checkView(currentview);
    //     this.actionRequestItemId = id;
    // },
  },
  computed: {
    custObj: function() {
      return GlobalStore.custObj;
    },

    actionRequestLabel() {
      // ! Using empty string here as 'action request' module currently has 
      // ! empty string for 'admin_url' this will need to be changed
      return GlobalMethods.getModuleTitle('')
    },

    sortUploads() {

      var tmp = this.groupBy(this.selectedRegionUploads, "store")

      // * Need this for question grouping.
      // tmp.forEach(store => {
      //   console.log('store', store);
      //   store.values = this.groupBy(store.values, "response_id")
      // })
      
      return tmp;
    },
  },

  methods: {
    /**
     * Sorts (in ascending order) a list of objects by items in their
     * '.images' field.
     * @param group the list of objets to be sorted
     * @returns A list of objects
     */
    sortByImageCount(group) {
      return Object.keys(group)
      .sort(function(a, b) { return group[b].image.length - group[a].image.length; })
      .map(function(category) { return group[category]; }).reverse()
    },

    arrayToColumns(array, column, columns) {
        const total = array.length; // How many items
        const gap = Math.ceil(total / columns); // How many per col
        let top = (gap * column) - 1; // Top of the col
        const bottom = (gap * (column - 1)); // Bottom of the col
        return array.filter(item =>
            array.indexOf(item) >= bottom
            && array.indexOf(item) <= top,
        );
        // console.log('arrayToColumns:', out);
        // return out;
    },
    
    /**
     * Populated the image upload field.
     */
    async populateUploads() {
      // Getting image responses.
      if (!this.responseImageUploads.length) await this.getResponseImageUploads();

      // Removing uploads that do not have images
      this.responseImageUploads = this.responseImageUploads.filter(upload => {
        return upload.image.length > 0;
      })
      // console.log('responseImageUploads', this.responseImageUploads);
      this.selectedRegion = this.$refs.regionSelector.value

      // Base cases
      if (this.selectedRegion == 'none') {
        this.selectedRegionUploads = []
        return
      }
      if (this.selectedRegion == 'all') {
        this.selectedRegionUploads = this.responseImageUploads
        return
      }

      this.selectedRegionUploads = this.responseImageUploads
        .filter(upload => { return upload.region_name == this.selectedRegion })
    },
    async getPageData() {
      // Defining variables
      // const pipelineAudienceUsers = [
      //   { $match: { action_request_guid : this.actionRequestGuid }},
      //   { $unwind: "$audience_users" },
      // ];
      // const pipelineAudienceStores = [
      //   { $match: { action_request_guid : this.actionRequestGuid }},
      //   // { $unwind: "$audience_stores" },
      // ];
      const pipelineUnrespondedUserView = [
        { $match: { action_request_guid : this.actionRequestGuid }},
        // { $unwind: "$remaining_users" },
        // { $unwind: "$responded_users" },
      ];
      const pipelineUnrespondedStoreView = [
        { $match: { action_request_guid : this.actionRequestGuid }},
        // { $unwind: "$remaining_stores" },
      ];
      const pipelineResponseSummaries = [
        { $match: { action_request_guid : this.actionRequestGuid}},
        { $match: { "request_items.request_type" : { "$ne" : "imageupload"}  }},
      ];

      await dashboardMethods.getDashboardData("audience_action_request", 5000, [{ $match: { action_request_guid : this.actionRequestGuid }}]).then(res => {
        if(res.hasOwnProperty('data')){
          this.dataAudience = res.data[0];
        console.log('dataAudience', this.dataAudience);
        }
      })
      await aggregateMethods.getAggregateResult("v_unresponded_action_request_users", pipelineUnrespondedUserView).then(res => {
        // We only want this data for the response information.
        console.log('v_unresponded_action_request_users', res);
        this.unrespondedUserView = res.data;
        // console.log("unrespondedUserView", this.unrespondedUserView);
        if (this.unrespondedUserView.length>0){
          this.respondedUsers = this.unrespondedUserView[0].responded_users;
          // First sort by displayname
          this.respondedUsers = this.respondedUsers.sort((a,b)=> this.displayName(a).localeCompare(this.displayName(b)));
          // Now sort by store summary
          this.respondedUsers = this.respondedUsers.sort((a,b)=> this.storeSummary(a).localeCompare(this.storeSummary(b)));
        }
        console.log("respondedUsers", this.respondedUsers);
      })
      await aggregateMethods.getAggregateResult("v_unresponded_action_request_stores", pipelineUnrespondedStoreView).then(res => {
        this.unrespondedStoreView = res.data
        // console.log("unrespondedStoreView", this.unrespondedStoreView);
      })
      await actionMethods.getActionRequestResponseDetail(this.actionRequestGuid).then(res => {
        this.responseDetail = res.data
        // console.log(this.responseDetail);
      })
      await aggregateMethods.getAggregateResult("v_action_response_summary", pipelineResponseSummaries).then(res => {
        this.responseSummaries = res.data
        // console.log("responseSummaries", this.responseSummaries);
      })
    },

    /**
     * Gets image responses and populates 'this.responseImageUploads'.
     */
    async getResponseImageUploads() {
      const pipelineResponseImageUploads = [
        { $match: { action_request_guid : this.actionRequestGuid }},
        { $lookup: { 'from' : 'image',
                    'localField' : "image_guid" ,
                    'foreignField' : "image_guid",
                    'as' : "image"} },
      ];
      try {
        await dashboardMethods.getDashboardData("action_request_image_uploads", 5000, pipelineResponseImageUploads).then(res => {
          this.responseImageUploads = res.data || [];
  
        })
      } catch (err) {
        console.error(`There was an error getting images: ${err.message}`)
      }
    },

    groupBy(xs, key)  {
      return xs.reduce(function (rv, x) { 
        let v = key instanceof Function ? key(x) : x[key]; 
        let el = rv.find((r) => r && r.key === v); 
        if (el) { 
          el.values.push(x); 
        } else { 
          rv.push({ key: v, values: [x] }); 
        }
        return rv; 
      }, []); 
    },

    requestItemText(ix) {
      return this.responseDetail.response_summary[Number(ix)]['text']
    },

    formatToLocalTime(ts) {
      return(AdminAPI.formatToLocalTime(ts));
    },

    fuzzyTime(datestamp){
      // Make a fuzzy time
      return(AdminAPI.fuzzyTime(datestamp));
    },

    parseISOString(s) {
      var b = s.split(/\D+/);
      return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
    },

    longDateTimeFormat(datetime){
      return this.parseISOString(datetime)
    },

    removeImages(html){
      return(html.replace(/<img .*?>/g, "[IMAGE REMOVED]<br>"));
    },

    async loadActionRequest(){
      try{
        var resp = await actionMethods.getActionRequestItem(this.actionRequestGuid);
        this.selectedRequestItem = resp.data;
        this.actionRequestTitle = resp.data.title;
        this.actionRequestMode = resp.data.mode;
        // console.log(this.selectedRequestItem); 

        if(typeof resp.data.images.thumbnail_image_url != 'undefined'){
          this.selectedRequestItem.images.thumbnail_image_url = resp.data.images.thumbnail_image_url;
        }else{
          this.selectedRequestItem.images.thumbnail_image_url = resp.data.images.hero_image_url;
        }

        this.additionalImages = [...resp.data.images.additional_images_url]        

        this.selectedRequestItem.due_by_string = this.longDateTimeFormat(this.selectedRequestItem.due_by);
        // Ensure no images in the text
        this.selectedRequestItem.body_html = this.removeImages(this.selectedRequestItem.body_html);
        // console.log(this.selectedRequestItem);
      }
      catch(err){
        console.error("getActionrequestItem failed: " + err.message)
      }
    },

    // ! Modal package not yet installed
    // openModal(item) {
    //   if (!item.hasOwnProperty('url')) {
    //     console.log(GlobalStore.imageEndpoint);
    //     this.selectedItem = {url: GlobalStore.imageEndpoint + item}
    //   } else {
    //     this.selectedItem = item
    //   }
      
    //   this.$modal.show('image-modal')
    // },

    retreatPage() {
      this.$router.go(-1);      
    },
    storeSummary(objUser) {
      if (objUser.stores.hasOwnProperty('length')){
        if (objUser.stores.length == 1){
          return(objUser.stores[0] == "" ? "0 stores" : objUser.stores[0]);
        }
        return(objUser.stores.length + " stores");
      }
      return "0 stores"
    },
    displayName(objUser) {
      return(objUser.given_name + " " + objUser.last_name);
    }
  },

  async mounted() {
    this.actionRequestGuid = this.$route.params.guid;
    // console.log(this.actionRequestGuid);

    try{
      await this.loadActionRequest();
    }
    catch(ex){
      console.error(ex)
    }

    // Getting audience responses.
    try {
      this.getPageData().then(() => {
        if (this.actionRequestMode == 'store'){
          this.remainingStores = this.unrespondedStoreView[0].remaining_stores.filter(value => this.dataAudience.audience.stores.includes(value));
          // console.log('this.remainingStores', this.remainingStores);
          // console.log('this.dataAudience.audience.stores', this.dataAudience.audience.stores);
        }
        if (this.actionRequestMode == 'user'){
          this.remainingUsers = this.dataAudience.audience.users.filter(o1 => !this.respondedUsers.some(o2 => o1.login === o2.login));
          // Sort the remaining users by store, then by name
          // console.log('this.remainingUsers pre sort', this.remainingUsers);
          // First sort by displayname
          this.remainingUsers = this.remainingUsers.sort((a,b)=> this.displayName(a).localeCompare(this.displayName(b)));
          // Now sort by store summary
          this.remainingUsers = this.remainingUsers.sort((a,b)=> this.storeSummary(a).localeCompare(this.storeSummary(b)));
          // console.log('this.remainingUsers post sort', this.remainingUsers);
        }
        // Reformatting image urls
        this.responseImageUploads.forEach(response => {
          response.image.forEach(img => {
            img.thumbnail_url = GlobalStore.imageEndpoint + img.thumbnail_url 
          })
        })
        if (this.actionRequestMode == "user") {
          // this.unrespondedUserView = this.unrespondedStoreView;
          this.progressPotential = this.dataAudience.audience.users.length;
          if (this.dataAudience.audience.users.length == 0) {
            this.progressPercent = 0.0;  
            this.progressCurrent = 0.0;
          } else {
            this.progressPercent = this.dataAudience.progress.completed_percent.toFixed(0);
            this.progressCurrent = this.dataAudience.progress.completed_names.length;
          }
        } 
        if (this.actionRequestMode == "store") { // store mode
          // align the number of unresponded users to the number of unresponded stores
          this.unrespondedUserView = this.unrespondedStoreView;
          this.progressPotential = this.dataAudience.audience.stores.length;
          if (this.dataAudience.audience.stores.length == 0 || !this.unrespondedStoreView[0].responded_stores) {
            this.progressPercent = 0.0;  
            this.progressCurrent = 0.0;
          } else {
            this.progressPercent =  (Math.min(100.0, this.unrespondedStoreView[0].responded_stores.filter(String).length / this.dataAudience.audience.stores.length * 100)).toFixed(0);
            this.progressCurrent = this.unrespondedStoreView[0].responded_stores.filter(String).length;
          }
        }

        // Setting progress bar length
        this.widthPercentComplete = `width: ${this.progressPercent}%`;

        // ! Dont yet have graph package
        // graphMethods.renderPieGraph(this.$refs.donut, (this.progressPotential) ?
        // [{
        //   value: this.progressCurrent,
        //   name: 'Progress'
        // }, {
        //   value: (Math.max(0, this.progressPotential - this.progressCurrent)),
        //   name: 'Yet to respond'
        // }]
        // :
        // [{}]
        // );
      })
    } catch(err) {
      console.error("An issue occured waiting for promise: " + err.message);
      this.responseSummaries = [];
    }
    this.dataLoaded = true;
  },
  components: {
    pageLoader,
    // ! Dont yet have the package required for ImageModal
    // ImageModal,
    tcxImage
  }
}
</script>

<style scoped>
.back-button {
  max-height: 36px;
}

.actionRequestDashboard.card, .card-container {
  padding-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1246px;
}

.actionRequestDashboard.card, .card-header {
  min-height: 3rem !important; 
  text-align: center;
}

.tag-list {
  display: flex;
  flex-direction: row;
}

.row-title {
  text-align: right;
  padding-right: 00.1rem;
  font-size: small;
  font-weight: bold;
  color: dimgray;
}
.row-text {
  text-align: left;
  padding-left: 00.25rem;
  font-size: small;
  font-weight: 500;
  color: dimgray
}
.response-header {
  text-align: left;
  font-size: small;
  font-weight: bold;
  padding: 0.25rem;
  min-height: 0.1rem !important;
  text-align: left;
  color: dimgray;
}
.upload-header {
  text-align: left;
  font-size: small;
  font-weight: bold;
  padding: 0.25rem;
  min-height: 0.1rem !important;
  text-align: left;
  color: dimgray;
  /* width: 180px; */
  word-wrap: break-word;
}
.upload-text-user {
  padding-left: 00.1rem;
  font-size: small;
  font-weight: bold;
  color: dimgray
}
.upload-text-timestamp {
  padding-left: 00.1rem;
  font-size: small;
  font-weight: 500;
  color: dimgray
}

.audience-header {
  font-size: small;
  font-weight: bold;
  padding: 0.25rem;
  min-height: 0.1rem !important;
  color: dimgray;
}
.audience-body {
  padding: 0.5rem !important;
  font-size: small;
}
.audience-text {
  font-size: x-small !important;
  font-weight: 500;
  text-align: left;
  color: dimgray;
}
.statistic-number {
  font-size: 3rem;
  font-weight: 500;
  padding: 0rem !important;
  color: dimgray
}
.statistic-body {
  padding: 0.5rem !important;
}
.statistic-text {
  font-size: small;
  font-weight: 500;
  text-align: center;
  color: dimgray;
}
.select-label {
  float: left !important;
  vertical-align: middle;
  padding-right: 1rem;
  font-weight: normal;
}

.img-tiles {
  padding: 0 !important;
  
  /* Need the 'important' here to overwrite the bootstrap 'col' styling. */
  min-width: 180px !important;
  width: 10vw !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.img-text {
  max-width: 238px !important;
}

.back-button {
  color: #fff;
  font-weight: 600;

}

#storeSelect {
  width: 30%;
}

/* PROGRESS BAR */
.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progress-bar {
  background-color: #3FA535 !important;
}
.progress-text {
  font-size: small;
}

.progress-body {
  min-height: 107px;
  vertical-align: middle;
}
.progress-row {
  vertical-align: middle;
}
/* MODAL */
.v--modal-overlay {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  opacity: 1;
}
</style>