<template>
  <div class="container">
    <div v-if="loading">
      <page-loader></page-loader>
    </div>

    <multiselect
      v-model="selectedFilterItems"
      tag-placeholder="Add this as new tag"
      placeholder="Select a tag"
      label="name"
      track-by="code"
      :options="tagList"
      :multiple="true"
      class="filter pb-3"
      @select="tagSelected"
      @remove="tagSelected"
    ></multiselect>

    <div
      v-if="viewMode == 'list'"
      class="pt-3 row mx-n2 no-gutter elements-as-card"
    >
      <div
        class="col-md-4 col-xxl-4 mb-3 px-md-2 cursor-pointer"
        @click="viewContent(index)"
        v-for="(item, index) in filteredItemList"
        :key="index"
      >
        <div v-if="filteredItemList.length == 0">
          There are no items to display.
        </div>
        <div class="card cursor-pointer">
          <div class="card-img-top">
            <tcx-image
              className="mr-3 border-rounded"
              :imageSrc="item.images.hero_image_url"
              :aspectBlur="true"
              v-if="showImages"
            />
          </div>
          <div class="card-body">
            <span
              v-if="item.display.viewed_by_me == false"
              :class="newItemIcon"
              class="new-item-icon"
            ></span>
            <h5 class="card-title">{{ item.title }}</h5>
            <p class="card-text font-size-med">
              {{ item.summary }}
            </p>
            <p
              v-if="item.category.length > 0"
              class="card-text customer-color-primary font-size-med"
            >
              {{ item.category }}
            </p>
          </div>
          <div class="p-1 card-footer">
            <!-- Tags -->
            <div @click="viewContent(index)">
              <div
                class="float-start tag-list"
                v-for="tag in item.tags"
                v-bind:key="tag"
              >
                <span class="badge badge-pill badge-secondary">{{ tag }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-3" v-if="loadingScrollItems">
      <spinner />
    </div>

    <!-- Mobile Rendering -->
    <div v-if="viewMode == 'list'" class="elements-as-list pb-5">
      <div v-if="filteredItemList.length == 0">
        There are no items to display.
      </div>
      <div
        :key="index"
        v-for="(item, index) in filteredItemList"
        class="mt-3 row no-gutters list-unstyled"
      >
        <div class="col-4" @click="viewContent(index)">
          <tcx-image
            :imageSrc="item.images.thumbnail_image_url"
            :aspectBlur="true"
            v-if="showImages"
          />
        </div>
        <div class="col-7 p-0 ml-2">
          <div @click="viewContent(index)">
            <h6 class="mb-1 pt-1">{{ item.title }}</h6>
            <div class="card-text font-size-sm">
              {{ item.summary }}
            </div>
            <div class="card-text font-size-sm customer-color-primary">
              {{ item.category }}
            </div>
          </div>
        </div>

        <!-- Side notification bar -->
        <div class="col pr-1 py-1 notifications align-items-end">
          <!-- New Item Icon -->
          <span
            v-if="item.display.viewed_by_me == false"
            :class="newItemIcon"
            class="new-item-icon mb-2"
          ></span>
          <!-- Tags Icon -->
          <tag-icon :tags="item.tags" />
        </div>
      </div>
      <div class="pt-3" v-if="loadingScrollItems">
        <spinner />
      </div>
    </div>

    <div v-if="viewMode == 'content'" class="to-do-item">
      <view-action-request
        @back="backToList()"
        :contentList="filteredItemList"
        :itemIdx="this.selectedIdx"
        @itemCompleted="itemCompleted()"
      ></view-action-request>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import viewActionRequest from "../components/viewActionRequest";
import pageLoader from "../components/pageLoader";
import tagIcon from "../components/tagIcon.vue";
import tcxImage from "../components/tcxImage";
import spinner from "../components/spinner";
import Multiselect from "vue-multiselect";
import { GlobalMethods, GlobalStore } from "../global";
import { requests } from "../helpers/requests";
import { lazyLoad } from "../helpers/lazyLoad";

Vue.component("multiselect", Multiselect);

export default {
  name: "Second",
  data() {
    return {
      /** Array of data to be shown. */
      itemList: [],
      appName: "Brieee2f",
      imageEndpoint: GlobalStore.imageEndpoint,
      viewMode: "list",
      selectedIdx: "",
      loading: true,
      selectedFilterItems: [],
      tagList: [],
      newItemIcon: "czi-bell",
      /** Used by lazyLoader for api calls. */
      nextPage: null,
      /** Flag used by bottom spinner. Will be set to true when scroll items are loading. */
      loadingScrollItems: false,
      showImages: true,
    };
  },
  computed: {
    filteredItemList() {
      return requests.filteredItemListHelper(this.itemList);
    },
  },
  props: {
    msg: String,
  },
  methods: {
    tagSelected() {
      setTimeout(() => {
        this.getItems();
      }, 200);
    },
    handleClick: function () {
      alert(this.appName);
    },
    getCustGuid: function () {
      return GlobalStore.custGuid;
    },
    viewContent: async function (idx) {
      await GlobalMethods.checkRefreshToken();

      //console.log(this.filteredItemList[idx]);
      console.log(GlobalStore.userMeObj);
      if (
        this.filteredItemList[idx].mode == "store" &&
        GlobalStore.userMeObj.stores.length == 0
      ) {
        GlobalStore.toastObj = this.$toasted.show(
          "You are not currently associated with any stores, so you can't respond to this.",
          {
            theme: "outline",
            position: "bottom-center",
            duration: 5000,
            type: "error",
            fitToScreen: true,
            fullWidth: false,
          }
        );
      } else {
        this.viewMode = "content";
        this.selectedIdx = idx;
        window.scrollTo(0, 0);
      }
    },
    backToList: async function () {
      await GlobalMethods.checkRefreshToken();
      this.viewMode = "list";
      this.selectedItem = null;
    },
    itemCompleted: async function () {
      await GlobalMethods.checkRefreshToken();
      this.getItems();
      this.viewMode = "list";
      this.selectedItem = null;
    },

    /**
     * Formats variables of items in array.
     * @param {Array} data Array of items
     */
    formatData(data) {
      data.forEach((item) => {
        item.request_items.forEach((r) => {
          if (r.request_type == "checkbox") {
            r.value = false;
            r.image_upload = [];
          } else {
            r.value = "";
            r.image_upload = [];
            r.image_blob = null;
          }
        });
      });
    },

    /**
     * Getting initial items.
     */
    async getItems() {
      // Resetting 'nextPage'
      this.nextPage = null;
      this.showImages = false;

      requests
        .getInitialItems(
          `${GlobalStore.APIEndpoint}/action_request`,
          [],
          this.selectedFilterItems
        )
        .then((res) => {
          console.log("getItems", res.data.data);
          this.itemList = res.data.data;
          this.nextPage =
            res.data.total_count > GlobalStore.requests.LAZY_LOAD_PER_PAGE
              ? res.data.next_page
              : null;

          this.formatData(this.itemList);
          this.showImages = true;
        })
        .catch((err) => {
          console.error(err);
          GlobalMethods.handleAPIerror(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Implements the onscroll event listener to call lazyLoad data. Must be
     * called in the execution file rather than helper file to use
     * eventListener and variables.
     */
    initializeLazyLoad() {
      window.onscroll = () => {
        // Gets set to true if user has scrolled to bottom of window
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset >= document.body.offsetHeight;
        if (bottomOfWindow && this.nextPage && !this.loadingScrollItems) {
          this.loadingScrollItems = true;
          const url = `${GlobalStore.APIEndpoint}/action_request`;
          const params = new URLSearchParams({
            ...(this.selectedFilterItems && {
              tags: encodeURIComponent(
                requests.getFilterString(this.selectedFilterItems)
              ),
            }),
          });

          // Getting data
          lazyLoad
            .getNextItems(
              url,
              params,
              this.nextPage,
              GlobalStore.requests.LAZY_LOAD_PER_PAGE
            )
            .then((res) => {
              this.itemList.push(...res.data.data);
              this.nextPage = res.data.next_page;
            })
            .finally(() => {
              this.loadingScrollItems = false;
            });
        }
      };
    },
  },
  components: {
    viewActionRequest,
    pageLoader,
    tcxImage,
    Multiselect,
    tagIcon,
    spinner,
  },
  mounted() {
    GlobalStore.custObj.config.tags.action_request.sort().forEach((tag) => {
      this.tagList.push({ name: tag, code: tag });
    });
    this.newItemIcon = GlobalMethods.getNewItemIcon();

    this.initializeLazyLoad();
  },

  beforeMount() {
    this.getItems();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h6 {
  text-align: left;
}

.to-do-item {
  margin-top: 10px;
}

.container {
  margin-bottom: 30px;
}

.notifications {
  display: flex;
  flex-direction: column;
}

.font-size-sm {
  font-size: 8pt !important;
  text-align: left;
}
.font-size-med {
  font-size: 11pt !important;
}

.border-rounded {
  border-radius: 5px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-4 {
  margin-bottom: 0.75em !important;
}

.font-size-sm {
  text-align: left;
}

.todo-thumbnail {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 38%;
}
div.todo-thumbnail {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 38%;
}
img {
  width: 100%;
}

.list-unstyled {
  border: 1px solid rgba(0, 0, 0, 0.085);
  border-radius: 0.4375rem;
}
.elements-as-list img {
  border-top-left-radius: 0.4375rem;
  border-bottom-left-radius: 0.4375rem;
}

.multiselect {
  margin: 15px 0px 0px 0px;
  padding-bottom: 0px !important;
}
/* card display classes */
.card-body {
  padding: 0.4rem;
}
.card {
  height: 100%;
}
.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}
.w-40 {
  width: 40%;
}
.card-footer {
  text-align: left;
  min-height: 33px;
}

/* tag display classes */
.tag-list {
  display: inline-block !important;
}

.elements-as-card img {
  border-top-left-radius: 0.4375rem;
  border-top-right-radius: 0.4375rem;
}

.elements-as-list {
  margin-top: 15px !important;
}

.new-item-icon {
  float: right;
  color: green;
}
/*
Displaying items as a list for screens below 480px width and as cards for
screens above 480px
*/
@media screen and (max-width: 480px) {
  .elements-as-card {
    display: none;
  }
}
@media screen and (min-width: 481px) {
  .elements-as-list {
    display: none;
  }
}
</style>
