import Vue from 'vue';
import axios from 'axios';
import { GlobalMethods, GlobalStore } from '../global';

export const requests = new Vue({
	methods: {
		/**
		 * Queries items for the main pages. Designed to be used to get initial
		 * page data. I.e. page 1. Additional data should be fetched with
		 * lazyLoad methods.
		 * @param {String} path Path of the request URL.
		 * @param {URLSearchParams} searchParams Optional search parameters.
		 * @param {Array.<String>} selectedFilterItems Items to filter by.
		 * @returns Array of items.
		 */
		async getInitialItems(path, searchParams = [], selectedFilterItems = []) {
			await GlobalMethods.checkRefreshToken();
			const headers = {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + GlobalStore.access_token
			};

			// Constructing API searchQueries
			const params = new URLSearchParams({
				// Spreading params from given array for reusability.
				...Object.fromEntries(searchParams),
				// Adding tags if they exist in selectedFilterItems.
				...(selectedFilterItems && {
					tags: encodeURIComponent(this.getFilterString(selectedFilterItems))
				}),
				per_page: GlobalStore.requests.LAZY_LOAD_PER_PAGE,
				page: 1
			});

			return axios.get(`${path}?${params.toString()}`, {
				headers: headers
			});
		},

		/**
		 * Encodes 'selectedFilterItems' array. Used for 'tag' functionality.
		 * @param {Array.<Object>} selectedFilterItems Collection of FilterItems
		 * @returns Encoded 'filterItems' string separated by commas.
		 * E.e. "tag_1,tag_2,tag_3".
		 */
		getFilterString(selectedFilterItems) {
			return selectedFilterItems.map((f) => f.code).join(',');
		},

		/**
		 * Sorts items based on their 'order_by' property.
		 * @param {Array.<Object>} items Collection of item objects
		 * @returns Collection of item objects filtered by 'order_by'
		 */
		filteredItemListHelper(items) {
			var filteredItems = items.filter((f) => f.display.order_by != 0);
			var filteredItemsZeros = items.filter((f) => f.display.order_by == 0);

			filteredItems.sort((a, b) =>
				a.display.order_by > b.display.order_by ? 1 : -1
			);

			filteredItemsZeros.forEach((i) => {
				filteredItems.push(i);
			});

			return filteredItems;
		}
	}
});
